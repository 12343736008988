import React from 'react';
import * as PropTypes from 'prop-types';
import { Skeleton } from '@chakra-ui/core';
import { useAlert } from '../../components/Notification/hAlert';
import { isDoctorNotAvailable } from '../../util/functions';
import HForm from '../../components/Form/HForm';
import * as ROUTES from '../../constants/routes';
import AppointmentPropTypes, { AppointmentDefaultProps, AppointmentModel } from './AppointmentPropType';
import { FirebasePropType } from '../../components/Firebase';

const AppointmentForm = ({
  firebase, appointment, postSubmit, model,
}) => {
  const [defaults, setDefaults] = React.useState(null);
  React.useEffect(() => {
    if (appointment) {
      setDefaults({
        ...AppointmentDefaultProps,
        ...appointment,
      });
    }
  }, [appointment]);
  const { alertComponent, setMessage } = useAlert(true, false);
  const preSubmit = async (meta, values) => {
    const notAvailable = await isDoctorNotAvailable(
      firebase, values.doctor, values.datetime, values.duration, appointment,
    );
    if (notAvailable) {
      setMessage({
        type: 'warning',
        title: 'Ya hay una cita programada para este médico dentro de este horario.',
        message: `Cita programada: ${notAvailable.range}. Con: ${notAvailable.patient}.`,
      });
      return true;
    }
    const newValues = {
      ...values,
    };
    if (newValues.serviceType !== 'eps') {
      newValues.eps = firebase.fieldValue.delete();
    }
    setMessage({
      message: null,
      title: null,
    });
    return newValues;
  };
  return (
    <>
      <Skeleton width="100%" height={defaults === null ? '500px' : 'auto'} isLoaded={defaults !== null}>
        {!!defaults && (
          <HForm
            collection="appointments"
            preSubmit={preSubmit}
            postSubmit={postSubmit}
            columns={2}
            basePath={ROUTES.APPOINTMENTS}
            label="la cita"
            entity={defaults}
            model={model || AppointmentModel}
          />
        )}
        {alertComponent}
      </Skeleton>
    </>
  );
};

AppointmentForm.defaultProps = {
  appointment: null,
  postSubmit: null,
  model: null,
};

AppointmentForm.propTypes = {
  firebase: FirebasePropType.isRequired,
  appointment: AppointmentPropTypes,
  postSubmit: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  model: PropTypes.object,
};

export default AppointmentForm;
