import React from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { RiEyeLine } from 'react-icons/ri';
import { Button } from '@chakra-ui/core';

const ViewButton = ({ to, children, ...props }) => (
  <Button
    variantColor="cyan"
    size="sm"
    as={Link}
    to={to}
    leftIcon={RiEyeLine}
    {...props}
  >
    {children}
  </Button>
);

ViewButton.propTypes = {
  to: PropTypes.string.isRequired,
  ...Button.propTypes,
  children: PropTypes.node,
};

ViewButton.defaultProps = {
  children: 'Ver',
};

export default ViewButton;
