import React from 'react';
import * as PropTypes from 'prop-types';
import { RiEyeLine, RiEyeCloseLine } from 'react-icons/ri';
import { Button } from '@chakra-ui/core';

const ToggleButton = ({
  opened, openText, closeText, toggleAction, ...props
}) => (
  <Button
    variantColor="teal"
    onClick={toggleAction}
    size="xs"
    leftIcon={opened ? RiEyeLine : RiEyeCloseLine}
    {...props}
  >
    {opened ? closeText : openText}
  </Button>
);

ToggleButton.propTypes = {
  opened: PropTypes.bool,
  openText: PropTypes.string,
  closeText: PropTypes.string,
  toggleAction: PropTypes.func.isRequired,
  ...Button.propTypes,
  children: PropTypes.node,
};

ToggleButton.defaultProps = {
  openText: 'Mostrar',
  closeText: 'Ocultar',
  opened: false,
  children: null,
};

export default ToggleButton;
