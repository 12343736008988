import React from 'react';
import { Box, Collapse, useDisclosure } from '@chakra-ui/core';
import {
  ACTIVE_OPTIONS,
  ACTIVE_OPTIONS_FEM,
  getStatusOptionsFilter,
} from '../../util/genericPropType';
import SelectFilter from './selectFilter';
import { getDateTimeFromDate } from '../../util/functions';
import DateFilter from './dateFilter';
import TextFilter from './textFilter';
import ToggleButton from '../Buttons/toggle';

const useActiveFilter = (
  defaultValue = true,
  femVersion = true,
  field = 'active',
  label = 'Filtrar por estado',
) => {
  const [filter, setFilter] = React.useState(defaultValue);
  const changeFilter = (value) => {
    let val;
    switch (value) {
      case 'true':
        val = true;
        break;
      case 'false':
        val = false;
        break;
      default:
        val = null;
    }
    setFilter(val);
  };
  const component = (
    <SelectFilter
      options={femVersion ? ACTIVE_OPTIONS_FEM : ACTIVE_OPTIONS}
      field={field}
      label={label}
      onChange={changeFilter}
      value={filter}
    />
  );
  return {
    component,
    filter,
  };
};

const useStatusFilter = (
  showAll = true,
  defaultValue = 'scheduled',
  field = 'status',
  label = 'Estado',
) => {
  const [filter, setFilter] = React.useState(defaultValue);
  const [options] = React.useState(getStatusOptionsFilter(showAll));
  const component = (
    <SelectFilter
      options={options}
      field={field}
      label={label}
      onChange={setFilter}
      value={filter}
    />
  );
  return {
    component,
    filter,
  };
};

const useDateFilter = (startDate = undefined, endDate = undefined) => {
  const [values, setValues] = React.useState({
    start: startDate || getDateTimeFromDate(new Date(), false),
    end: endDate || getDateTimeFromDate(new Date(), true),
  });
  const changeValue = (newValue) => {
    setValues(newValue);
  };
  const component = (
    <DateFilter
      startValue={values.start}
      endValue={values.end}
      field="date"
      onChange={changeValue}
    />
  );
  return {
    component,
    valueFilter: values,
  };
};

const useTextFilter = (
  defaultValue = '',
  field = 'text',
  label = 'Filtrar por texto',
) => {
  const [value, setValue] = React.useState(defaultValue);
  const component = (
    <TextFilter
      value={value}
      field={field}
      label={label}
      onChange={setValue}
    />
  );
  return {
    component,
    valueFilter: value,
  };
};

const useReferenceFilter = (
  references, field, label, attr = 'name', emptyLabel = 'Todas',
) => {
  const emptyOption = {
    value: '',
    label: emptyLabel,
  };
  const [valueFilter, setValueFilter] = React.useState(emptyOption.value);
  const [options, setOptions] = React.useState([emptyOption]);
  React.useEffect(() => {
    if (references) {
      const values = [emptyOption];
      Object.keys(references).forEach((item) => {
        if (references[item].active) {
          values.push({
            value: item,
            label: references[item][attr],
          });
        }
      });
      setOptions(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [references]);
  if (references) {
    const component = (
      <SelectFilter
        options={options}
        field={field}
        label={label}
        onChange={setValueFilter}
        value={valueFilter}
      />
    );
    return {
      component,
      valueFilter,
    };
  }
  return {
    component: null,
    valueFilter: null,
  };
};

const useAppointmentFilter = (
  specialties,
  epss,
  doctors,
  addWeeks = false,
  substractWeeks = false,
  showAllStatus = true,
  defaultStatus = undefined,
) => {
  const [values, setValues] = React.useState({
    start: getDateTimeFromDate(new Date(), false, substractWeeks ? -1 : 0),
    end: getDateTimeFromDate(new Date(), true, addWeeks ? 1 : 0),
    eps: '',
    doctor: '',
    status: '',
    text: '',
  });
  const {
    valueFilter: date,
    component: dateFilterComponent,
  } = useDateFilter(values.start, values.end);
  const {
    valueFilter: specialty,
    component: specialtyFilterComponent,
  } = useReferenceFilter(specialties.list, 'specialty', 'Especialidad');
  const {
    valueFilter: eps,
    component: epsFilterComponent,
  } = useReferenceFilter(epss.list, 'eps', 'EPS');
  const {
    valueFilter: doctor,
    component: doctorFilterComponent,
  } = useReferenceFilter(
    doctors ? doctors.list : null,
    'doctor',
    'Médico',
    'name',
    'Todos',
  );
  const {
    component: statusFilterComponent,
    filter: status,
  } = useStatusFilter(showAllStatus, defaultStatus);
  const {
    component: textFilterComponent,
    valueFilter: text,
  } = useTextFilter(values.text, 'text', 'Paciente');
  React.useEffect(() => {
    const newValues = {
      ...values,
      eps,
      specialty,
      status,
      text,
      ...date,
    };
    if (doctor) {
      newValues.doctor = doctor;
    } else {
      newValues.doctor = '';
    }
    setValues(newValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eps, specialty, doctor, date, status, text]);
  const { isOpen, onToggle } = useDisclosure(false);
  const component = (
    <>
      {statusFilterComponent}
      {dateFilterComponent}
      <Box p={2} borderWidth={1}>
        <ToggleButton openText="Mostrar más filtros" closeText="Ocultar más filtros" toggleAction={onToggle} opened={isOpen} />
        <Collapse isOpen={isOpen}>
          {specialtyFilterComponent}
          {epsFilterComponent}
          {doctorFilterComponent}
          {textFilterComponent}
        </Collapse>
      </Box>
    </>
  );
  return {
    component,
    valueFilter: values,
  };
};

export {
  useActiveFilter,
  useReferenceFilter,
  useDateFilter,
  useAppointmentFilter,
};
