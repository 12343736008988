import React from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Flex } from '@chakra-ui/core';
import SingOutButton from '../Session/button';
// import Navigation from '../Navigation';
import { HOME } from '../../constants/routes';
import Logo from '../Logo';
import { MenuIcon } from '../../styles/Icons';

const Header = ({ toggleMenu, ...props }) => (
  <Flex
    as="nav"
    align="center"
    justify="space-between"
    wrap="wrap"
    padding="10px 15px"
    bg="teal.500"
    color="white"
    {...props}
  >
    <Link to={HOME}>
      <Logo mr={5} />
    </Link>
    <Flex>
      <MenuIcon
        aria-label="Mostrar u ocultar menú"
        onClick={toggleMenu}
        display={{ xs: 'inline-flex', md: 'none' }}
      />
      <SingOutButton />
    </Flex>
  </Flex>
);

Header.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  ...Flex.propTypes,
};

export default Header;
