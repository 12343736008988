import React from 'react';
import * as PropTypes from 'prop-types';
import { Text } from '@chakra-ui/core';

const SmallTitleLabel = ({ children, ...props }) => (
  <Text
    fontWeight="xs"
    color="gray.500"
    textTransform="uppercase"
    fontSize="xs"
    {...props}
  >
    {children}
  </Text>
);

SmallTitleLabel.propTypes = {
  children: PropTypes.node.isRequired,
  ...Text.propTypes,
};

export default SmallTitleLabel;
