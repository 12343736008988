import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import SpecialtiesList from './list';
import SpecialtiesNew from './new';
import SpecialtiesEdit from './edit';
import { withAuthorization } from '../../components/Session';
import { isEditor } from '../../util/functions';

const Specialties = () => (
  <Switch>
    <Route path={`${ROUTES.SPECIALTIES}/new`} component={SpecialtiesNew} />
    <Route path={`${ROUTES.SPECIALTIES}/edit/:id`} component={SpecialtiesEdit} />
    <Route path={ROUTES.SPECIALTIES} component={SpecialtiesList} />
  </Switch>
);

export default withAuthorization(isEditor)(Specialties);
