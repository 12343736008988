import React from 'react';
import { compose } from 'recompose';
import {
  Box, Heading, List, ListIcon, ListItem, Text, Link, Image, Stack,
} from '@chakra-ui/core';
import { userCondition, withAuthorization, withEmailVerification } from '../../components/Session';
// import Messages from '../Messages';
import ModuleContainer from '../../layout/ModuleContainer';

const HomePage = () => (
  <ModuleContainer title="Inicio">
    <Text>
      Te damos la bienvenida a la App para gestión de citas y solicitudes
      de citas del Hospital Departamental San Antonio de Roldanillo.
    </Text>
    <Box
      width="100%"
      maxWidth={400}
      mr={4}
      mt={5}
      d="inline-block"
      backgroundColor="gray.50"
      px={4}
      pt={3}
      pb={5}
      borderRadius={5}
    >
      <Heading as="h3" size="lg">
        Funciones Integradas
      </Heading>
      <List mt={4} spacing={3}>
        <ListItem>
          <ListIcon icon="check-circle" color="teal.500" />
          Gestión de EPS.
        </ListItem>
        <ListItem>
          <ListIcon icon="check-circle" color="teal.500" />
          Gestión de médicos.
        </ListItem>
        <ListItem>
          <ListIcon icon="check-circle" color="teal.500" />
          Gestión de especialidades.
        </ListItem>
        <ListItem>
          <ListIcon icon="check-circle" color="teal.500" />
          Gestión de usuarios.
        </ListItem>
        <ListItem>
          <Link isExternal href="/solicitud">
            <ListIcon icon="external-link" color="teal.500" />
            Solicitud de citas en el sitio Web.
          </Link>
        </ListItem>
        <ListItem>
          <ListIcon icon="chat" color="teal.500" />
          Gestión de solicitudes de citas.
        </ListItem>
        <ListItem>
          <ListIcon icon="calendar" color="teal.500" />
          Gestión de asignación de citas.
        </ListItem>
        <ListItem>
          <ListIcon icon="bell" color="teal.500" />
          Notificaciones vía correo electrónico.
        </ListItem>
        <ListItem>
          <ListIcon icon="info" color="teal.500" />
          App Web adaptada a dispositivos móviles.
        </ListItem>
      </List>
    </Box>
    <Box
      width="100%"
      maxWidth={550}
      mt={5}
      d="inline-block"
      verticalAlign="top"
      px={4}
      pt={3}
      pb={5}
    >
      <Heading as="h3" size="lg">
        Citas Virtuales
      </Heading>
      <Text mt={4}>
        {'Las citas virtuales son atendidas a través de la integración con '}
        <Link isExternal href="https://jitsi.org/">Jitsi</Link>
        .
      </Text>
      <Text mt={4}>
        Una vez la cita es agendada, tanto médicos como pacientes recibirán
        un correo electrónico con el enlace correspondiente a su cita.
      </Text>
      <Text mt={4}>
        Para acceder a la cita usando dispositivos móviles, dében descargar
        la App de Jitsi (encuentran los enlaces de App Store y Google Play a continuación).
      </Text>
      <Stack mt={4} spacing={4} isInline align="center" justify="center">
        <Link isExternal href="https://jitsi.org/">
          <Image
            width="150px"
            src="/jitsi.png"
            alt="Logo Jitsi"
          />
        </Link>
        <Link isExternal href="https://apps.apple.com/us/app/jitsi-meet/id1165103905">
          <Image
            width="150px"
            src="/AppStore.png"
            alt="Logo App Store"
          />
        </Link>
        <Link isExternal href="https://play.google.com/store/apps/details?id=org.jitsi.meet">
          <Image
            width="150px"
            src="/GooglePlay.png"
            alt="Logo Google Play"
          />
        </Link>
      </Stack>
      <Text mt={4}>
        Para acceder a la cita desde un PC o Mac, pueden hacerlo desde el navegador
        sin instalar software adicional.
      </Text>
    </Box>
  </ModuleContainer>
);

export default compose(
  withEmailVerification,
  withAuthorization(userCondition),
)(HomePage);
