import * as PropTypes from 'prop-types';
import GenericPropType, { GenericDefaultProps } from '../../util/genericPropType';

const DoctorPropTypes = PropTypes.shape({
  name: PropTypes.string,
  active: PropTypes.bool,
  specialty: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  ...GenericPropType,
});

export const DoctorDefaultProps = {
  name: '',
  active: true,
  specialty: '',
  email: '',
  phone: null,
  ...GenericDefaultProps,
};

export default DoctorPropTypes;
