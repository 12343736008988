import React from 'react';
import * as PropTypes from 'prop-types';
import {
  FormControl, FormErrorMessage, FormHelperText, FormLabel, Input,
} from '@chakra-ui/core';

const VALIDATE_MESSAGES = {
  required: 'Este campo es requerido.',
  min: 'El valor mínimo es: ',
  max: 'El valor máximo es: ',
  minLength: 'El valor indicado no es válido.',
  maxLength: 'El valor indicado no es válido.',
  pattern: 'El valor indicado no es válido.',
  validate: 'El valor indicado no es válido.',
};

const InputBase = ({
  errors, label, name, register, validation, placeholder,
  helper, errorMessage, type, value, ...props
}) => {
  let errMsg = null;
  if (errors[name]) {
    errMsg = (
      <FormErrorMessage>
        {errorMessage || VALIDATE_MESSAGES[errors[name].type]}
      </FormErrorMessage>
    );
  }
  return (
    <FormControl isInvalid={errors[name]} {...props}>
      <FormLabel htmlFor={name}>
        {label}
      </FormLabel>
      <Input
        width="100%"
        type={type}
        name={name}
        placeholder={placeholder}
        ref={register(validation)}
        defaultValue={value}
      />
      {!!helper && (
        <FormHelperText id={`${name}-helper-text`} mt={1}>
          {helper}
        </FormHelperText>
      )}
      {errMsg}
    </FormControl>
  );
};

InputBase.defaultProps = {
  register: () => {},
  validation: {},
  placeholder: '',
  errors: {},
  type: 'text',
  errorMessage: null,
  value: null,
  helper: null,
};

InputBase.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'password', 'numeric', 'email']),
  register: PropTypes.func,
  helper: PropTypes.string,
  validation: PropTypes.shape({
    required: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    pattern: PropTypes.instanceOf(RegExp),
    validate: PropTypes.func,
  }),
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object,
  errorMessage: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ...FormControl.propTypes,
};

export default InputBase;
