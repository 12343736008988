import React from 'react';
import {
  Box, Skeleton,
} from '@chakra-ui/core';
import { useAlert } from '../../components/Notification/hAlert';
import { FirebasePropType, withFirebase } from '../../components/Firebase';
import { useRedirectToAfterSleep } from '../../util/functions';
import BackButton from '../../components/Buttons/back';
import * as ROUTES from '../../constants/routes';
import ModuleContainer from '../../layout/ModuleContainer';
import { useLoadRequest, DetailsComponent } from '../../components/RequestsComponents';

const RequestDetails = ({ firebase }) => {
  const {
    loading, setMessage, alertComponent, type,
  } = useAlert(false, true);

  const { request } = useLoadRequest(firebase, setMessage);

  const deleted = useRedirectToAfterSleep(
    setMessage, '', ROUTES.REQUESTS, 4, 'Se borró correctamente la solicitud de cita',
  );

  const cancelled = useRedirectToAfterSleep(
    setMessage, '', ROUTES.REQUESTS, 4, 'Se canceló correctamente la solicitud de cita',
  );

  return (
    <ModuleContainer
      title={!request.name ? 'Cargando detalles de solicitud de cita' : `Detalles solicitud de ${request.name}`}
      headerLeft={(
        <BackButton to={ROUTES.REQUESTS} />
      )}
    >
      <Box maxWidth="750px">
        <Skeleton isLoaded={!loading}>
          {type ? alertComponent : (
            <DetailsComponent
              showActions
              firebase={firebase}
              request={request}
              deleted={deleted}
              cancelled={cancelled}
            />
          )}
        </Skeleton>
      </Box>
    </ModuleContainer>
  );
};

RequestDetails.propTypes = {
  firebase: FirebasePropType.isRequired,
};

export default withFirebase(RequestDetails);
