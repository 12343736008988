import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const withAuthentication = (Component) => {
  // eslint-disable-next-line react/prop-types
  const WithAuthentication = ({ firebase, ...props }) => {
    const [authUser, setAuthUser] = React
      .useState(JSON.parse(localStorage.getItem('authUser')));
    React.useEffect(() => {
      // eslint-disable-next-line react/prop-types
      const listener = firebase.onAuthUserListener(
        (user) => {
          localStorage.setItem('authUser', JSON.stringify(user));
          setAuthUser(user);
        },
        () => {
          localStorage.removeItem('authUser');
          setAuthUser(null);
        },
      );
      return () => listener();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <AuthUserContext.Provider value={authUser}>
        <Component {...props} />
      </AuthUserContext.Provider>
    );
  };
  return withFirebase(WithAuthentication);
};

export default withAuthentication;
