import React from 'react';
import * as PropTypes from 'prop-types';
import { Heading } from '@chakra-ui/core';
import Title from '../components/Title';

const ModuleAuthContainer = ({ children, title }) => (
  <Title title={title}>
    <Heading>
      {title}
    </Heading>
    {children}
  </Title>
);

ModuleAuthContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default ModuleAuthContainer;
