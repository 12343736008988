import React from 'react';
import * as PropTypes from 'prop-types';
import { Input } from '@chakra-ui/core';

const Hidden = ({
  name, register, value, ...props
}) => (
  <Input
    width="100%"
    type="hidden"
    name={name}
    ref={register({})}
    defaultValue={value}
    {...props}
  />
);

Hidden.defaultProps = {
  register: () => {},
  value: null,
};

Hidden.propTypes = {
  name: PropTypes.string.isRequired,
  register: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  ...Input.propTypes,
};

export default Hidden;
