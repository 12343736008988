import React from 'react';
import * as PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import {
  Box, Button, Divider, SimpleGrid,
} from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import ReCAPTCHA from 'react-google-recaptcha';
import InputBase from './Input';
import SwitchBase from './Switch';
import { useAlert } from '../Notification/hAlert';
import { AuthUserPropType, withCurrentUser } from '../Session';
import { FirebasePropType, withFirebase } from '../Firebase';
import GenericPropType from '../../util/genericPropType';
import ReferenceSelect from './ReferenceSelect';
import MultipleCheck from './MultipleCheck';
import HSelect from './HSelect';
import MenuLabel from '../Navigation/MenuLabel';
import HTextArea from './TextArea';
import Hidden from './Hidden';
import HDateTimeInput from './DateTimeInput';

const getSwitch = (
  model, defaultValue, errors, register, formState,
) => {
  const {
    label, name, validation,
  } = model;
  return (
    <SwitchBase
      mt={4}
      key={name}
      name={name}
      label={label}
      isDisabled={formState.isSubmitting}
      value={defaultValue}
      register={register}
      errors={errors}
      validation={validation}
    />
  );
};

const getMultiCheck = (
  model, defaultValue, errors, register, formState, watch,
) => {
  const {
    label, name, validation, reference, helper, layout,
  } = model;
  const otherProps = layout || {};
  return (
    <MultipleCheck
      mt={4}
      key={name}
      name={name}
      label={label}
      isDisabled={formState.isSubmitting}
      value={defaultValue}
      register={register}
      errors={errors}
      validation={validation}
      reference={reference}
      helper={helper}
      watch={watch}
      {...otherProps}
    />
  );
};

const getSelect = (
  model, defaultValue, errors, register, formState,
) => {
  const {
    label, validation, name, errorMessage, reference,
  } = model;
  return (
    <HSelect
      mt={4}
      key={name}
      label={label}
      name={name}
      errors={errors}
      register={register}
      value={defaultValue}
      isDisabled={formState.isSubmitting}
      validation={validation}
      errorMessage={errorMessage}
      reference={reference}
    />
  );
};

const getReferenceSelect = (
  model, defaultValue, errors, register, formState, watch,
) => {
  const {
    label, validation, name, errorMessage, reference,
  } = model;
  return (
    <ReferenceSelect
      mt={4}
      key={name}
      label={label}
      name={name}
      errors={errors}
      register={register}
      value={defaultValue}
      isDisabled={formState.isSubmitting}
      validation={validation}
      errorMessage={errorMessage}
      reference={reference}
      watch={watch}
    />
  );
};

const getBaseInput = (
  model, defaultValue, errors, register, formState,
) => {
  const {
    label, type, validation, name, errorMessage, helper,
  } = model;
  return (
    <InputBase
      mt={4}
      label={label}
      key={name}
      name={name}
      errors={errors}
      register={register}
      type={type}
      value={defaultValue}
      isDisabled={formState.isSubmitting}
      validation={validation}
      helper={helper}
      errorMessage={errorMessage}
    />
  );
};

const getHidden = (model, defaultValue, register) => (
  <Hidden
    key={model.name}
    register={register}
    value={defaultValue}
    name={model.name}
  />
);

const getTextArea = (
  model, defaultValue, errors, register, formState,
) => {
  const {
    label, validation, name, errorMessage,
  } = model;
  return (
    <HTextArea
      mt={4}
      label={label}
      key={name}
      name={name}
      errors={errors}
      register={register}
      value={defaultValue}
      isDisabled={formState.isSubmitting}
      validation={validation}
      errorMessage={errorMessage}
    />
  );
};

const getDateTime = (
  model, defaultValue, errors, control, formState,
) => {
  const {
    label, validation, name, errorMessage,
  } = model;
  return (
    <HDateTimeInput
      mt={4}
      label={label}
      key={name}
      name={name}
      errors={errors}
      control={control}
      value={defaultValue}
      isDisabled={formState.isSubmitting}
      validation={validation}
      errorMessage={errorMessage}
    />
  );
};

const getDivider = (index) => (
  <Divider
    mt={8}
    mb={0}
    gridColumn="1 / -1"
    key={`section_${index}`}
  />
);

const getSection = (model, index) => (
  <MenuLabel
    text={model.label}
    gridColumn="1 / -1"
    mb={0}
    mx={0}
    mt={4}
    p={0}
    fontSize="sm"
    key={`section_${index}`}
  />
);

const getEmptyBox = (index) => (
  <Box key={`empty_${index}`} />
);

const generateForm = (
  model, entity, errors, register, formState, watch, control,
) => model.map((item, index) => {
  if (typeof item.isInvisible === 'function') {
    if (item.isInvisible(watch, entity)) {
      return getEmptyBox(index);
    }
  }
  switch (item.type) {
    case 'text':
    case 'password':
    case 'email':
    case 'numeric':
      return getBaseInput(item, entity[item.name], errors, register, formState);
    case 'hidden':
      return getHidden(item, entity[item.name], register);
    case 'textarea':
      return getTextArea(item, entity[item.name], errors, register, formState);
    case 'boolean':
      return getSwitch(item, entity[item.name], errors, register, formState);
    case 'reference':
      return getReferenceSelect(item, entity[item.name], errors, register, formState, watch);
    case 'select':
      return getSelect(item, entity[item.name], errors, register, formState);
    case 'multiboolean':
      return getMultiCheck(item, entity[item.name], errors, register, formState, watch);
    case 'divider':
      return getDivider(index);
    case 'section':
      return getSection(item, index);
    case 'empty':
      return getEmptyBox(index);
    case 'datetime':
      return getDateTime(item, entity[item.name], errors, control, formState);
    default:
      return null;
  }
});

const HForm = ({
  entity, firebase, authUser, collection, label, hideCancel,
  model, postSubmit, basePath, preSubmit, columns, withCaptcha,
}) => {
  const {
    handleSubmit, errors, register, formState, reset, watch, control,
  } = useForm();
  const captchaRef = React.useRef();
  const [captchaDisabled, setCaptchaDisabled] = React.useState(withCaptcha);
  const { setMessage, alertComponent } = useAlert();
  const onCaptchaChange = (val) => {
    if (typeof val === 'string' && val.length > 10) {
      setCaptchaDisabled(false);
      return;
    }
    setCaptchaDisabled(true);
  };
  const onSubmit = async (newValues) => {
    const update = entity.uid !== null;
    const meta = {};
    let values = {
      ...newValues,
    };
    if (update) {
      meta.lastEditAt = firebase.fieldValue.serverTimestamp();
    } else {
      meta.createdAt = firebase.fieldValue.serverTimestamp();
      if (authUser) {
        meta.userId = authUser.uid;
      } else {
        meta.userId = 'anonymous';
      }
    }
    if (typeof preSubmit === 'function') {
      const result = await preSubmit(meta, values, update);
      if (typeof result === 'boolean' && result) {
        return;
      }
      if (typeof result === 'object') {
        values = {
          ...values,
          ...result,
        };
      }
    }
    try {
      const reference = update ? firebase[collection]().doc(entity.uid)
        : firebase[collection]().doc();
      await reference.set({
        ...meta,
        ...values,
      }, { merge: true });
      if (typeof postSubmit === 'function') {
        await postSubmit(reference, meta, values, reset);
      } else {
        setMessage({
          type: 'success',
          message: `Se ${update ? 'actualizó' : 'creó'} correctamente ${label}.`,
        });
        reset();
      }
    } catch (e) {
      setMessage({
        type: 'error',
        message: `Ups! Algo no salió bien ${update ? 'actualizando' : 'creando'} ${label}. Intentalo de nuevo.`,
      });
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SimpleGrid columns={{ base: 1, sm: columns }} spacingX={5}>
        {generateForm(model, entity, errors, register, formState, watch, control)}
        {!!withCaptcha && (
          <Box mt={4} style={{ transform: 'scale(0.9)', transformOrigin: 0 }}>
            <ReCAPTCHA
              ref={captchaRef}
              size="normal"
              onChange={onCaptchaChange}
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            />
          </Box>
        )}
      </SimpleGrid>
      <Box my={4}>
        <Button
          mt={4}
          variantColor="teal"
          isLoading={formState.isSubmitting}
          isDisabled={formState.isSubmitting || captchaDisabled}
          loadingText="Guardando..."
          type="submit"
        >
          Guardar
        </Button>
        {!hideCancel && (
          <Button
            mt={4}
            ml={4}
            variantColor="red"
            isDisabled={formState.isSubmitting || captchaDisabled}
            as={Link}
            to={basePath}
          >
            Cancelar
          </Button>
        )}
      </Box>
      {alertComponent}
    </form>
  );
};

HForm.defaultProps = {
  postSubmit: null,
  preSubmit: null,
  columns: 1,
  hideCancel: false,
  withCaptcha: false,
};

HForm.propTypes = {
  firebase: FirebasePropType.isRequired,
  authUser: AuthUserPropType.isRequired,
  entity: PropTypes.shape(GenericPropType).isRequired,
  collection: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  model: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    helper: PropTypes.string,
    name: PropTypes.string,
    validation: PropTypes.shape({
      required: PropTypes.bool,
      min: PropTypes.number,
      max: PropTypes.number,
      minLength: PropTypes.number,
      maxLength: PropTypes.number,
      pattern: PropTypes.instanceOf(RegExp),
      validate: PropTypes.func,
    }),
    type: PropTypes.oneOf([
      'text', 'reference', 'numeric', 'password', 'boolean', 'email', 'textarea',
      'multiboolean', 'select', 'divider', 'section', 'hidden', 'empty', 'datetime',
    ]).isRequired,
    errorMessage: PropTypes.string,
    reference: PropTypes.shape({
      entity: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        })),
      ]),
      validate: PropTypes.func,
    }),
    layout: Box.propTypes,
    isInvisible: PropTypes.func,
  })).isRequired,
  basePath: PropTypes.string.isRequired,
  postSubmit: PropTypes.func,
  preSubmit: PropTypes.func,
  columns: PropTypes.number,
  hideCancel: PropTypes.bool,
  withCaptcha: PropTypes.bool,
};

export default compose(
  withFirebase,
  withCurrentUser,
)(HForm);
