import AuthUserContext from './context';
import withAuthentication from './withAuthentication';
import withAuthorization from './withAuthorization';
import withEmailVerification from './withEmailVerification';
import withCurrentUser from './withCurrentUser';
import AuthUserPropType from './authUser';

const userCondition = (authUser) => !!authUser;

export {
  AuthUserContext,
  withAuthentication,
  withAuthorization,
  withEmailVerification,
  withCurrentUser,
  AuthUserPropType,
  userCondition,
};
