import React from 'react';
import * as PropTypes from 'prop-types';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Button, useDisclosure } from '@chakra-ui/core';
import Confirm from '../Confirm';

const DeleteButton = ({ action, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        variantColor="red"
        size="xs"
        onClick={onOpen}
        leftIcon={RiDeleteBinLine}
        {...props}
      >
        Borrar
      </Button>
      <Confirm
        isOpen={isOpen}
        message="¿Está seguro de que desea borrar el elemento indicado?"
        title="Confirmar Borrado"
        onAccept={action}
        onClose={onClose}
      />
    </>
  );
};

DeleteButton.propTypes = {
  action: PropTypes.func.isRequired,
  ...Button.propTypes,
  children: PropTypes.node,
};

DeleteButton.defaultProps = {
  children: null,
};

export default DeleteButton;
