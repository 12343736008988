import React from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Link as ChakraLink } from '@chakra-ui/core';

const HLink = ({ children, router, ...props }) => (
  <ChakraLink
    as={router ? Link : 'a'}
    {...props}
  >
    {children}
  </ChakraLink>
);

HLink.defaultProps = {
  router: false,
};

HLink.propTypes = {
  children: PropTypes.node.isRequired,
  router: PropTypes.bool,
  ...ChakraLink.propTypes,
};

export default HLink;
