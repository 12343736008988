import React from 'react';
import { Text } from '@chakra-ui/core';
import * as PropTypes from 'prop-types';

const MenuLabel = ({ text, ...props }) => (
  <Text
    d="block"
    pt={5}
    pb={1.5}
    my={0.2}
    px={5}
    color="gray.400"
    textTransform="uppercase"
    fontSize="xs"
    fontWeight="bold"
    {...props}
  >
    {text}
  </Text>
);

MenuLabel.propTypes = {
  text: PropTypes.string.isRequired,
  ...Text.propTypes,
};

export default MenuLabel;
