import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../../components/Session';
import AppRoutes from './AppRoutes';
import AuthRoutes from './AuthRoutes';
import Solicitud from '../Solicitud';

const App = () => (
  <Router>
    <Switch>
      <Route path={ROUTES.SOLICITUD} component={Solicitud} />
      <Route path={ROUTES.HOME} component={AppRoutes} />
      <Route path={ROUTES.LOGIN} component={AuthRoutes} />
      <Redirect to={ROUTES.SOLICITUD} />
    </Switch>
  </Router>
);

export default withAuthentication(App);
