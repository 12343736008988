import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Box, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Flex,
} from '@chakra-ui/core';
import AuthMenu from './AuthMenu';
import { AuthUserPropType, withCurrentUser } from '../Session';
import { useBreakpoint } from '../../util/functions';
import Logo from '../Logo';

const queries = {
  md: '(max-width: 768px)',
};

const Navigation = ({ authUser, isOpen, onClose }) => {
  const matchPoints = useBreakpoint(queries);
  const menu = <AuthMenu authUser={authUser} />;
  return matchPoints && matchPoints.md ? (
    <Drawer size="xs" placement="left" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader p={0} m={0}>
          <Flex
            align="center"
            justify="space-between"
            wrap="wrap"
            padding="10px 15px"
            bg="teal.500"
            color="white"
          >
            <Logo />
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          {menu}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  ) : (
    <Box h="calc(100vh - 60px)" pt={5} px={2} w={300} borderRightWidth={1}>
      {menu}
    </Box>
  );
};

Navigation.defaultProps = {
  authUser: null,
};

Navigation.propTypes = {
  authUser: AuthUserPropType,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withCurrentUser(Navigation);
