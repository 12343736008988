import React from 'react';
import {
  Box, Button, Flex, Link,
} from '@chakra-ui/core';
import { useHideLoader } from '../../util/functions';
import { useAlert } from '../../components/Notification/hAlert';
import Logo from '../../components/Logo';
import HForm from '../../components/Form/HForm';
import * as ROUTES from '../../constants/routes';
import { RequestDefaultProps, RequestModel } from '../Requests/RequestPropType';
import Footer from '../../components/Footer';
import ModuleAuthContainer from '../../layout/ModuleAuthContainer';

const PageSolicitud = () => {
  useHideLoader();
  const {
    setMessage,
    alertComponent,
  } = useAlert(true, false);
  const [showForm, setShowForm] = React.useState(true);
  const postSubmit = async () => {
    setMessage({
      type: 'success',
      title: 'Solicitud enviada con éxito!',
      message: 'Hemos procesado su solicitud. Pronto nos pondremos en contacto con usted vía telefónica o a través del correo electrónico.',
    });
    setShowForm(false);
  };
  return (
    <>
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        padding="10px 15px"
        bg="teal.500"
        color="white"
      >
        <Link isExternal href="https://hdsa.gov.co">
          <Logo mr={5} />
        </Link>
      </Flex>
      <Box maxWidth="950px" mx="auto" px={4} pb={5} pt={4}>
        <ModuleAuthContainer title="Solicitud de cita">
          {showForm ? (
            <HForm
              hideCancel
              withCaptcha
              collection="requests"
              columns={2}
              basePath={ROUTES.SOLICITUD}
              label="la solicitud"
              entity={RequestDefaultProps}
              model={RequestModel}
              postSubmit={postSubmit}
            />
          ) : (
            <>
              {alertComponent}
              <Box my={4}>
                <Button
                  mt={4}
                  variantColor="teal"
                  mr={4}
                  onClick={() => setShowForm(true)}
                >
                  Enviar nueva solicitud
                </Button>
                <Button
                  mt={4}
                  variantColor="red"
                  as={Link}
                  href="https://hdsa.gov.co"
                  isExternal
                >
                  Volver al sitio del HDSA
                </Button>
              </Box>
            </>
          )}
        </ModuleAuthContainer>
        <Box mt={8}>
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default PageSolicitud;
