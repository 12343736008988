import React from 'react';
import AuthUserContext from './context';

const withCurrentUser = (Component) => (props) => (
  <AuthUserContext.Consumer>
    {
      (authUser) => (<Component {...props} authUser={authUser} />)
    }
  </AuthUserContext.Consumer>
);

export default withCurrentUser;
