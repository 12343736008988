import React from 'react';
import { useHistory } from 'react-router';
import CryptoJS from 'crypto-js';
import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import 'moment/locale/es';
import Firebase from 'firebase/app';
import 'firebase/firestore';
import * as ROLES from '../constants/roles';

Moment.tz.setDefault('America/Bogota');
Moment.locale('es');

const moment = extendMoment(Moment);

const useBreakpoint = (queries) => {
  const [queryMatch, setQueryMatch] = React.useState(null);

  React.useEffect(() => {
    const mediaQueryLists = {};
    const keys = Object.keys(queries);

    // To check whether event listener is attached or not
    let isAttached = false;

    const handleQueryListener = () => {
      const updatedMatches = keys.reduce((acc, media) => {
        acc[media] = !!(mediaQueryLists[media] && mediaQueryLists[media].matches);
        return acc;
      }, {});
      // Setting state to the updated matches
      // when document either starts or stops matching a query
      setQueryMatch(updatedMatches);
    };

    if (window && window.matchMedia) {
      const matches = {};
      keys.forEach((media) => {
        if (typeof queries[media] === 'string') {
          mediaQueryLists[media] = window.matchMedia(queries[media]);
          matches[media] = mediaQueryLists[media].matches;
        } else {
          matches[media] = false;
        }
      });
      // Setting state to initial matching queries
      setQueryMatch(matches);
      isAttached = true;
      keys.forEach((media) => {
        if (typeof queries[media] === 'string') {
          mediaQueryLists[media].addListener(handleQueryListener);
        }
      });
    }

    return () => {
      // If event listener is attached then remove it when deps change
      if (isAttached) {
        keys.forEach((media) => {
          if (typeof queries[media] === 'string') {
            mediaQueryLists[media].removeListener(handleQueryListener);
          }
        });
      }
    };
  }, [queries]);

  return queryMatch;
};

const getAuthErrorMessage = (err) => {
  switch (err.code) {
    case 'auth/network-request-failed':
      return 'Ocurrió un error en la red.';
    case 'auth/operation-not-allowed':
      return 'La operación no está permitida.';
    case 'auth/email-already-exists':
      return 'Este correo ya está siendo usado por otro usuario.';
    case 'auth/requires-recent-login':
      return 'Esta operación es un cambio importante y requiere una autenticación reciente. Inicia sesión de nuevo e intentalo nuevamente.';
    case 'auth/too-many-requests':
      return 'Demasiadas peticiones al mismo tiempo.';
    case 'auth/unauthorized-domain':
      return 'El dominio desde el que se está haciendo la petición no está autorizado.';
    case 'auth/user-disabled':
      return 'Este usuario ha sido deshabilitado.';
    case 'auth/invalid-email':
      return 'Correo electrónico no valido.';
    case 'auth/wrong-password':
      return 'Contraseña incorrecta.';
    case 'auth/user-not-found':
      return 'No se encontró cuenta del usuario con el correo especificado.';
    case 'auth/weak-password':
      return 'Contraseña muy debil o no válida.';
    case 'auth/missing-email':
      return 'Hace falta registrar un correo electrónico.';
    case 'auth/internal-error':
      return 'Error interno.';
    case 'auth/account-exists-with-different-credential':
      return 'Ya existe una cuenta con esa dirección de correo electrónico. Por favor ingrese con esa cuenta y luego enlace sus redes sociales en su página de perfil.';
    case 'auth/invalid-custom-token':
      return 'Token personalizado invalido.';
    case 'auth/popup-closed-by-user':
      return 'La ventana emergente fue cerrada antes de terminar la operación.';
    case 'auth/no-such-provider':
      return 'No ha enlazado su cuenta con el proveedor indicado.';
    default:
      if (err.message === 'Missing or insufficient permissions.') {
        return 'No se encontró cuenta del usuario con el correo especificado.';
      }
      return err.message;
  }
};

const useHideLoader = () => {
  React.useEffect(() => {
    const element = document.getElementById('preload');
    element.style.display = 'none';
  }, []);
};

const showLoader = () => {
  const element = document.getElementById('preload');
  element.style.display = 'flex';
};

const sleep = (timeout) => new Promise((resolve) => {
  setTimeout(resolve, timeout);
});

const isAdmin = (authUser) => !!authUser && authUser.roles[ROLES.ADMIN];

const isEditor = (authUser) => !!authUser && (authUser.roles[ROLES.EDITOR] || isAdmin(authUser));

const encrypt = (hash) => CryptoJS.AES
  .encrypt(hash, process.env.REACT_APP_USERS_API).toString();
// const makeCancelable = (asyncFunction) => {
//   let isCanceled = false;
//   const wrappedPromise = new Promise((resolve, reject) => {
//     asyncFunction()
//       .then((val) => {
//         if (isCanceled) {
//           const err = new Error();
//           err.isCanceled = isCanceled;
//           reject(err);
//         } else {
//           resolve(val);
//         }
//       })
//       .catch((error) => {
//         if (isCanceled) {
//           const err = new Error();
//           err.isCanceled = isCanceled;
//           reject(err);
//         } else {
//           reject(error);
//         }
//       });
//   });
//   return {
//     promise: wrappedPromise,
//     cancel: () => {
//       isCanceled = true;
//     },
//   };
// };
//
// const useCancellablePromise = () => {
//   // https://medium.com/@rajeshnaroth/writing-a-react-hook-to-cancel-promises-when-a-component-unmounts-526efabf251f
//   // think of useRef as member variables inside a hook
//   // you cannot define promises here as an array because
//   // they will get initialized at every render refresh
//   const promises = React.useRef([]);
//   // useEffect initializes the promises array
//   // and cleans up by calling cancel on every stored
//   // promise.
//   // Empty array as input to useEffect ensures that the hook is
//   // called once during mount and the cancel() function called
//   // once during unmount
//   React.useEffect(
//     () => {
//       promises.current = promises.current || [];
//       return function cancel() {
//         promises.current.forEach((p) => p.cancel());
//         promises.current = [];
//       };
//     }, [],
//   );
//
//   // cancelablePromise remembers the promises that you
//   // have called so far. It returns a wrapped cancelable
//   // promise
//   return (p) => {
//     const cPromise = makeCancelable(p);
//     promises.current.push(cPromise);
//     return cPromise.promise;
//   };
// };

const useRedirectToAfterSleep = (
  setMessage, label, to, timeout = 4, prefixMessage = 'Se editó correctamente ',
) => {
  const isMounted = React.useRef(true);
  const history = useHistory();
  React.useEffect(() => () => {
    isMounted.current = false;
  }, []);
  const [toPath, setToPath] = React.useState(to);
  React.useEffect(() => {
    setToPath(to);
  }, [to]);
  return async () => {
    try {
      for (let i = timeout; i > 0; i -= 1) {
        if (!isMounted.current) {
          return;
        }
        setMessage({
          type: 'success',
          message: `${prefixMessage}${label}. Será redirigido en ${i} segundos.`,
        });
        // eslint-disable-next-line no-await-in-loop
        await sleep(1000);
      }
      if (isMounted.current) {
        history.push(toPath);
      }
    } catch (e) {
      // console.warn(e);
    }
  };
};

const useReference = (
  firebase, setMessage, collection, name, label, withoutLabel, attr = 'name',
) => {
  const [reference, setReference] = React.useState({
    loading: true,
    list: {},
  });
  React.useEffect(() => {
    const unsubscribe = firebase[collection]().onSnapshot((snapshot) => {
      const list = {};
      if (!snapshot.empty) {
        snapshot.forEach((doc) => {
          list[doc.id] = {
            uid: doc.id,
            ...doc.data(),
          };
        });
      }
      setReference({
        loading: false,
        list,
      });
    }, () => {
      setMessage({
        loading: false,
        type: 'error',
        message: `Ups! Algo pasó y no pudimos cargar ${label}. Recarga la página para intentarlo nuevamente.`,
      });
    });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    ...reference,
    attr,
    name,
    withoutLabel,
  };
};

/**
 * Función para cargar referencias en la lista final de datos a partir de multiples referencias
 * @param data objeto con listado de datos y estado de carga actual de los datos principales
 * @param setData función para actualizar el estado del objeto data
 * @param setMessage función para setear el mensaje, estado de carga, título y tipo de mensaje
 * @param references arreglo de objetos referencias para cargar.
 * Dében incluir atributos: loading, name, list, attr, withoutLabel
 */
const useReferenceAfterLoading = (
  data, setData, setMessage, ...references
) => {
  let notLoading = references.every((item) => !item.loading);
  notLoading = notLoading && !data.loading;
  React.useEffect(() => {
    if (notLoading) {
      const list = data.list.map((doc) => {
        const res = { ...doc };
        references.forEach((ref) => {
          const refDoc = ref.list[doc[ref.name]];
          if (refDoc) {
            res[ref.name] = refDoc[ref.attr];
            res[`${ref.name}Id`] = refDoc.uid;
          } else {
            res[ref.name] = ref.withoutLabel;
            res[`${ref.name}Id`] = null;
          }
        });
        return res;
      });
      setData({
        loading: false,
        list,
      });
      setMessage({
        loading: false,
        type: null,
        message: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notLoading]);
};

const getDateTimeFromFirebase = (
  value, format = 'DD/MM/YYYY hh:mmA',
) => {
  if (value) {
    let val = value;
    if (!value.toDate) {
      val = new Firebase.firestore.Timestamp(value.seconds, value.nanoseconds);
    }
    return moment(val.toDate()).format(format);
  }
  return null;
};

const getDateWithDuration = (date, duration) => {
  const result = moment(date);
  const durationDate = moment(duration, 'hh:mm');
  result.add(durationDate.get('hours'), 'hours');
  result.add(durationDate.get('minutes'), 'minutes');
  return result;
};

const isDoctorNotAvailable = async (firebase, doctor, datetime, duration, appointment) => {
  const start = moment(datetime);
  start.subtract(2, 'hour');
  const end = getDateWithDuration(datetime, duration);
  // console.log(`Query Start: ${start.format('YYYY/MM/DD hh:mm A')}`);
  // console.log(`Query End: ${end.format('YYYY/MM/DD hh:mm A')}`);
  const snap = await firebase.appointments()
    .where('datetime', '>=', start.toDate())
    .where('datetime', '<=', end.toDate())
    .where('doctor', '==', doctor)
    .where('status', '==', 'scheduled')
    .get();
  if (snap.empty) {
    // console.log('Empty?');
    return false;
  }
  let prevDate = null;
  snap.forEach((doc) => {
    const old = {
      uid: doc.id,
      ...doc.data(),
    };
    if (!appointment || appointment.uid !== old.uid) {
      const oldDate = moment(old.datetime.toDate());
      const oldEndDate = getDateWithDuration(oldDate, old.duration);
      oldDate.add(1, 'minute');
      oldEndDate.subtract(1, 'minute');
      const oldRange = moment.range(oldDate, oldEndDate);
      // console.log(`Old Start: ${moment(oldDate).format('YYYY/MM/DD hh:mm A')}`);
      // console.log(`Old End: ${oldEndDate.format('YYYY/MM/DD hh:mm A')}`);
      const range = moment.range(moment(datetime), end);
      // console.log(`Start: ${moment(datetime).format('YYYY/MM/DD hh:mm A')}`);
      // console.log(`End: ${end.format('YYYY/MM/DD hh:mm A')}`);
      if (prevDate === null && oldRange.overlaps(range, { adjacent: false })) {
        oldDate.subtract(1, 'minute');
        oldEndDate.add(1, 'minute');
        prevDate = {
          patient: `${old.firstName} ${old.lastName}`,
          range: `${oldDate.format('DD/MM/YYYY hh:mmA')} - ${oldEndDate.format('hh:mmA')}`,
        };
      }
    }
  });
  if (prevDate) {
    return prevDate;
  }
  return false;
};

const getAppointmentFromRequest = (request, defaults) => {
  if (request) {
    const {
      firstName, lastName, documentType, documentNumber,
      email, phone, specialtyId, serviceType, epsId, observations,
    } = request;
    const appointment = {
      ...defaults,
      firstName,
      lastName,
      documentType,
      documentNumber,
      email,
      phone,
      specialty: specialtyId,
      serviceType,
      eps: epsId,
      observations,
      request: request.uid,
    };
    return appointment;
  }
  return defaults;
};

/**
 * Get a date with time from a date object.
 * @param {Date} date Initial date to get result
 * @param {boolean} endOfDay Indicates if time for date will be the beginning of the day or the end
 * @param {number} addWeeks If positive it adds that number weeks to the date,
 * if negative it substract that number of weeks from the date,
 * if 0 it does nothing.
 * @returns {Date} Resulting date from operation including time
 */
const getDateTimeFromDate = (date, endOfDay, addWeeks = 0) => {
  const m = moment(date);
  m.set('hour', endOfDay ? 23 : 0);
  m.set('minute', endOfDay ? 59 : 0);
  m.set('second', endOfDay ? 59 : 0);
  if (addWeeks > 0) {
    m.add(addWeeks, 'weeks');
  } else if (addWeeks < 0) {
    m.subtract(addWeeks * -1, 'weeks');
  }
  return m.toDate();
};

const loginRedirect = (history, location) => {
  if (location.search && location.search.includes('next=')) {
    const params = new URLSearchParams(location.search);
    history.push(params.get('next'));
  } else {
    history.push('/app');
  }
};

export {
  sleep,
  showLoader,
  useHideLoader,
  getAuthErrorMessage,
  isAdmin,
  isEditor,
  useBreakpoint,
  useRedirectToAfterSleep,
  encrypt,
  useReference,
  useReferenceAfterLoading,
  getDateTimeFromFirebase,
  isDoctorNotAvailable,
  getAppointmentFromRequest,
  getDateTimeFromDate,
  loginRedirect,
  // useCancellablePromise,
};
