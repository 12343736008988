import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Box, Heading, Divider, Stack,
} from '@chakra-ui/core';

const TitleComponent = ({
  title, leftContent, rightContent, ...props
}) => (
  <Box>
    <Stack isInline alignItems="baseline">
      {leftContent}
      <Heading
        ml={leftContent ? 5 : 0}
        mr={rightContent ? 10 : 0}
        {...props}
      >
        {title}
      </Heading>
      {rightContent}
    </Stack>
    <Divider mb={5} />
  </Box>
);

TitleComponent.defaultProps = {
  leftContent: null,
  rightContent: null,
};

TitleComponent.propTypes = {
  title: PropTypes.string.isRequired,
  leftContent: PropTypes.node,
  rightContent: PropTypes.node,
};

export default TitleComponent;
