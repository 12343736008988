import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../../components/Session';
import * as ROUTES from '../../constants/routes';
import UserList from './list';
import UserNew from './new';
import UserEdit from './edit';
import { isAdmin } from '../../util/functions';

const UsersPage = () => (
  <Switch>
    <Route exact path={`${ROUTES.USERS}/new`} component={UserNew} />
    <Route exact path={`${ROUTES.USERS}/edit/:id`} component={UserEdit} />
    <Route exact path={ROUTES.USERS} component={UserList} />
  </Switch>
);

export default compose(
  withEmailVerification,
  withAuthorization(isAdmin),
)(UsersPage);
