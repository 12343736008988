import React from 'react';
import { Box } from '@chakra-ui/core';
import ModuleContainer from '../../layout/ModuleContainer';
import BackButton from '../../components/Buttons/back';
import * as ROUTES from '../../constants/routes';
import HForm from '../../components/Form/HForm';
import { SpecialtyDefaultProps } from './SpecialtyPropTypes';

const SpecialtiesNew = () => (
  <ModuleContainer
    title="Nueva Especialidad"
    headerLeft={(
      <BackButton to={ROUTES.SPECIALTIES} />
    )}
  >
    <Box maxWidth="400px">
      <HForm
        collection="specialties"
        basePath={ROUTES.SPECIALTIES}
        label="la especialidad"
        entity={SpecialtyDefaultProps}
        model={[
          {
            validation: {
              minLength: 4,
              required: true,
            },
            name: 'name',
            label: 'Nombre',
            type: 'text',
          },
          {
            name: 'active',
            label: 'Activa',
            type: 'boolean',
          },
        ]}
      />
    </Box>
  </ModuleContainer>
);

export default SpecialtiesNew;
