import React from 'react';
import * as PropTypes from 'prop-types';
import RRDPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Button } from '@chakra-ui/core';
import { RiGoogleLine } from 'react-icons/all';
import { FirebasePropType, withFirebase } from '../../components/Firebase';
import { getAuthErrorMessage, loginRedirect } from '../../util/functions';

const SignInGoogleBase = ({
  firebase, history, location, setError, setLoading, loading,
}) => {
  const onClick = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const socialAuthUser = await firebase.doSignInWithGoogle();
      const user = await firebase.user(socialAuthUser.user.uid).get();
      if (user.exists) {
        await firebase.user(socialAuthUser.user.uid).set(
          { username: socialAuthUser.user.displayName },
          { merge: true },
        );
      } else {
        const e = new Error('El usuario no existe.');
        e.code = 'auth/user-not-found';
        setLoading(false);
        setError(getAuthErrorMessage(e));
        return;
      }
      loginRedirect(history, location);
    } catch (e) {
      // console.warn(e);
      setLoading(false);
      setError(getAuthErrorMessage(e));
    }
  };
  return (
    <Button
      leftIcon={RiGoogleLine}
      variantColor="red"
      onClick={onClick}
      isLoading={loading}
      mt={4}
      ml={2}
    >
      Inicia con Google
    </Button>
  );
};

SignInGoogleBase.propTypes = {
  firebase: FirebasePropType.isRequired,
  history: RRDPropTypes.history.isRequired,
  location: RRDPropTypes.location.isRequired,
  setError: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

// const SignInFacebookBase = ({ firebase, history }) => {
//   const [error, setError] = React.useState(null);
//   const onSubmit = (event) => {
//     event.preventDefault();
//     firebase.doSignInWithFacebook()
//       .then((socialAuthUser) => firebase
//         .user(socialAuthUser.user.uid).set(
//           {
//             username: socialAuthUser.additionalUserInfo.profile.name,
//             email: socialAuthUser.additionalUserInfo.profile.email,
//             roles: {},
//           },
//           { merge: true },
//         ))
//       .then(() => {
//         setError(null);
//         history.push(ROUTES.HOME);
//       })
//       .catch((err) => {
//         let { message } = err;
//         if (err.code === ERROR_CODE_ACCOUNT_EXISTS) {
//           message = ERROR_MSG_ACCOUNT_EXISTS;
//         }
//         setError({
//           ...err,
//           message,
//         });
//       });
//   };
//   return (
//     <form onSubmit={onSubmit}>
//       <button type="submit">Sign In with Facebook</button>
//       {error && <p>{error.message}</p>}
//     </form>
//   );
// };
//
// SignInFacebookBase.propTypes = {
//   firebase: FirebasePropType.isRequired,
//   history: RRDPropTypes.history.isRequired,
// };
//
// const SignInTwitterBase = ({ firebase, history }) => {
//   const [error, setError] = React.useState(null);
//   const onSubmit = (event) => {
//     event.preventDefault();
//     firebase.doSignInWithTwitter()
//       .then((socialAuthUser) => firebase
//         .user(socialAuthUser.user.uid).set(
//           {
//             username: socialAuthUser.additionalUserInfo.profile.name,
//             email: socialAuthUser.additionalUserInfo.profile.email,
//             roles: {},
//           },
//           { merge: true },
//         ))
//       .then(() => {
//         setError(null);
//         history.push(ROUTES.HOME);
//       })
//       .catch((err) => {
//         let { message } = err;
//         if (err.code === ERROR_CODE_ACCOUNT_EXISTS) {
//           message = ERROR_MSG_ACCOUNT_EXISTS;
//         }
//         setError({
//           ...err,
//           message,
//         });
//       });
//   };
//   return (
//     <form onSubmit={onSubmit}>
//       <button type="submit">Sign In with Twitter</button>
//       {error && <p>{error.message}</p>}
//     </form>
//   );
// };
//
// SignInTwitterBase.propTypes = {
//   firebase: FirebasePropType.isRequired,
//   history: RRDPropTypes.history.isRequired,
// };

const SignInGoogle = compose(
  withRouter,
  withFirebase,
)(SignInGoogleBase);

SignInGoogle.propTypes = {
  setError: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export {
  // eslint-disable-next-line import/prefer-default-export
  SignInGoogle,
};

// export const SignInFacebook = compose(
//   withRouter,
//   withFirebase,
// )(SignInFacebookBase);
//
// export const SignInTwitter = compose(
//   withRouter,
//   withFirebase,
// )(SignInTwitterBase);
