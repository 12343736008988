import React from 'react';
import * as PropTypes from 'prop-types';
import { Flex, Box } from '@chakra-ui/core';
import Logo from '../components/Logo';
import Footer from '../components/Footer';

const AuthContainer = ({ children }) => (
  <>
    <Box
      bg="teal.500"
      width="100%"
      height="100vh"
      position="fixed"
    />
    <Flex
      color="white"
      alignItems="center"
      justifyContent="center"
      width="100%"
      minHeight="100vh"
      borderColor="red.500"
      position="absolute"
    >
      <Box
        w="100%"
        maxWidth="400px"
        mx={3}
        my={6}
      >
        <Logo size={100} vertical />
        <Box
          mt={8}
          bg="white"
          color="black"
          borderRadius="5px"
          p={5}
        >
          {children}
          <Footer />
        </Box>
      </Box>
    </Flex>
  </>
);

AuthContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContainer;
