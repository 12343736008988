import React from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { RiAddBoxLine } from 'react-icons/ri';
import { Button } from '@chakra-ui/core';

const AddButton = ({ to, ...props }) => (
  <Button
    variantColor="green"
    size="xs"
    as={Link}
    to={to}
    leftIcon={RiAddBoxLine}
    {...props}
  >
    Agregar
  </Button>
);

AddButton.propTypes = {
  to: PropTypes.string.isRequired,
  ...Button.propTypes,
  children: PropTypes.node,
};

AddButton.defaultProps = {
  children: null,
};

export default AddButton;
