import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Box,
} from '@chakra-ui/core';
import * as ROUTES from '../../constants/routes';
import AppointmentPropTypes from './AppointmentPropType';
import { FirebasePropType } from '../../components/Firebase';
import {
  ViewButton, CancelButton, EditButton, DeleteButton,
} from '../Requests/buttons';

const ButtonBox = ({
  appointment, firebase, setMessage, size, hideView, ...props
}) => {
  if (appointment === null || appointment === undefined) {
    return null;
  }
  const cancelAction = async (deleteIt = false) => {
    try {
      if (deleteIt) {
        await firebase.appointment(appointment.uid).delete();
        if (appointment.request) {
          await firebase.request(appointment.request.uid).delete();
        }
      } else {
        await firebase.appointment(appointment.uid).set({
          status: 'cancelled',
          notify: true,
        }, { merge: true });
      }
      if (typeof setMessage === 'function') {
        setMessage({
          type: 'success',
          message: `Se ${deleteIt ? 'borró' : 'canceló'} correctamente la cita.`,
        });
      } else {
        if (deleteIt) {
          setMessage.deleted();
          return;
        }
        setMessage.cancelled();
      }
    } catch (e) {
      setMessage({
        type: 'error',
        message: `Ups! Algo no salió bien ${deleteIt ? 'borrando' : 'cancelando'} la cita. Intentalo de nuevo.`,
      });
    }
  };
  return (
    <Box mt={1} {...props}>
      {!hideView && (
        <ViewButton
          size={size}
          action={`${ROUTES.APPOINTMENTS}/details/${appointment.uid}`}
          state={{ item: appointment }}
          desc="Ver Detalles Cita"
        />
      )}
      {appointment.status !== 'cancelled' && (
        <>
          <CancelButton
            size={size}
            action={cancelAction}
            desc="Cancelar Cita"
            message="¿Está seguro de que desea cancelar la cita indicada?"
          />
        </>
      )}
      <EditButton
        size={size}
        action={`${ROUTES.APPOINTMENTS}/edit/${appointment.uid}`}
        desc="Modificar Cita"
      />
      <DeleteButton
        size={size}
        action={() => cancelAction(true)}
        desc="Borrar Cita"
        message="¿Está seguro de que desea borrar la cita indicada?"
      />
    </Box>
  );
};

ButtonBox.defaultProps = {
  hideView: false,
  appointment: null,
};

ButtonBox.propTypes = {
  appointment: AppointmentPropTypes,
  firebase: FirebasePropType.isRequired,
  setMessage: PropTypes.func.isRequired,
  hideView: PropTypes.bool,
  ...Box.propTypes,
};

export default ButtonBox;
