import React from 'react';
import * as PropTypes from 'prop-types';
import {
  FormControl, FormErrorMessage, FormLabel,
} from '@chakra-ui/core';
import { Controller } from 'react-hook-form';
import DateTimeBase from './DateTimeBase';

const VALIDATE_MESSAGES = {
  required: 'Este campo es requerido.',
  minLength: 'El valor indicado no es válido.',
  maxLength: 'El valor indicado no es válido.',
  pattern: 'El valor indicado no es válido.',
  validate: 'El valor indicado no es válido.',
};

const HDateTimeInput = ({
  errors, label, name, validation, placeholder, isDisabled,
  errorMessage, type, value, control, ...props
}) => {
  let errMsg = null;
  if (errors[name]) {
    errMsg = (
      <FormErrorMessage>
        {errorMessage || VALIDATE_MESSAGES[errors[name].type]}
      </FormErrorMessage>
    );
  }
  return (
    <FormControl
      isInvalid={errors[name]}
      isDisabled={isDisabled}
      {...props}
    >
      <FormLabel htmlFor={name}>
        {label}
      </FormLabel>
      <Controller
        as={(
          <DateTimeBase disabled={isDisabled} />
        )}
        control={control}
        valueName="selected" // DateSelect value's name is selected
        onChange={([selected]) => selected}
        defaultValue={value}
        name={name}
        rules={validation}
        className="input"
        placeholderText={placeholder}
      />
      {errMsg}
    </FormControl>
  );
};

HDateTimeInput.defaultProps = {
  control: {},
  validation: {},
  placeholder: '',
  errors: {},
  errorMessage: null,
  value: null,
};

HDateTimeInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object,
  validation: PropTypes.shape({
    required: PropTypes.bool,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    pattern: PropTypes.instanceOf(RegExp),
    validate: PropTypes.func,
  }),
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object,
  errorMessage: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ...FormControl.propTypes,
};

export default HDateTimeInput;
