import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Badge, Box, Divider, Heading, Link, Text,
} from '@chakra-ui/core';
import DoctorPropTypes from './DoctorPropTypes';
import DeleteButton from '../../components/Buttons/delete';
import { FirebasePropType, withFirebase } from '../../components/Firebase';
import EditButton from '../../components/Buttons/edit';
import * as ROUTES from '../../constants/routes';
import ActivateButton from '../../components/Buttons/activate';

const DoctorItem = ({
  item, firebase, setMessage, isAdmin,
}) => {
  const deleteAction = async () => {
    try {
      const activeAppointmentsSnap = await firebase.appointments()
        .where('doctor', '==', item.uid)
        .get();
      if (activeAppointmentsSnap.size > 0) {
        setMessage({
          type: 'warning',
          message: `No es posible borrar el médico: ${item.name}. Tiene ${activeAppointmentsSnap.size} citas dentro del programa.`,
        });
        return;
      }
      await firebase.doctor(item.uid).delete();
      setMessage({
        type: 'success',
        message: `Se borró correctamente el médico: ${item.name}`,
      });
    } catch (e) {
      setMessage({
        type: 'error',
        message: `Ups! Algo no salió bien borrando el médico: ${item.name}. Intentalo de nuevo.`,
      });
    }
  };
  const activateAction = async () => {
    const text = item.active ? 'inactivar' : 'activar';
    try {
      if (item.active) {
        const activeAppointmentsSnap = await firebase.appointments()
          .where('doctor', '==', item.uid)
          .get();
        let quantity = 0;
        if (!activeAppointmentsSnap.empty) {
          activeAppointmentsSnap.forEach((doc) => {
            if (doc.data().status !== 'cancelled') {
              quantity += 1;
            }
          });
        }
        if (quantity > 0) {
          setMessage({
            type: 'warning',
            message: `No es posible desactivar al médico: ${item.name}. Tiene ${quantity} citas programadas o pendientes.`,
          });
          return;
        }
      }
      await firebase.doctor(item.uid).set({
        active: !item.active,
      }, { merge: true });
      setMessage({
        type: 'success',
        message: `Se logró ${text} correctamente al médico: ${item.name}`,
      });
    } catch (e) {
      setMessage({
        type: 'error',
        message: `Ups! Algo no salió bien al ${text} al médico: ${item.name}. Intentalo de nuevo.`,
      });
    }
  };
  return (
    <Box key={item.uid} p={5} borderWidth={1} borderRadius={3}>
      <Badge variantColor={item.active ? 'green' : 'gray'}>
        {item.active ? 'Activo' : 'Inactivo'}
      </Badge>
      <Text
        mt={1}
        fontWeight="bold"
        fontSize="xs"
        color="gray.500"
        textTransform="uppercase"
      >
        {item.specialty}
      </Text>
      <Heading as="h4" size="md">
        {item.name}
      </Heading>
      <Link size="sm" isExternal href={`mailto:${item.email}`}>
        {item.email}
      </Link>
      <br />
      <Link size="sm" isExternal href={`tel:${item.phone}`}>
        {item.phone}
      </Link>
      <Divider />
      <EditButton to={`${ROUTES.DOCTORS}/edit/${item.uid}`} />
      <ActivateButton active={item.active} action={activateAction} ml={2} />
      {!!isAdmin && (
        <DeleteButton ml={3} action={deleteAction} />
      )}
    </Box>
  );
};

DoctorItem.propTypes = {
  item: DoctorPropTypes.isRequired,
  firebase: FirebasePropType.isRequired,
  setMessage: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
};

DoctorItem.defaultProps = {
  isAdmin: false,
};

export default withFirebase(DoctorItem);
