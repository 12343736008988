import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Alert, AlertDescription, AlertIcon, AlertTitle, CloseButton, Flex,
} from '@chakra-ui/core';

const HAlert = ({
  status, title, description, onClose, ...props
}) => (
  <Alert status={status} {...props}>
    <AlertIcon />
    <Flex
      flexDirection="column"
    >
      {!!title && (
        <AlertTitle mr={2}>
          {title}
        </AlertTitle>
      )}
      <AlertDescription mr={onClose ? 4 : 0} dangerouslySetInnerHTML={{ __html: description }} />
    </Flex>
    {!!onClose && (
      <CloseButton position="absolute" right="8px" top="8px" onClick={onClose} />
    )}
  </Alert>
);

HAlert.defaultProps = {
  status: 'error',
  title: null,
  onClose: null,
};

HAlert.propTypes = {
  status: PropTypes.oneOf(['error', 'success', 'warning', 'info']),
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  ...Alert.propTypes,
};

export const useAlert = (closable = true, loading = false) => {
  const [message, setMessage] = React.useState({ loading });
  const alertComponent = message.message ? (
    <HAlert
      my={4}
      title={message.title}
      status={message.type}
      description={message.message}
      onClose={closable ? () => setMessage({ message: null, loading: false }) : null}
    />
  ) : null;
  return {
    setMessage: (val) => setMessage((prev) => ({
      ...prev,
      ...val,
    })),
    alertComponent,
    loading: message.loading,
    type: message.type,
    message: message.message,
    title: message.title,
  };
};

export default HAlert;
