import React from 'react';
import {
  FormControl, FormErrorMessage, FormLabel, Select,
} from '@chakra-ui/core';
import * as PropTypes from 'prop-types';

const VALIDATE_MESSAGES = {
  required: 'Este campo es requerido.',
  validate: 'El valor indicado no es válido',
};

const HSelect = ({
  errors, internalMessage, name, errorMessage, isDisabled, label,
  register, validation, value, reference, placeholder, ...props
}) => {
  let errMsg = null;
  const existsInternalMessage = internalMessage !== null;
  if (errors[name] || (existsInternalMessage && internalMessage.message !== null)) {
    const theMessage = (existsInternalMessage && internalMessage.message !== null)
      ? internalMessage.message : (errorMessage || VALIDATE_MESSAGES[errors[name].type]);
    errMsg = (
      <FormErrorMessage>
        {theMessage}
      </FormErrorMessage>
    );
  }
  return (
    <FormControl
      isInvalid={errors[name] || (existsInternalMessage && internalMessage.message !== null)}
      {...props}
      isDisabled={(existsInternalMessage && internalMessage.loading) || isDisabled}
    >
      <FormLabel htmlFor={name}>
        {label}
      </FormLabel>
      <Select
        width="100%"
        name={name}
        ref={register(validation)}
        placeholder={placeholder || 'Seleccione una opción'}
      >
        {reference.entity.map((item) => (
          <option selected={value === item.value} value={item.value} key={`${name}${item.value}`}>
            {item.label}
          </option>
        ))}
      </Select>
      {errMsg}
    </FormControl>
  );
};

HSelect.defaultProps = {
  register: () => {},
  validation: {},
  errors: {},
  errorMessage: null,
  value: null,
  placeholder: null,
  internalMessage: null,
};

HSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func,
  validation: PropTypes.shape({
    required: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    pattern: PropTypes.instanceOf(RegExp),
    validate: PropTypes.func,
  }),
  reference: PropTypes.shape({
    entity: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })).isRequired,
  }).isRequired,
  internalMessage: PropTypes.shape({
    message: PropTypes.string,
    loading: PropTypes.bool,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object,
  errorMessage: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  ...FormControl.propTypes,
};

export default HSelect;
