import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Box, FormControl, FormLabel, Select,
} from '@chakra-ui/core';

const SelectFilter = ({
  options, label, onChange, field, value, ...props
}) => {
  const action = (val) => onChange(val.target.value, field);
  return (
    <Box
      maxWidth="300px"
      my={4}
      d="inline-block"
      mr={4}
      verticalAlign="top"
      {...props}
    >
      <FormControl>
        <FormLabel htmlFor={field}>
          {label}
        </FormLabel>
        <Select onChange={action} name={field} variant="filled">
          {options.map((item) => (
            <option selected={value === item.value} key={`${field}.${item.value}`} value={item.value}>
              {item.label}
            </option>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

SelectFilter.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  })).isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number, PropTypes.bool,
  ]).isRequired,
  ...Box.propTypes,
};

SelectFilter.defaultProps = {
  onChange: () => {},
};

export default SelectFilter;
