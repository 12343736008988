import React from 'react';
import * as PropTypes from 'prop-types';
import {
  FormControl, FormErrorMessage, FormLabel, Textarea,
} from '@chakra-ui/core';

const VALIDATE_MESSAGES = {
  required: 'Este campo es requerido.',
  minLength: 'El valor indicado no es válido.',
  maxLength: 'El valor indicado no es válido.',
  pattern: 'El valor indicado no es válido.',
  validate: 'El valor indicado no es válido.',
};

const HTextArea = ({
  errors, label, name, register, validation, placeholder,
  errorMessage, type, value, ...props
}) => {
  let errMsg = null;
  if (errors[name]) {
    errMsg = (
      <FormErrorMessage>
        {errorMessage || VALIDATE_MESSAGES[errors[name].type]}
      </FormErrorMessage>
    );
  }
  return (
    <FormControl isInvalid={errors[name]} {...props}>
      <FormLabel htmlFor={name}>
        {label}
      </FormLabel>
      <Textarea
        name={name}
        ref={register(validation)}
        placeholder={placeholder}
        defaultValue={value}
        size="sm"
        width="100%"
      />
      {errMsg}
    </FormControl>
  );
};

HTextArea.defaultProps = {
  register: () => {},
  validation: {},
  placeholder: '',
  errors: {},
  errorMessage: null,
  value: null,
};

HTextArea.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func,
  validation: PropTypes.shape({
    required: PropTypes.bool,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    pattern: PropTypes.instanceOf(RegExp),
    validate: PropTypes.func,
  }),
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object,
  errorMessage: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ...FormControl.propTypes,
};

export default HTextArea;
