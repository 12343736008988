import React from 'react';
import { Route } from 'react-router-dom';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes';
import HomePage from '../Home';
import AccountPage from '../Account';
import { userCondition, withAuthorization, withEmailVerification } from '../../components/Session';
import { useHideLoader } from '../../util/functions';
import MainContainer from '../../layout/MainContainer';
import UsersPage from '../Users/page';
import EPS from '../EPS';
import Specialties from '../Specialties';
import Doctors from '../Doctors';
import Requests from '../Requests';
import NotificationsPage from '../Notifications';
import Appointments from '../Appointments';

const AppRoutes = () => {
  useHideLoader();
  return (
    <MainContainer>
      <Route path={ROUTES.HOME} exact component={HomePage} />
      <Route path={ROUTES.ACCOUNT} component={AccountPage} />
      <Route path={ROUTES.USERS} component={UsersPage} />
      <Route path={ROUTES.EPS} component={EPS} />
      <Route path={ROUTES.SPECIALTIES} component={Specialties} />
      <Route path={ROUTES.DOCTORS} component={Doctors} />
      <Route path={ROUTES.REQUESTS} component={Requests} />
      <Route path={ROUTES.APPOINTMENTS} component={Appointments} />
      <Route path={ROUTES.NOTIFICATIONS} component={NotificationsPage} />
    </MainContainer>
  );
};

export default compose(
  withEmailVerification,
  withAuthorization(userCondition),
)(AppRoutes);
