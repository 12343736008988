import { Badge, Box, SimpleGrid } from '@chakra-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { RequestTitle } from '../../routes/Requests/header';
import ButtonBox from '../../routes/Appointments/buttons';
import { FirebasePropType } from '../Firebase';
import {
  getSection, Bloque, Observaciones, getObjects, getDaysOrTimeString, fillServiceAndSpecialty,
} from '../RequestsComponents';
import { getDateTimeFromFirebase } from '../../util/functions';
import { DAY_PART, DAYS } from '../../routes/Requests/RequestPropType';
import { APPOINTMENT_TYPE } from '../../routes/Appointments/AppointmentPropType';

const fillAppointment = async (firebase, oldItem) => {
  const item = await fillServiceAndSpecialty(oldItem, firebase);
  const doctor = await firebase.doctor(item.doctor).get();
  item.doctorId = item.doctor;
  if (doctor.exists) {
    item.doctor = doctor.data().name;
  } else {
    item.doctor = 'Especialidad no asignada';
  }
  if (item.request && item.request.trim().length > 0) {
    const request = await firebase.request(item.request).get();
    if (request.exists) {
      item.request = {
        uid: request.id,
        ...request.data(),
      };
    } else {
      item.request = null;
    }
  }
  return item;
};

const getAppointment = async (firebase, uid, setMessage) => {
  const doc = await firebase.appointment(uid).get();
  if (doc.exists) {
    const item = {
      uid: doc.id,
      ...doc.data(),
    };
    return fillAppointment(firebase, item);
  }
  setMessage({
    loading: false,
    type: 'error',
    message: 'Ups! Lo sentimos, la cita que busca, no existe. Verifíque el listado.',
  });
  return null;
};

const DetailsComponent = ({
  appointment, cancelled, deleted, showActions, firebase,
}) => (
  <SimpleGrid
    columns={{
      base: 1, sm: 2, lg: 3,
    }}
  >
    {getSection('Datos de la cita')}
    <Bloque label="Fecha y hora cita" value={appointment.datetime} />
    <Bloque label="Duración" value={appointment.duration} />
    <Box mb={4}>
      <Badge variantColor={appointment.typeColor} fontSize="md">
        {appointment.type}
      </Badge>
      <RequestTitle color="gray.400" mt={0} fontSize="xs">
        Tipo de cita
      </RequestTitle>
    </Box>
    <Bloque label="Especialista" value={appointment.specialty} />
    <Bloque label="Médico" value={appointment.doctor} />
    <Bloque label="Tipo de servicio" value={appointment.serviceType} />
    {!!appointment.eps && (
      <Bloque label="EPS" value={appointment.eps} />
    )}
    <Box mb={4}>
      <Badge variantColor={appointment.statusColor} fontSize="md">
        {appointment.status}
      </Badge>
      <RequestTitle color="gray.400" mt={0} fontSize="xs">
        Estado de la cita
      </RequestTitle>
    </Box>
    {showActions && appointment.item && (
    <ButtonBox
      setMessage={{
        cancelled,
        deleted,
      }}
      firebase={firebase}
      appointment={appointment.item}
      hideView
      size="lg"
    />
    )}
    <Observaciones value={appointment.observations} />
    <Bloque label="Fecha creación" value={appointment.createdAt} />
    {!!appointment.lastEditAt && (
      <Bloque label="Última modificación" value={appointment.lastEditAt} />
    )}
    {getSection('Datos del paciente', true)}
    <Bloque label="Nombre" value={appointment.name} />
    <Bloque label="Tipo documento" value={appointment.documentType} />
    <Bloque label="Número documento" value={appointment.documentNumber} />
    <Bloque label="Número telefónico" value={appointment.phone} type="phone" />
    <Bloque label="Correo electrónico" value={appointment.email} type="email" />
    {!!appointment.request && (
      <>
        {getSection('Datos solicitud', true)}
        <Bloque label="Días sugeridos" value={appointment.request.days} />
        <Bloque label="Horarios sugeridos" value={appointment.request.dayPart} />
        <Observaciones value={appointment.request.observations} />
        <Bloque label="Fecha solicitud" value={appointment.request.createdAt} />
        {!!appointment.request.lastEditAt && (
          <Bloque label="Última modificación solicitud" value={appointment.request.lastEditAt} />
        )}
      </>
    )}
  </SimpleGrid>
);

DetailsComponent.defaultProps = {
  showActions: false,
  cancelled: () => {},
  deleted: () => {},
};

DetailsComponent.propTypes = {
  firebase: FirebasePropType.isRequired,
  showActions: PropTypes.bool,
  cancelled: PropTypes.func,
  deleted: PropTypes.func,
  appointment: PropTypes.shape({
    name: PropTypes.string,
    documentType: PropTypes.string,
    documentNumber: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    specialty: PropTypes.string,
    eps: PropTypes.string,
    serviceType: PropTypes.string,
    createdAt: PropTypes.string,
    lastEditAt: PropTypes.string,
    statusColor: PropTypes.string,
    status: PropTypes.string,
    observations: PropTypes.string,
    datetime: PropTypes.instanceOf(Date),
    duration: PropTypes.string,
    doctor: PropTypes.string,
    link: PropTypes.string,
    type: PropTypes.string,
    typeColor: PropTypes.string,
    request: PropTypes.shape({
      days: PropTypes.string,
      dayPart: PropTypes.string,
      uid: PropTypes.string,
      createdAt: PropTypes.string,
      lastEditAt: PropTypes.string,
      observations: PropTypes.string,
    }),
    // eslint-disable-next-line react/forbid-prop-types
    item: PropTypes.object,
  }).isRequired,
};

const getAppointmentObjects = () => {
  const APPOINTMENTTYPE_OBJECT = {};
  APPOINTMENT_TYPE.forEach((item) => {
    APPOINTMENTTYPE_OBJECT[item.value] = {
      type: item.label,
      typeColor: item.color,
    };
  });
  return APPOINTMENTTYPE_OBJECT;
};

const setAppointmentData = (appointment, setAppointment) => {
  const {
    DOCTYPE_OBJECT, SERVICETYPE_OBJECT, STATUS_OBJECT,
  } = getObjects();
  const newAppointment = {
    ...appointment,
    name: `${appointment.firstName} ${appointment.lastName}`,
    createdAt: getDateTimeFromFirebase(appointment.createdAt),
    datetime: getDateTimeFromFirebase(appointment.datetime),
    duration: `${appointment.duration} ${appointment.duration.startsWith('00') ? 'minutos' : 'horas'}`,
    documentType: DOCTYPE_OBJECT[appointment.documentType],
    serviceType: SERVICETYPE_OBJECT[appointment.serviceType],
    lastEditAt: getDateTimeFromFirebase(appointment.lastEditAt),
    ...STATUS_OBJECT[appointment.status],
    ...getAppointmentObjects()[appointment.type],
    item: appointment,
  };
  if (appointment.request) {
    newAppointment.request.days = getDaysOrTimeString(DAYS, appointment.request.days);
    newAppointment.request.dayPart = getDaysOrTimeString(DAY_PART, appointment.request.dayPart);
    newAppointment.request.createdAt = getDateTimeFromFirebase(appointment.request.createdAt);
    newAppointment.request.datetime = getDateTimeFromFirebase(appointment.request.datetime);
  }
  setAppointment(newAppointment);
};

const useLoadAppointment = (firebase, setMessage) => {
  const match = useRouteMatch();
  const location = useLocation();
  const [appointment, setAppointment] = React.useState({});
  React.useEffect(() => {
    if (location.state && location.state.item) {
      setAppointmentData(location.state.item, setAppointment);
      setMessage({
        loading: false,
        message: null,
        type: null,
      });
    } else if (match && match.params.id) {
      getAppointment(firebase, match.params.id, setMessage)
        .then((item) => {
          if (item) {
            setAppointmentData(item, setAppointment);
            setMessage({
              loading: false,
              type: null,
              message: null,
            });
          }
        })
        .catch(() => {
          setMessage({
            loading: false,
            message: 'Ups! Algo no salió bien cargando la cita que busca. Inténtelo de nuevo.',
            type: 'error',
          });
        });
    } else {
      setMessage({
        loading: false,
        message: 'Ups! No hemos encontrado la cita que busca. Verifique en el listado.',
        type: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, match]);
  return {
    setAppointment,
    appointment,
  };
};

export {
  getAppointment,
  DetailsComponent,
  useLoadAppointment,
  fillAppointment,
};
