import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Badge, Box, Divider, Heading,
} from '@chakra-ui/core';
import EPSpropTypes from './EPSpropTypes';
// import DeleteButton from '../../components/Buttons/delete';
import { FirebasePropType, withFirebase } from '../../components/Firebase';
import EditButton from '../../components/Buttons/edit';
import * as ROUTES from '../../constants/routes';
import ActivateButton from '../../components/Buttons/activate';

const EPSItem = ({ eps, firebase, setMessage }) => {
  // const deleteAction = async () => {
  //   try {
  //     await firebase.eps(eps.uid).delete();
  //     setMessage({
  //       type: 'success',
  //       message: `Se borró correctamente la EPS: ${eps.name}`,
  //     });
  //   } catch (e) {
  //     setMessage({
  //       type: 'error',
  //       message: `Ups! Algo no salió bien borrando la EPS: ${eps.name}. Intentalo de nuevo.`,
  //     });
  //   }
  // };
  const activateAction = async () => {
    const text = eps.active ? 'inactivar' : 'activar';
    try {
      await firebase.eps(eps.uid).set({
        active: !eps.active,
      }, { merge: true });
      setMessage({
        type: 'success',
        message: `Se logró ${text} correctamente la EPS: ${eps.name}`,
      });
    } catch (e) {
      setMessage({
        type: 'error',
        message: `Ups! Algo no salió bien al ${text} la EPS: ${eps.name}. Intentalo de nuevo.`,
      });
    }
  };
  return (
    <Box key={eps.uid} p={5} borderWidth={1} borderRadius={3}>
      <Badge variantColor={eps.active ? 'green' : 'gray'}>
        {eps.active ? 'Activa' : 'Inactiva'}
      </Badge>
      <Heading as="h4" size="md">
        {eps.name}
      </Heading>
      <Divider />
      <EditButton to={`${ROUTES.EPS}/edit/${eps.uid}`} />
      <ActivateButton ml={3} active={eps.active} action={activateAction} />
      { /* <DeleteButton ml={3} action={deleteAction} /> */ }
    </Box>
  );
};

EPSItem.propTypes = {
  eps: EPSpropTypes.isRequired,
  firebase: FirebasePropType.isRequired,
  setMessage: PropTypes.func.isRequired,
};

export default withFirebase(EPSItem);
