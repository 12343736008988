import React from 'react';
import { Box, Skeleton } from '@chakra-ui/core';
import { useRouteMatch } from 'react-router-dom';
import ModuleContainer from '../../layout/ModuleContainer';
import BackButton from '../../components/Buttons/back';
import * as ROUTES from '../../constants/routes';
import HForm from '../../components/Form/HForm';
import { FirebasePropType, withFirebase } from '../../components/Firebase';
import { useAlert } from '../../components/Notification/hAlert';
import { useRedirectToAfterSleep } from '../../util/functions';

const SpecialtiesEdit = ({ firebase }) => {
  const {
    setMessage, loading, alertComponent, message,
  } = useAlert(false, true);
  const [entity, setEntity] = React.useState(null);
  const match = useRouteMatch();
  React.useEffect(() => {
    if (match) {
      if (match.params.id) {
        firebase.specialty(match.params.id)
          .get()
          .then((doc) => {
            if (doc.exists) {
              setEntity({
                uid: doc.id,
                ...doc.data(),
              });
              setMessage({
                loading: false,
                message: null,
                type: null,
              });
            } else {
              setMessage({
                loading: false,
                type: 'error',
                message: 'Ups, no encontramos la especialidad que buscas en la base de datos.',
              });
            }
          }).catch(() => {
            setMessage({
              loading: false,
              type: 'error',
              message: 'Ups, algo no salió bien intentando cargar la especialidad. Intentalo de nuevo.',
            });
          });
      } else {
        setMessage({
          loading: false,
          type: 'warning',
          message: 'Ups, nos perdimos encontrando la página que estás buscando.',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  const redirect = useRedirectToAfterSleep(setMessage, 'la especialidad', ROUTES.SPECIALTIES);

  return (
    <ModuleContainer
      title={!entity ? 'Cargando especialidad...' : `Editar especialidad: ${entity.name}`}
      headerLeft={(
        <BackButton to={ROUTES.SPECIALTIES} />
      )}
    >
      {message ? alertComponent : (
        <Box maxWidth="400px">
          <Skeleton height={loading ? '500px' : 'auto'} isLoaded={!loading}>
            {!entity ? null : (
              <HForm
                collection="specialties"
                label="la especialidad"
                basePath={ROUTES.SPECIALTIES}
                postSubmit={redirect}
                entity={entity}
                model={[
                  {
                    validation: {
                      minLength: 4,
                      required: true,
                    },
                    name: 'name',
                    label: 'Nombre',
                    type: 'text',
                  },
                  {
                    name: 'active',
                    label: 'Activa',
                    type: 'boolean',
                  },
                ]}
              />
            )}
          </Skeleton>
        </Box>
      )}
    </ModuleContainer>
  );
};

SpecialtiesEdit.propTypes = {
  firebase: FirebasePropType.isRequired,
};

export default withFirebase(SpecialtiesEdit);
