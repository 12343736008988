import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Badge, Box, PseudoBox, SimpleGrid, Text,
} from '@chakra-ui/core';
import RequestPropTypes from './RequestPropType';
import { getDateTimeFromFirebase } from '../../util/functions';
import { RequestTitle } from './header';
import ButtonBox from './buttons';
import { FirebasePropType } from '../../components/Firebase';
import { STATUS_OBJECT } from '../../util/genericPropType';

const Bloque = ({ label, value }) => (
  <Box d={{ base: 'block', lg: 'table' }}>
    <RequestTitle d={{ base: 'block', lg: 'none' }}>
      {label}
    </RequestTitle>
    <Text d={{ base: 'block', lg: 'table-cell' }} verticalAlign="middle">
      {value}
    </Text>
  </Box>
);

Bloque.defaultProps = {
  value: '',
};

Bloque.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

const getEps = (item) => {
  if (item.serviceType === 'eps') {
    return item.eps;
  }
  return 'Particular';
};

const RequestItem = ({
  item, withBg, firebase, setMessage,
}) => {
  const [request, setRequest] = React.useState({});
  React.useEffect(() => {
    setRequest({
      createdAt: getDateTimeFromFirebase(item.createdAt),
      name: `${item.firstName} ${item.lastName}`,
      eps: getEps(item),
      specialty: item.specialty,
      ...STATUS_OBJECT[item.status],
    });
  }, [item]);
  return (
    <PseudoBox
      bg={withBg ? 'gray.50' : 'white'}
      _hover={{ bg: 'gray.200' }}
    >
      <SimpleGrid
        columns={{ base: 1, sm: 2, lg: 5 }}
        spacing={4}
        px={2}
        py={5}
      >
        <Bloque value={request.createdAt} label="Fecha solicitud" />
        <Bloque value={request.specialty} label="Especialidad" />
        <Bloque value={request.eps} label="EPS" />
        <Bloque value={request.name} label="Paciente" />
        <Box>
          <Badge variantColor={request.statusColor}>
            {request.status}
          </Badge>
          <ButtonBox setMessage={setMessage} firebase={firebase} request={item} />
        </Box>
      </SimpleGrid>
    </PseudoBox>
  );
};

RequestItem.propTypes = {
  item: RequestPropTypes.isRequired,
  withBg: PropTypes.bool.isRequired,
  firebase: FirebasePropType.isRequired,
  setMessage: PropTypes.func.isRequired,
};

export {
  Bloque,
  getEps,
};

export default RequestItem;
