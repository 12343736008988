/* eslint-disable react/prop-types */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const getPathName = (location) => {
  let pathname = ROUTES.HOME;
  if (location.pathname) {
    ({ pathname } = location);
  }
  return pathname;
};

const withAuthorization = (condition) => (Component) => {
  const WithAuthorization = ({
    firebase, location, history, ...props
  }) => {
    React.useEffect(() => {
      const listener = firebase.onAuthUserListener(
        (authUser) => {
          if (!condition(authUser)) {
            history.push({
              pathname: ROUTES.LOGIN,
              search: `?next=${getPathName(location, history)}`,
            });
          }
        },
        () => {
          history.push({
            pathname: ROUTES.LOGIN,
            search: `?next=${getPathName(location, history)}`,
          });
        },
      );
      return () => listener();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    return (
      <AuthUserContext.Consumer>
        {(authUser) => (condition(authUser) ? <Component {...props} /> : null)}
      </AuthUserContext.Consumer>
    );
  };
  return compose(
    withRouter,
    withFirebase,
  )(WithAuthorization);
};

export default withAuthorization;
