import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const needsEmailVerification = (authUser) => authUser
    && !authUser.emailVerified
    && authUser.providerData
    && authUser.providerData
      .map((provider) => provider.providerId)
      .includes('password');

const withEmailVerification = (Component) => {
  // eslint-disable-next-line react/prop-types
  const WithEmailVerification = ({ firebase, ...props }) => {
    const [sent, setSent] = React.useState(false);
    const onSendEmailVerification = () => {
      firebase
        // eslint-disable-next-line react/prop-types
        .doSendEmailVerification()
        .then(() => setSent(true));
    };
    return (
      <AuthUserContext.Consumer>
        {(authUser) => (needsEmailVerification(authUser) ? (
          <div>
            {sent ? (
              <p>
                E-Mail confirmation sent: Check you E-Mails (Spam
                folder included) for a confirmation E-Mail.
                Refresh this page once you confirmed your E-Mail.
              </p>
            ) : (
              <p>
                Verify your E-Mail: Check you E-Mails (Spam folder
                included) for a confirmation E-Mail or send
                another confirmation E-Mail.
              </p>
            )}

            <button
              type="button"
              onClick={onSendEmailVerification}
              disabled={sent}
            >
              Send confirmation E-Mail
            </button>
          </div>
        ) : (
          <Component {...props} />
        ))}
      </AuthUserContext.Consumer>
    );
  };

  return withFirebase(WithEmailVerification);
};

export default withEmailVerification;
