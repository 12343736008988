import React from 'react';
import { Heading, SimpleGrid, Skeleton } from '@chakra-ui/core';
import AddButton from '../../components/Buttons/add';
import ModuleContainer from '../../layout/ModuleContainer';
import * as ROUTES from '../../constants/routes';
import { FirebasePropType, withFirebase } from '../../components/Firebase';
import { useAlert } from '../../components/Notification/hAlert';
import AppointmentItem from './item';
import { useReferenceAfterLoading, useReference } from '../../util/functions';
import AppointmentHeader from './header';
import { useAppointmentFilter } from '../../components/Filters';

const AppointmentsList = ({ firebase }) => {
  const [data, setData] = React.useState({
    loading: true,
    list: [],
  });
  const { alertComponent, setMessage, loading } = useAlert(true, true);
  const specialties = useReference(
    firebase, setMessage, 'specialties', 'specialty', 'las especialidades', 'Sin especialidad',
  );
  const epss = useReference(
    firebase, setMessage, 'epss', 'eps', 'las EPSs', 'Sin EPS',
  );
  const doctors = useReference(
    firebase, setMessage, 'doctors', 'doctor', 'los médicos', 'Sin médico',
  );
  const {
    component: filterComponent,
    valueFilter,
  } = useAppointmentFilter(
    specialties, epss, doctors, true, false, false,
  );
  React.useEffect(() => {
    let ref = firebase.appointments();
    let text = null;
    Object.keys(valueFilter).forEach((key) => {
      if (key === 'start') {
        ref = ref.where('datetime', '>=', valueFilter.start);
      } else if (key === 'end') {
        ref = ref.where('datetime', '<=', valueFilter.end);
      } else if (key === 'text' && valueFilter[key] !== '') {
        text = valueFilter[key];
      } else if (valueFilter[key] && valueFilter[key] !== '') {
        ref = ref.where(key, '==', valueFilter[key]);
      }
    });
    const unsubscribe = ref.onSnapshot((snapshot) => {
      setData((prev) => ({
        ...prev,
        loading: true,
      }));
      const list = [];
      if (!snapshot.empty) {
        snapshot.forEach((doc) => {
          const item = {
            uid: doc.id,
            ...doc.data(),
          };
          if (text) {
            if (`${item.firstName} ${item.lastName} ${item.documentNumber}`
              .toLowerCase().includes(text)) {
              list.push(item);
            }
          } else {
            list.push(item);
          }
        });
      }
      setData({
        loading: false,
        list,
      });
    }, () => {
      setMessage({
        loading: false,
        type: 'error',
        message: 'Ups! Algo pasó y no pudimos cargar las citas. Recarga la página para intentarlo nuevamente.',
      });
    });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueFilter]);
  useReferenceAfterLoading(
    data, setData, setMessage, specialties, epss, doctors,
  );
  return (
    <ModuleContainer
      title="Citas"
      headerRight={(
        <AddButton to={`${ROUTES.APPOINTMENTS}/new`} />
      )}
    >
      <Skeleton height={loading ? '500px' : 'auto'} isLoaded={!loading}>
        {alertComponent}
        {filterComponent}
        {data.list.length === 0 ? (
          <Heading as="h4" size="lg" textAlign="center" color="gray.400" mt={10}>
            {'No encontramos ningúna cita cargada en la base datos... '}
            <span role="img" aria-label="carita pensando">🧐</span>
          </Heading>
        ) : (
          <>
            <AppointmentHeader />
            <SimpleGrid columns={1} spacing={{ base: 5, lg: 0 }}>
              {data.list.map((item, index) => (
                <AppointmentItem
                  key={item.uid}
                  item={item}
                  firebase={firebase}
                  setMessage={setMessage}
                  withBg={index % 2 === 0}
                />
              ))}
            </SimpleGrid>
          </>
        )}
      </Skeleton>
    </ModuleContainer>
  );
};

AppointmentsList.propTypes = {
  firebase: FirebasePropType.isRequired,
};

export default withFirebase(AppointmentsList);
