import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.fieldValue = app.firestore.FieldValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.firestore();

    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword(email, password) {
    return this.auth.createUserWithEmailAndPassword(email, password);
  }

  doSignInWithEmailAndPassword(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  doSignInWithGoogle() {
    return this.auth.signInWithPopup(this.googleProvider);
  }

  doSignInWithFacebook() {
    return this.auth.signInWithPopup(this.facebookProvider);
  }

  doSignInWithTwitter() {
    return this.auth.signInWithPopup(this.twitterProvider);
  }

  doSignOut() {
    return this.auth.signOut();
  }

  doPasswordReset(email) {
    return this.auth.sendPasswordResetEmail(email);
  }

  doSendEmailVerification() {
    return this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });
  }

  doPasswordUpdate(password) {
    return this.auth.currentUser.updatePassword(password);
  }

  // *** Merge Auth and DB User API *** //

  onAuthUserListener(next, fallback) {
    return this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then((snapshot) => {
            const dbUser = snapshot.data();

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            const nAuthUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };
            next(nAuthUser);
          });
      } else {
        fallback();
      }
    });
  }

  // *** User API ***

  user(uid) {
    return this.db.doc(`users/${uid}`);
  }

  users() {
    return this.db.collection('users');
  }

  // *** Message API ***

  message(uid) {
    return this.db.doc(`messages/${uid}`);
  }

  messages() {
    return this.db.collection('messages');
  }

  // *** EPS ***

  epss() {
    return this.db.collection('eps');
  }

  eps(uid) {
    return this.epss().doc(uid);
  }

  // *** Specialties ***

  specialties() {
    return this.db.collection('specialties');
  }

  specialty(uid) {
    return this.specialties().doc(uid);
  }

  // *** Doctors ***
  doctors() {
    return this.db.collection('doctors');
  }

  doctor(uid) {
    return this.doctors().doc(uid);
  }

  // *** Requests ***
  requests() {
    return this.db.collection('requests');
  }

  request(uid) {
    return this.requests().doc(uid);
  }

  // *** Notifications ***
  notifications() {
    return this.db.collection('notifications');
  }

  notification() {
    return this.notifications().doc('main');
  }

  // *** Appointments ***
  appointments() {
    return this.db.collection('appointments');
  }

  appointment(uid) {
    return this.appointments().doc(uid);
  }
}

export default Firebase;
