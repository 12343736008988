import React from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { RiArrowGoBackLine } from 'react-icons/ri';
import { IconButton } from '@chakra-ui/core';

const BackButton = ({ to, ...props }) => (
  <IconButton
    aria-label="Regresar"
    variantColor="gray"
    isRound
    size="sm"
    as={Link}
    to={to}
    icon={RiArrowGoBackLine}
    {...props}
  />
);

BackButton.propTypes = {
  to: PropTypes.string.isRequired,
  ...IconButton.propTypes,
  children: PropTypes.node,
  'aria-label': PropTypes.string,
};

BackButton.defaultProps = {
  children: null,
  'aria-label': null,
};

export default BackButton;
