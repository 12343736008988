import React from 'react';
import { Box } from '@chakra-ui/core';
import ModuleContainer from '../../layout/ModuleContainer';
import BackButton from '../../components/Buttons/back';
import * as ROUTES from '../../constants/routes';
import HForm from '../../components/Form/HForm';
import { DoctorDefaultProps } from './DoctorPropTypes';

const SpecialtiesNew = () => (
  <ModuleContainer
    title="Nuevo Médico"
    headerLeft={(
      <BackButton to={ROUTES.DOCTORS} />
    )}
  >
    <Box maxWidth="400px">
      <HForm
        collection="doctors"
        basePath={ROUTES.DOCTORS}
        label="el médico"
        entity={DoctorDefaultProps}
        model={[
          {
            validation: {
              minLength: 4,
              required: true,
            },
            name: 'name',
            label: 'Nombre',
            type: 'text',
          },
          {
            validation: {
              pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              required: true,
            },
            name: 'email',
            label: 'Correo electrónico',
            type: 'email',
          },
          {
            validation: {
              validate: (val) => val.length === 10 || val.length === 7,
              required: true,
            },
            name: 'phone',
            label: 'Número Telefónico',
            type: 'numeric',
          },
          {
            validation: {
              required: true,
            },
            name: 'specialty',
            label: 'Especialidad',
            type: 'reference',
            reference: {
              entity: 'specialties',
              where: [
                {
                  field: 'active', operator: '==', value: true,
                },
              ],
            },
          },
          {
            name: 'active',
            label: 'Activo',
            type: 'boolean',
          },
        ]}
      />
    </Box>
  </ModuleContainer>
);

export default SpecialtiesNew;
