import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Badge, Box, Divider, Heading,
} from '@chakra-ui/core';
import SpecialtyPropTypes from './SpecialtyPropTypes';
// import DeleteButton from '../../components/Buttons/delete';
import { FirebasePropType, withFirebase } from '../../components/Firebase';
import EditButton from '../../components/Buttons/edit';
import * as ROUTES from '../../constants/routes';
import ActivateButton from '../../components/Buttons/activate';

const SpecialtyItem = ({ item, firebase, setMessage }) => {
  // const deleteAction = async () => {
  //   try {
  //     const snap = await firebase.doctors()
  //       .where('specialty', '==', item.uid).get();
  //     if (snap.size > 0) {
  //       setMessage({
  //         type: 'warning',
  //         message: `No se puede borrar esta especialidad, hay ${snap.size} médico(s) usandola.`,
  //       });
  //       return;
  //     }
  //     await firebase.specialty(item.uid).delete();
  //     setMessage({
  //       type: 'success',
  //       message: `Se borró correctamente la especialidad: ${item.name}.`,
  //     });
  //   } catch (e) {
  //     setMessage({
  //       type: 'error',
  //       message: `Ups! Algo no salió bien borrando la especialidad: ${item.name}.
  //       Intentalo de nuevo.`,
  //     });
  //   }
  // };
  const activateAction = async () => {
    const text = item.active ? 'inactivar' : 'activar';
    try {
      await firebase.specialty(item.uid).set({
        active: !item.active,
      }, { merge: true });
      setMessage({
        type: 'success',
        message: `Se logró ${text} correctamente la especialidad: ${item.name}`,
      });
    } catch (e) {
      setMessage({
        type: 'error',
        message: `Ups! Algo no salió bien al ${text} la especialidad: ${item.name}. Intentalo de nuevo.`,
      });
    }
  };
  return (
    <Box key={item.uid} p={5} borderWidth={1} borderRadius={3}>
      <Badge variantColor={item.active ? 'green' : 'gray'}>
        {item.active ? 'Activa' : 'Inactiva'}
      </Badge>
      <Heading as="h4" size="md">
        {item.name}
      </Heading>
      <Divider />
      <EditButton to={`${ROUTES.SPECIALTIES}/edit/${item.uid}`} />
      { /* <DeleteButton ml={3} action={deleteAction} /> */ }
      <ActivateButton active={item.active} action={activateAction} ml={2} />
    </Box>
  );
};

SpecialtyItem.propTypes = {
  item: SpecialtyPropTypes.isRequired,
  firebase: FirebasePropType.isRequired,
  setMessage: PropTypes.func.isRequired,
};

export default withFirebase(SpecialtyItem);
