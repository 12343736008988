import React from 'react';
import { useLocation } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
// import * as ROLES from '../../constants/roles';
import { AuthUserPropType } from '../Session';
import MenuItem from './MenuItem';
import MenuLabel from './MenuLabel';

const isActive = (currentPath, path) => {
  if (path === ROUTES.HOME) {
    return currentPath === ROUTES.HOME;
  }
  return currentPath.startsWith(path);
};

const getMenuItem = (item, authUser, location) => {
  if (item.condition && !item.condition(authUser)) {
    return [];
  }
  if (item.path) {
    const active = isActive(location.pathname, item.path);
    return [(
      <MenuItem key={item.label} route={item.path} text={item.label} active={active} />
    )];
  }
  if (item.links) {
    const results = [];
    if (item.label) {
      results.push((
        <MenuLabel key={item.label} text={item.label} />
      ));
    }
    Object.keys(item.links).forEach((key) => {
      results.push(...getMenuItem(item.links[key], authUser, location));
    });
    return results;
  }
  return [];
};

const AuthMenu = ({ authUser }) => {
  const location = useLocation();
  const [menu, setMenu] = React.useState(null);
  React.useEffect(() => {
    if (authUser && location) {
      setMenu(getMenuItem(ROUTES.default, authUser, location));
    }
  }, [authUser, location]);
  return menu;
};

AuthMenu.propTypes = {
  authUser: AuthUserPropType,
};

AuthMenu.defaultProps = {
  authUser: null,
};

export default AuthMenu;
