import React from 'react';
import * as PropTypes from 'prop-types';
import { Box, Flex, useDisclosure } from '@chakra-ui/core';
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

const MainContainer = ({ children }) => {
  const { isOpen, onClose, onToggle } = useDisclosure();
  return (
    <>
      <Header toggleMenu={onToggle} />
      <Flex direction="row">
        <Navigation onClose={onClose} isOpen={isOpen} />
        <Box
          w="100%"
          p={5}
        >
          {children}
          <Footer />
        </Box>
      </Flex>
    </>
  );
};

MainContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainContainer;
