import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Box, Skeleton } from '@chakra-ui/core';
import { useAlert } from '../../components/Notification/hAlert';
import { FirebasePropType, withFirebase } from '../../components/Firebase';
import { useRedirectToAfterSleep } from '../../util/functions';
import * as ROUTES from '../../constants/routes';
import BackButton from '../../components/Buttons/back';
import ModuleContainer from '../../layout/ModuleContainer';
import {
  RequestModel,
} from './RequestPropType';
import HForm from '../../components/Form/HForm';
import { STATUS_OPTIONS } from '../../util/genericPropType';

const RequestEdit = ({ firebase }) => {
  const {
    setMessage, alertComponent, message,
  } = useAlert(false, true);
  const [entity, setEntity] = React.useState(null);
  const [model, setModel] = React.useState(null);
  React.useEffect(() => {
    const newModel = [
      ...RequestModel,
    ];
    newModel.pop();
    const status = [
      ...STATUS_OPTIONS,
    ];
    status.pop();
    newModel.push({
      validation: {
        required: true,
      },
      name: 'status',
      label: 'Estado de la solicitud',
      type: 'select',
      reference: {
        entity: status,
      },
    });
    setModel(newModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const match = useRouteMatch();
  React.useEffect(() => {
    if (match) {
      if (match.params.id) {
        firebase.request(match.params.id)
          .get()
          .then((doc) => {
            if (doc.exists) {
              setEntity({
                uid: doc.id,
                ...doc.data(),
              });
              setMessage({
                loading: false,
                message: null,
                type: null,
              });
            } else {
              setMessage({
                loading: false,
                type: 'error',
                message: 'Ups, no encontramos la solicitud de cita que buscas en la base de datos.',
              });
            }
          }).catch(() => {
            setMessage({
              loading: false,
              type: 'error',
              message: 'Ups, algo no salió bien intentando cargar la solicitud de cita. Intentalo de nuevo.',
            });
          });
      } else {
        setMessage({
          loading: false,
          type: 'warning',
          message: 'Ups, nos perdimos encontrando la página que estás buscando.',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  const redirect = useRedirectToAfterSleep(setMessage, 'la solicitud de cita', ROUTES.REQUESTS);

  return (
    <ModuleContainer
      title={!entity
        ? 'Cargando solicitud...'
        : `Editar solicitud de: ${entity.firstName} ${entity.lastName}`}
      headerLeft={(
        <BackButton to={ROUTES.REQUESTS} />
      )}
    >
      {message ? alertComponent : (
        <Box maxWidth="950px">
          <Skeleton height={!(!!entity && !!model) ? '500px' : 'auto'} isLoaded={(!!entity && !!model)}>
            {(!entity || !model) ? null : (
              <HForm
                collection="requests"
                columns={2}
                basePath={ROUTES.REQUESTS}
                label="la solicitud"
                postSubmit={redirect}
                entity={entity}
                model={model}
              />
            )}
          </Skeleton>
        </Box>
      )}
    </ModuleContainer>
  );
};

RequestEdit.propTypes = {
  firebase: FirebasePropType.isRequired,
};

export default withFirebase(RequestEdit);
