import * as PropTypes from 'prop-types';
import GenericPropType, { GenericDefaultProps } from '../../util/genericPropType';

const SpecialtyPropTypes = PropTypes.shape({
  name: PropTypes.string,
  active: PropTypes.bool,
  ...GenericPropType,
});

export const SpecialtyDefaultProps = {
  name: '',
  active: true,
  ...GenericDefaultProps,
};

export default SpecialtyPropTypes;
