import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Badge, Box, PseudoBox, SimpleGrid, Skeleton,
} from '@chakra-ui/core';
import AppointmentPropTypes from './AppointmentPropType';
import ButtonBox from './buttons';
import { FirebasePropType } from '../../components/Firebase';
import { Bloque } from '../Requests/item';
import { getDateTimeFromFirebase } from '../../util/functions';
import { STATUS_OBJECT } from '../../util/genericPropType';

const AppointmentItem = ({
  item, withBg, firebase, setMessage,
}) => {
  const [appointment, setAppointment] = React.useState({});
  const setItem = React.useCallback((theItem) => {
    setAppointment({
      datetime: getDateTimeFromFirebase(theItem.datetime),
      name: `${theItem.firstName} ${theItem.lastName}`,
      specialty: theItem.specialty,
      duration: theItem.duration,
      doctor: theItem.doctor,
      eps: theItem.eps,
      ...STATUS_OBJECT[theItem.status],
      item: theItem,
    });
  }, []);
  React.useEffect(() => {
    if (item.request) {
      firebase.request(item.request).get()
        .then((request) => {
          const theItem = {
            ...item,
          };
          if (request.exists) {
            theItem.request = {
              uid: request.id,
              ...request.data(),
            };
          }
          setItem(theItem);
        });
    } else {
      setItem(item);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);
  return (
    <Skeleton isLoaded={!!appointment.datetime}>
      <PseudoBox
        bg={withBg ? 'gray.50' : 'white'}
        _hover={{ bg: 'gray.200' }}
      >
        <SimpleGrid
          columns={{ base: 1, sm: 2, lg: 7 }}
          spacing={3}
          px={2}
          py={5}
        >
          <Bloque value={appointment.datetime} label="Fecha cita" />
          <Bloque value={appointment.duration} label="Duración" />
          <Bloque value={appointment.specialty} label="Especialidad" />
          <Bloque value={appointment.doctor} label="Médico" />
          <Bloque value={appointment.eps} label="EPS" />
          <Bloque value={appointment.name} label="Paciente" />
          <Box>
            <Badge variantColor={appointment.statusColor}>
              {appointment.status}
            </Badge>
            <ButtonBox setMessage={setMessage} firebase={firebase} appointment={appointment.item} />
          </Box>
        </SimpleGrid>
      </PseudoBox>
    </Skeleton>
  );
};

AppointmentItem.propTypes = {
  item: AppointmentPropTypes.isRequired,
  withBg: PropTypes.bool.isRequired,
  firebase: FirebasePropType.isRequired,
  setMessage: PropTypes.func.isRequired,
};

export default AppointmentItem;
