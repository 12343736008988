import React from 'react';
import {
  FormControl, FormErrorMessage, FormLabel, Switch,
} from '@chakra-ui/core';
import * as PropTypes from 'prop-types';

const VALIDATE_MESSAGES = {
  required: 'Este campo es requerido.',
};

const SwitchBase = ({
  errors, label, name, register, validation,
  errorMessage, value, isDisabled, ...props
}) => {
  let errMsg = null;
  if (errors[name]) {
    errMsg = (
      <FormErrorMessage>
        {errorMessage || VALIDATE_MESSAGES[errors[name].type]}
      </FormErrorMessage>
    );
  }
  return (
    <FormControl isInvalid={errors[name]} isDisabled={isDisabled} {...props}>
      <FormLabel htmlFor={name}>
        {label}
      </FormLabel>
      <Switch
        defaultIsChecked={value}
        name={name}
        isDisabled={isDisabled}
        ref={register(validation)}
      />
      {errMsg}
    </FormControl>
  );
};

SwitchBase.defaultProps = {
  register: () => {},
  validation: {},
  errors: {},
  errorMessage: null,
  value: null,
};

SwitchBase.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func,
  validation: PropTypes.shape({
    required: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    pattern: PropTypes.instanceOf(RegExp),
    validate: PropTypes.func,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object,
  errorMessage: PropTypes.string,
  value: PropTypes.bool,
  ...FormControl.propTypes,
};

export default SwitchBase;
