import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import EpsList from './list';
import EPSNew from './new';
import EPSEdit from './edit';
import { withAuthorization } from '../../components/Session';
import { isEditor } from '../../util/functions';

const EPS = () => (
  <Switch>
    <Route path={`${ROUTES.EPS}/new`} component={EPSNew} />
    <Route path={`${ROUTES.EPS}/edit/:epsid`} component={EPSEdit} />
    <Route path={ROUTES.EPS} component={EpsList} />
  </Switch>
);

export default withAuthorization(isEditor)(EPS);
