import React from 'react';
import {
  Box, Image, Link, Text,
} from '@chakra-ui/core';

const Footer = () => (
  <Box clear="both" opacity={0.3} mt={5} style={{ filter: 'grayscale(100%)' }}>
    <Text fontSize="xs" verticalAlign="top">
      SISTEMA PARA GESTIÓN DE CITAS VIRTUALES V 1.4
    </Text>
    <Link isExternal href="mailto:info@soluciones404.com.co">
      <Image src="/logo-soluciones.png" width="100px" />
    </Link>
    <Text fontSize="xs" verticalAlign="top">
      &copy; 2020
    </Text>
  </Box>
);

export default Footer;
