import * as PropTypes from 'prop-types';

const SERVICE_TYPE = [
  {
    value: 'eps',
    label: 'EPS',
  },
  {
    value: 'private',
    label: 'Particular',
  },
];

const STATUS_OPTIONS = [
  {
    value: 'pending',
    label: 'Pendiente',
    color: 'red',
  },
  {
    value: 'cancelled',
    label: 'Cancelada',
    color: 'gray',
  },
  {
    value: 'scheduled',
    label: 'Agendada',
    color: 'green',
  },
];

const getStatusOptionsFilter = (all) => {
  const options = [
    {
      value: '',
      label: 'Todos',
    },
    ...STATUS_OPTIONS,
  ];
  if (all) {
    return options;
  }
  options.splice(1, 1);
  return options;
};

const ACTIVE_OPTIONS = [
  {
    value: null,
    label: 'Todos',
  },
  {
    value: true,
    label: 'Activos',
  },
  {
    value: false,
    label: 'Inactivos',
  },
];

const ACTIVE_OPTIONS_FEM = [
  {
    value: null,
    label: 'Todas',
  },
  {
    value: true,
    label: 'Activas',
  },
  {
    value: false,
    label: 'Inactivas',
  },
];

const DOCUMENT_TYPES = [
  {
    value: 'cc',
    label: 'Cédula de ciudadanía',
  },
  {
    value: 'ce',
    label: 'Cédula de extranjería',
  },
  {
    value: 'ti',
    label: 'Tarjeta de identidad',
  },
  {
    value: 'pa',
    label: 'Pasaporte',
  },
  {
    value: 'rc',
    label: 'Registro civil',
  },
  {
    value: 'nv',
    label: 'Certificado de nacido vivo',
  },
  {
    value: 'pp',
    label: 'Permiso especial de permanencia',
  },
  {
    value: 'pt',
    label: 'Permiso por protección temporal',
  },
];

const TimeStampPropType = PropTypes.shape({
  seconds: PropTypes.number,
  nanoseconds: PropTypes.number,
});

const GenericPropType = {
  uid: PropTypes.string,
  userId: PropTypes.string,
  createdAt: TimeStampPropType,
  lastEditAt: TimeStampPropType,
};

const GenericDefaultProps = {
  uid: null,
  userId: null,
  createdAt: null,
  lastEditAt: null,
};

const STATUS_OBJECT = {};
STATUS_OPTIONS.forEach((item) => {
  STATUS_OBJECT[item.value] = {
    status: item.label,
    statusColor: item.color,
  };
});

export {
  STATUS_OBJECT,
  TimeStampPropType,
  GenericDefaultProps,
  DOCUMENT_TYPES,
  SERVICE_TYPE,
  STATUS_OPTIONS,
  ACTIVE_OPTIONS,
  ACTIVE_OPTIONS_FEM,
  getStatusOptionsFilter,
};

export default GenericPropType;
