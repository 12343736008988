import React from 'react';
import { Route } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
// import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import { useHideLoader } from '../../util/functions';
import AuthContainer from '../../layout/AuthContainer';

const AuthRoutes = () => {
  useHideLoader();
  return (
    <AuthContainer>
      <Route path={ROUTES.LOGIN} exact component={SignInPage} />
      {/* <Route path={ROUTES.SIGN_UP} component={SignUpPage} /> */}
      <Route
        path={ROUTES.PASSWORD_FORGET}
        component={PasswordForgetPage}
      />
    </AuthContainer>
  );
};

export default AuthRoutes;
