import React from 'react';
import { Box } from '@chakra-ui/core';
import ModuleContainer from '../../layout/ModuleContainer';
import BackButton from '../../components/Buttons/back';
import * as ROUTES from '../../constants/routes';
import { FirebasePropType, withFirebase } from '../../components/Firebase';
import AppointmentForm from './form';
import { AppointmentDefaultProps } from './AppointmentPropType';

const AppointmentNew = ({ firebase }) => (
  <ModuleContainer
    title="Nueva cita médica"
    headerLeft={(
      <BackButton to={ROUTES.APPOINTMENTS} />
      )}
  >
    <Box maxWidth="950px">
      <AppointmentForm firebase={firebase} appointment={AppointmentDefaultProps} />
    </Box>
  </ModuleContainer>
);

AppointmentNew.propTypes = {
  firebase: FirebasePropType.isRequired,
};

export default withFirebase(AppointmentNew);
