import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import DoctorsList from './list';
import DoctorsNew from './new';
import DoctorsEdit from './edit';
import { withAuthorization } from '../../components/Session';
import { isEditor } from '../../util/functions';

const Doctors = () => (
  <Switch>
    <Route path={`${ROUTES.DOCTORS}/new`} component={DoctorsNew} />
    <Route path={`${ROUTES.DOCTORS}/edit/:id`} component={DoctorsEdit} />
    <Route path={ROUTES.DOCTORS} component={DoctorsList} />
  </Switch>
);

export default withAuthorization(isEditor)(Doctors);
