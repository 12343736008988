import React from 'react';
import { Box, Skeleton } from '@chakra-ui/core';
import { useRouteMatch } from 'react-router-dom';
import ModuleContainer from '../../layout/ModuleContainer';
import BackButton from '../../components/Buttons/back';
import * as ROUTES from '../../constants/routes';
import HForm from '../../components/Form/HForm';
import { FirebasePropType, withFirebase } from '../../components/Firebase';
import { useAlert } from '../../components/Notification/hAlert';
import { useRedirectToAfterSleep } from '../../util/functions';

const EPSEdit = ({ firebase }) => {
  const {
    setMessage, loading, alertComponent, message,
  } = useAlert(false, true);
  const [eps, setEps] = React.useState(null);
  const match = useRouteMatch();
  React.useEffect(() => {
    if (match) {
      if (match.params.epsid) {
        firebase.eps(match.params.epsid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              setEps({
                uid: doc.id,
                ...doc.data(),
              });
              setMessage({
                loading: false,
                message: null,
                type: null,
              });
            } else {
              setMessage({
                loading: false,
                type: 'error',
                message: 'Ups, no encontramos la EPS que buscas en la base de datos.',
              });
            }
          }).catch(() => {
            setMessage({
              loading: false,
              type: 'error',
              message: 'Ups, algo no salió bien intentando cargar la EPS. Intentalo de nuevo.',
            });
          });
      } else {
        setMessage({
          loading: false,
          type: 'warning',
          message: 'Ups, nos perdimos encontrando la página que estás buscando.',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  const redirect = useRedirectToAfterSleep(setMessage, 'la EPS', ROUTES.EPS);

  return (
    <ModuleContainer
      title={!eps ? 'Cargando EPS...' : `Editar EPS: ${eps.name}`}
      headerLeft={(
        <BackButton to={ROUTES.EPS} />
      )}
    >
      {message ? alertComponent : (
        <Box maxWidth="400px">
          <Skeleton height={loading ? '500px' : 'auto'} isLoaded={!loading}>
            {!eps ? null : (
              <HForm
                collection="epss"
                label="la EPS"
                basePath={ROUTES.EPS}
                postSubmit={redirect}
                entity={eps}
                model={[
                  {
                    validation: {
                      minLength: 4,
                      required: true,
                    },
                    name: 'name',
                    label: 'Nombre',
                    type: 'text',
                  },
                  {
                    name: 'active',
                    label: 'Activa',
                    type: 'boolean',
                  },
                ]}
              />
            )}
          </Skeleton>
        </Box>
      )}
    </ModuleContainer>
  );
};

EPSEdit.propTypes = {
  firebase: FirebasePropType.isRequired,
};

export default withFirebase(EPSEdit);
