import React from 'react';
import { useDisclosure } from '@chakra-ui/core';
import { FirebasePropType, withFirebase } from '../Firebase';
import { showLoader, sleep } from '../../util/functions';
import { LogoutIcon } from '../../styles/Icons';
import Confirm from '../Confirm';

const SingOutButton = ({ firebase }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const onAction = async () => {
    showLoader();
    await sleep(1000);
    await firebase.doSignOut();
  };
  return (
    <>
      <LogoutIcon aria-label="Cerrar Sesión" onClick={onOpen} />
      <Confirm
        isOpen={isOpen}
        message="¿Está seguro de que desea cerrar sesión?"
        title="Confirmar Cerrar Sesión"
        onAccept={onAction}
        onClose={onClose}
      />
    </>
  );
};

SingOutButton.propTypes = {
  firebase: FirebasePropType.isRequired,
};

export default withFirebase(SingOutButton);
