import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Badge, Box, Divider, Flex, Heading, Link,
} from '@chakra-ui/core';
import axios from 'axios';
import UserPropTypes, { getRolesEntity } from './UserPropTypes';
import EditButton from '../../components/Buttons/edit';
import * as ROUTES from '../../constants/routes';
import { AuthUserPropType, withCurrentUser } from '../../components/Session';
import { USUARIOS_URL } from '../../constants/apis';
import { encrypt } from '../../util/functions';
import ActivateButton from '../../components/Buttons/activate';

const UserItem = ({
  item,
  authUser,
  setMessage,
}) => {
  const [roles, setRoles] = React.useState([]);
  const [isDisabled, setDisabled] = React.useState(false);
  React.useEffect(() => {
    const rolesEntity = getRolesEntity();
    const nRoles = [];
    rolesEntity.forEach((entityRole) => {
      if (item.roles[entityRole.name]) {
        nRoles.push(entityRole.value);
      }
    });
    setRoles(nRoles);
  }, [item.roles]);
  const toggleAction = async () => {
    setDisabled(true);
    setMessage({
      title: null,
      message: 'Estamos procesando la petición de actualización...',
      type: 'warning',
    });
    try {
      const userId = encrypt(authUser.uid);
      const uid = encrypt(item.uid);
      const result = await axios({
        method: 'PUT',
        url: USUARIOS_URL,
        data: {
          userId,
          uid,
          active: !item.active,
          action: 'status',
        },
      });
      const {
        type: nType, message: title, details,
      } = result.data;
      const message = nType === 'error'
        ? details
        : `Usuario: <strong>${item.email}</strong> marcado como <strong>${item.active ? 'Inactivo' : 'Activo'}</strong>`;
      setMessage({
        type: nType,
        title,
        message,
        loading: false,
      });
    } catch (e) {
      setMessage({
        type: 'error',
        title: null,
        message: e.message,
        loading: false,
      });
    } finally {
      setDisabled(false);
    }
  };
  return (
    <Box key={item.uid} p={5} borderWidth={1} borderRadius={3}>
      <Badge variantColor={item.active ? 'green' : 'gray'}>
        {item.active ? 'Activo' : 'Inactivo'}
      </Badge>
      <Heading as="h4" size="md">
        {item.name}
      </Heading>
      <Link size="sm" isExternal href={`mailto:${item.email}`}>
        {item.email}
      </Link>
      <Flex>
        {roles.map((rol) => (
          <Badge key={`${item.uid}${rol}`} variantColor="gray" mr={2}>
            {rol}
          </Badge>
        ))}
      </Flex>
      <Divider />
      <EditButton
        isDisabled={isDisabled}
        to={`${ROUTES.USERS}/edit/${item.uid}`}
      />
      <ActivateButton
        isDisabled={isDisabled}
        ml={3}
        active={item.active}
        action={toggleAction}
      />
    </Box>
  );
};

UserItem.propTypes = {
  item: UserPropTypes.isRequired,
  authUser: AuthUserPropType.isRequired,
  // firebase: FirebasePropType.isRequired,
  setMessage: PropTypes.func.isRequired,
};

export default withCurrentUser(UserItem);
