import * as PropTypes from 'prop-types';
import GenericPropType, {
  GenericDefaultProps, SERVICE_TYPE, DOCUMENT_TYPES, STATUS_OPTIONS,
} from '../../util/genericPropType';

export const DAYS = [
  {
    label: 'Lunes',
    value: 'monday',
  },
  {
    label: 'Martes',
    value: 'tuesday',
  },
  {
    label: 'Miercoles',
    value: 'wednesday',
  },
  {
    label: 'Jueves',
    value: 'thursday',
  },
  {
    label: 'Viernes',
    value: 'friday',
  },
  {
    label: 'Sábado',
    value: 'saturday',
  },
  {
    label: 'Domingo',
    value: 'sunday',
  },
];

export const DAY_PART = [
  {
    label: 'Mañana',
    value: 'morning',
  },
  {
    label: 'Tarde',
    value: 'afternoon',
  },
];

const getDefaults = (OBJECT, propTypes = false) => {
  const defaults = {};
  OBJECT.forEach((item) => {
    defaults[item.value] = propTypes ? PropTypes.bool : false;
  });
  return defaults;
};

const RequestPropTypes = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  status: PropTypes.oneOf(STATUS_OPTIONS.map((item) => item.value)),
  documentType: PropTypes.oneOf(DOCUMENT_TYPES.map((item) => item.value)),
  documentNumber: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  specialty: PropTypes.string,
  serviceType: PropTypes.oneOf(SERVICE_TYPE.map((item) => item.value)),
  eps: PropTypes.string,
  observations: PropTypes.string,
  days: PropTypes.shape(getDefaults(DAYS, true)),
  dayPart: PropTypes.shape(getDefaults(DAY_PART, true)),
  ...GenericPropType,
});

export const RequestModel = [
  {
    type: 'section',
    label: 'Datos Personales',
  },
  {
    validation: {
      minLength: 4,
      required: true,
    },
    name: 'firstName',
    label: 'Nombres',
    type: 'text',
  },
  {
    validation: {
      minLength: 4,
      required: true,
    },
    name: 'lastName',
    label: 'Apellidos',
    type: 'text',
  },
  {
    validation: {
      required: true,
    },
    name: 'documentType',
    label: 'Tipo de documento',
    type: 'select',
    reference: {
      entity: DOCUMENT_TYPES,
    },
  },
  {
    validation: {
      minLength: 4,
      required: true,
    },
    name: 'documentNumber',
    label: 'Número de documento',
    type: 'text',
  },
  {
    validation: {
      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      required: true,
    },
    name: 'email',
    label: 'Correo electrónico',
    type: 'email',
  },
  {
    validation: {
      validate: (val) => val.length === 10 || val.length === 7,
      required: true,
    },
    name: 'phone',
    label: 'Número Telefónico',
    type: 'numeric',
  },
  {
    type: 'divider',
  },
  {
    type: 'section',
    label: 'Datos Solicitud',
  },
  {
    validation: {
      required: true,
    },
    name: 'specialty',
    label: 'Especialidad',
    type: 'reference',
    reference: {
      entity: 'specialties',
      where: [
        {
          field: 'active', operator: '==', value: true,
        },
      ],
    },
  },
  {
    validation: {
      required: true,
    },
    name: 'serviceType',
    label: 'Tipo de servicio',
    type: 'select',
    reference: {
      entity: SERVICE_TYPE,
    },
  },
  {
    validation: {
      required: true,
    },
    name: 'eps',
    label: 'EPS',
    type: 'reference',
    reference: {
      entity: 'epss',
      where: [
        {
          field: 'active', operator: '==', value: true,
        },
      ],
      // validate: (val, watch) => !(watch('serviceType') === 'eps' && val === ''),
    },
    isInvisible: (watch) => watch('serviceType') !== 'eps',
    errorMessage: 'Débe indicar una EPS debido al tipo de servicio que indicó',
  },
  {
    type: 'empty',
  },
  {
    validation: {},
    name: 'observations',
    label: 'Observaciones adicionales',
    type: 'textarea',
  },
  {
    type: 'divider',
  },
  {
    type: 'section',
    label: 'Datos Cita',
  },
  {
    validation: {
      required: true,
    },
    name: 'days',
    label: 'Días preferidos',
    helper: 'Indique que días serían más adecuados para la asignación de la cita',
    type: 'multiboolean',
    reference: {
      entity: DAYS,
    },
    layout: {
      gridColumn: '1 / -1',
    },
  },
  {
    validation: {
      required: true,
    },
    name: 'dayPart',
    label: 'Horario preferido',
    helper: 'Indique que horarios serían más adecuados para la asignación de la cita',
    type: 'multiboolean',
    reference: {
      entity: DAY_PART,
    },
    layout: {
      gridColumn: '1 / -1',
    },
  },
  {
    validation: {},
    name: 'status',
    label: 'Status',
    type: 'hidden',
  },
  {
    validation: {},
    name: 'notified',
    label: 'Notified',
    type: 'hidden',
  },
];

export const RequestDefaultProps = {
  firstName: '',
  lastName: '',
  status: 'pending',
  notified: false,
  documentType: '',
  documentNumber: '',
  email: '',
  phone: '',
  specialty: '',
  serviceType: '',
  eps: null,
  observations: '',
  days: getDefaults(DAYS),
  dayPart: getDefaults(DAY_PART),
  ...GenericDefaultProps,
};

export default RequestPropTypes;
