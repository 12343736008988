import React from 'react';
import * as PropTypes from 'prop-types';
import { RiEmotionHappyLine, RiEmotionUnhappyLine } from 'react-icons/ri';
import { Button, useDisclosure } from '@chakra-ui/core';
import Confirm from '../Confirm';

const ActivateButton = ({ action, active, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const text = active ? 'Desactivar' : 'Activar';
  return (
    <>
      <Button
        variantColor={active ? 'orange' : 'gray'}
        size="xs"
        onClick={onOpen}
        leftIcon={active ? RiEmotionUnhappyLine : RiEmotionHappyLine}
        {...props}
      >
        {text}
      </Button>
      <Confirm
        isOpen={isOpen}
        message={`¿Está seguro de que desea ${text.toLowerCase()} el elemento indicado?`}
        title={`Confirmar ${text}`}
        onAccept={action}
        onClose={onClose}
      />
    </>
  );
};

ActivateButton.propTypes = {
  action: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  ...Button.propTypes,
  children: PropTypes.node,
};

ActivateButton.defaultProps = {
  children: null,
};

export default ActivateButton;
