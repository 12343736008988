import * as PropTypes from 'prop-types';
import GenericPropType, { GenericDefaultProps } from '../../util/genericPropType';
import * as ROLES from '../../constants/roles';

const getRoles = (types = true) => {
  const prop = {};
  prop[ROLES.ADMIN] = types ? PropTypes.bool : false;
  // prop[ROLES.DOCTOR] = types ? PropTypes.bool : false;
  prop[ROLES.EDITOR] = types ? PropTypes.bool : false;
  return prop;
};

export const getRolesEntity = () => ([
  {
    value: ROLES.ADMIN,
    label: 'Administrador',
  },
  // {
  //   label: ROLES.DOCTOR,
  //   value: 'Médico',
  // },
  {
    value: ROLES.EDITOR,
    label: 'Editor',
  },
]);

const UserPropTypes = PropTypes.shape({
  name: PropTypes.string,
  active: PropTypes.bool,
  email: PropTypes.string,
  roles: PropTypes.shape(getRoles()),
  ...GenericPropType,
});

export const UserDefaultProps = {
  name: '',
  active: true,
  email: '',
  roles: getRoles(false),
  ...GenericDefaultProps,
};

export default UserPropTypes;
