import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AppointmentsList from './list';
import * as ROUTES from '../../constants/routes';
import AppointmentNew from './new';
import AppointmentDetails from './details';
import AppointmentEdit from './edit';
import { withAuthorization } from '../../components/Session';
import { isEditor } from '../../util/functions';

const Appointments = () => (
  <Switch>
    <Route component={AppointmentNew} path={`${ROUTES.APPOINTMENTS}/new`} />
    <Route component={AppointmentEdit} path={`${ROUTES.APPOINTMENTS}/edit/:id`} />
    <Route component={AppointmentDetails} path={`${ROUTES.APPOINTMENTS}/details/:id`} />
    <Route component={AppointmentsList} path={ROUTES.APPOINTMENTS} />
  </Switch>
);

export default withAuthorization(isEditor)(Appointments);
