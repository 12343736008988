import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Box } from '@chakra-ui/core';
import { useAlert } from '../../components/Notification/hAlert';
import { FirebasePropType, withFirebase } from '../../components/Firebase';
import { useRedirectToAfterSleep } from '../../util/functions';
import * as ROUTES from '../../constants/routes';
import BackButton from '../../components/Buttons/back';
import ModuleContainer from '../../layout/ModuleContainer';
import AppointmentForm from './form';
import { STATUS_OPTIONS } from '../../util/genericPropType';
import { AppointmentModel } from './AppointmentPropType';

const AppointmentEdit = ({ firebase }) => {
  const {
    setMessage, alertComponent, message,
  } = useAlert(false, true);
  const [entity, setEntity] = React.useState(null);
  const match = useRouteMatch();
  const [model, setModel] = React.useState(null);
  React.useEffect(() => {
    const newModel = [
      ...AppointmentModel,
    ];
    newModel.pop();
    const status = [
      ...STATUS_OPTIONS,
    ];
    status.shift();
    newModel.push({
      validation: {
        required: true,
      },
      name: 'status',
      label: 'Estado de la cita',
      type: 'select',
      reference: {
        entity: status,
      },
    });
    setModel(newModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (match) {
      if (match.params.id) {
        firebase.appointment(match.params.id)
          .get()
          .then((doc) => {
            if (doc.exists) {
              const appointment = {
                uid: doc.id,
                ...doc.data(),
              };
              appointment.datetime = appointment.datetime.toDate();
              setEntity(appointment);
              setMessage({
                loading: false,
                message: null,
                type: null,
              });
            } else {
              setMessage({
                loading: false,
                type: 'error',
                message: 'Ups, no encontramos la cita que buscas en la base de datos.',
              });
            }
          }).catch(() => {
            setMessage({
              loading: false,
              type: 'error',
              message: 'Ups, algo no salió bien intentando cargar la cita. Intentalo de nuevo.',
            });
          });
      } else {
        setMessage({
          loading: false,
          type: 'warning',
          message: 'Ups, nos perdimos encontrando la página que estás buscando.',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  const redirect = useRedirectToAfterSleep(setMessage, 'la cita', ROUTES.APPOINTMENTS);

  return (
    <ModuleContainer
      title={!entity
        ? 'Cargando cita...'
        : `Editar cita de: ${entity.firstName} ${entity.lastName}`}
      headerLeft={(
        <BackButton to={ROUTES.APPOINTMENTS} />
      )}
    >
      {message ? alertComponent : (
        <Box maxWidth="950px">
          <AppointmentForm
            firebase={firebase}
            appointment={entity}
            postSubmit={redirect}
            model={model}
          />
        </Box>
      )}
    </ModuleContainer>
  );
};

AppointmentEdit.propTypes = {
  firebase: FirebasePropType.isRequired,
};

export default withFirebase(AppointmentEdit);
