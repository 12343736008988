import React from 'react';
import * as PropTypes from 'prop-types';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import setHours from 'date-fns/setHours'; // eslint-disable-line import/no-extraneous-dependencies
import setMinutes from 'date-fns/setMinutes'; // eslint-disable-line import/no-extraneous-dependencies
import es from 'date-fns/locale/es'; // eslint-disable-line import/no-extraneous-dependencies
import '../../styles/datetimepicker.css';

registerLocale('es', es); // register it with the name you want

const DateTimeBase = ({
  minHours,
  minMinutes,
  maxHours,
  maxMinutes,
  timeIntervals,
  dateFormat,
  timeFormat,
  hideTime,
  ...props
}) => (
  <ReactDatePicker
    showTimeSelect={!hideTime}
    timeFormat={timeFormat}
    timeIntervals={timeIntervals}
    timeCaption="Hora"
    minTime={setHours(setMinutes(new Date(), minMinutes), minHours)}
    maxTime={setHours(setMinutes(new Date(), maxMinutes), maxHours)}
    locale="es"
    dateFormat={dateFormat}
    {...props}
  />
);

DateTimeBase.defaultProps = {
  minHours: 6,
  minMinutes: 0,
  maxHours: 19,
  maxMinutes: 0,
  timeIntervals: 5,
  dateFormat: 'MMMM d, yyyy h:mm aa',
  timeFormat: 'HH:mm',
  hideTime: false,
};

DateTimeBase.propTypes = {
  minHours: PropTypes.number,
  minMinutes: PropTypes.number,
  maxHours: PropTypes.number,
  maxMinutes: PropTypes.number,
  timeIntervals: PropTypes.number,
  dateFormat: PropTypes.string,
  timeFormat: PropTypes.string,
  hideTime: PropTypes.bool,
};

export default DateTimeBase;
