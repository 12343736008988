import React from 'react';
import { compose } from 'recompose';
import { Heading, SimpleGrid, Skeleton } from '@chakra-ui/core';
import AddButton from '../../components/Buttons/add';
import ModuleContainer from '../../layout/ModuleContainer';
import * as ROUTES from '../../constants/routes';
import { FirebasePropType, withFirebase } from '../../components/Firebase';
import { useAlert } from '../../components/Notification/hAlert';
import DoctorItem from './item';
import { useReferenceAfterLoading, useReference, isAdmin } from '../../util/functions';
import { useActiveFilter, useReferenceFilter } from '../../components/Filters';
import { AuthUserPropType, withCurrentUser } from '../../components/Session';

const DoctorsList = ({ firebase, authUser }) => {
  const [data, setData] = React.useState({
    loading: true,
    list: [],
  });
  const { alertComponent, setMessage, loading } = useAlert(true, true);
  const specialties = useReference(
    firebase, setMessage, 'specialties', 'specialty', 'las especialidades', 'Sin especialidad',
  );
  const { filter, component } = useActiveFilter(true, false);
  const {
    valueFilter: specialtyFilter,
    component: specialtyFilterComponent,
  } = useReferenceFilter(specialties.list, 'specialty', 'Filtrar por especialidad');
  React.useEffect(() => {
    setData((prev) => ({
      ...prev,
      loading: true,
    }));
    let reference = firebase.doctors();
    if (typeof filter === 'boolean') {
      reference = reference.where('active', '==', filter);
    }
    if (specialtyFilter !== '') {
      reference = reference.where('specialty', '==', specialtyFilter);
    }
    const unsubscribe = reference.onSnapshot((snapshot) => {
      const list = [];
      if (!snapshot.empty) {
        snapshot.forEach((doc) => {
          list.push({
            uid: doc.id,
            ...doc.data(),
          });
        });
      }
      setData({
        loading: false,
        list,
      });
    }, () => {
      setMessage({
        loading: false,
        type: 'error',
        message: 'Ups! Algo pasó y no pudimos cargar los médicos. Recarga la página para intentarlo nuevamente.',
      });
    });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, specialtyFilter]);
  useReferenceAfterLoading(
    data, setData, setMessage, specialties,
  );
  const admin = isAdmin(authUser);
  return (
    <ModuleContainer
      title="Médicos"
      headerRight={(
        <AddButton to={`${ROUTES.DOCTORS}/new`} />
      )}
    >
      <Skeleton height={loading ? '500px' : 'auto'} isLoaded={!loading}>
        {alertComponent}
        {component}
        {specialtyFilterComponent}
        {data.list.length === 0 ? (
          <Heading as="h4" size="lg" textAlign="center" color="gray.400" mt={10}>
            {'No encontramos ningún médico cargado en la base datos... '}
            <span role="img" aria-label="carita pensando">🧐</span>
          </Heading>
        ) : (
          <SimpleGrid
            columns={{
              base: 1, md: 2, xl: 3,
            }}
            spacing={5}
          >
            {data.list.map((item) => (
              <DoctorItem
                key={item.uid}
                item={item}
                setMessage={setMessage}
                isAdmin={admin}
              />
            ))}
          </SimpleGrid>
        )}
      </Skeleton>
    </ModuleContainer>
  );
};

DoctorsList.propTypes = {
  firebase: FirebasePropType.isRequired,
  authUser: AuthUserPropType.isRequired,
};

export default compose(
  withFirebase,
  withCurrentUser,
)(DoctorsList);
