import React from 'react';
import { compose } from 'recompose';
import { Box, Skeleton } from '@chakra-ui/core';
import { withAuthorization } from '../../components/Session';
import { isAdmin } from '../../util/functions';
import { FirebasePropType, withFirebase } from '../../components/Firebase';
import { useAlert } from '../../components/Notification/hAlert';
import ModuleContainer from '../../layout/ModuleContainer';
import * as ROUTES from '../../constants/routes';
import HForm from '../../components/Form/HForm';

export const NotificationsModel = [
  {
    type: 'section',
    label: 'Notificaciones vía correo electrónico',
  },
  {
    validation: {
      minLength: 4,
      required: true,
    },
    name: 'sendgrid',
    label: 'SendGrid API KEY',
    helper: 'Esta App usa SendGrid como plataforma para envío masivo de correos. Requiere obtener una API KEY.',
    type: 'text',
  },
  {
    validation: {
      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      required: true,
    },
    name: 'emailFrom',
    label: 'Dirección de correo electrónico de envío',
    helper: 'Se usará esta dirección de correo electrónico como remitente de los mensajes de notificación.',
    type: 'email',
  },
  {
    validation: {
      pattern: /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/,
      required: true,
    },
    name: 'requestsTo',
    label: 'Notificación de nuevas solicitudes de cita',
    helper: 'Puede indicar una o varias direcciones de correo electrónico separadas por comas.',
    type: 'text',
  },
  {
    validation: {
      pattern: /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/,
      required: true,
    },
    name: 'appointmentsTo',
    label: 'Notificación de nuevas citas asignadas',
    helper: 'Puede indicar una o varias direcciones de correo electrónico separadas por comas.',
    type: 'text',
  },
];

const DEFAULT_DATA = {
  uid: 'main',
  sendgrid: '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',
  emailFrom: 'sistemas@hdsa.gov.co',
  requestsTo: 'gurzaf@gmail.com, julian@soluciones404.com.co',
  appointmentsTo: 'gurzaf@gmail.com, julian@soluciones404.com.co',
};

const NotificationsPage = ({ firebase }) => {
  const [data, setData] = React.useState(null);
  const { setMessage, alertComponent, loading } = useAlert(true, true);
  React.useEffect(() => {
    firebase.notification().get()
      .then((doc) => {
        if (doc.exists) {
          setData({
            uid: doc.id,
            ...doc.data(),
          });
        } else {
          setData(DEFAULT_DATA);
        }
        setMessage({
          loading: false,
          message: null,
          type: null,
        });
      })
      .catch(() => {
        setData(DEFAULT_DATA);
        setMessage({
          type: 'error',
          title: 'Ups! Algo no salió bien',
          message: 'No pudimos cargar la configuración de las notificaciones. Inténtalo de nuevo.',
          loading: false,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const postSubmit = async () => {
    setMessage({
      title: 'Configuración actualizada',
      message: 'Se ha actualizado correctamente la configuración de notificaciones.',
      type: 'success',
      loading: false,
    });
  };

  return (
    <ModuleContainer
      title="Configuración Notificaciones"
    >
      <Skeleton height={loading ? '500px' : 'auto'} isLoaded={!loading}>
        <Box maxWidth="950px">
          {alertComponent}
          {!!data && (
            <HForm
              collection="notifications"
              columns={2}
              postSubmit={postSubmit}
              basePath={ROUTES.NOTIFICATIONS}
              label="la configuración de notificaciones"
              entity={data}
              model={NotificationsModel}
            />
          )}
        </Box>
      </Skeleton>
    </ModuleContainer>
  );
};

NotificationsPage.propTypes = {
  firebase: FirebasePropType.isRequired,
};

export default compose(
  withAuthorization(isAdmin),
  withFirebase,
)(NotificationsPage);
