import React from 'react';
import * as PropTypes from 'prop-types';
import { Box, FormControl, FormLabel } from '@chakra-ui/core';
import DateTimeBase from '../Form/DateTimeBase';

const DateFilter = ({
  onChange, field, startValue, endValue, ...props
}) => {
  const [dates, setDates] = React.useState({
    start: startValue,
    end: endValue,
  });
  const action = (val, start) => {
    const newDates = { ...dates };
    if (start) {
      newDates.start = val;
    } else {
      newDates.end = val;
    }
    setDates(newDates);
    onChange(newDates, field);
  };
  return (
    <>
      <Box
        maxWidth="200px"
        my={4}
        d="inline-block"
        verticalAlign="top"
        mr={4}
        {...props}
      >
        <FormControl>
          <FormLabel htmlFor={field}>
            Desde
          </FormLabel>
          <DateTimeBase
            hideTime
            selected={dates.start}
            dateFormat="MMMM d, yyyy"
            onChange={(res) => action(res, true)}
          />
        </FormControl>
      </Box>
      <Box
        maxWidth="200px"
        my={4}
        d="inline-block"
        verticalAlign="top"
        mr={4}
        {...props}
      >
        <FormControl>
          <FormLabel htmlFor={field}>
            Hasta
          </FormLabel>
          <DateTimeBase
            hideTime
            selected={dates.end}
            dateFormat="MMMM d, yyyy"
            onChange={(sel) => action(sel, false)}
          />
        </FormControl>
      </Box>
    </>
  );
};

DateFilter.propTypes = {
  startValue: PropTypes.instanceOf(Date).isRequired,
  endValue: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func,
  field: PropTypes.string.isRequired,
  ...Box.propTypes,
};

DateFilter.defaultProps = {
  onChange: () => {},
};

export default DateFilter;
