import React from 'react';
import {
  Box, Skeleton,
} from '@chakra-ui/core';
import { useAlert } from '../../components/Notification/hAlert';
import { FirebasePropType, withFirebase } from '../../components/Firebase';
import BackButton from '../../components/Buttons/back';
import * as ROUTES from '../../constants/routes';
import ModuleContainer from '../../layout/ModuleContainer';
import { useLoadRequest, DetailsComponent } from '../../components/RequestsComponents';
import AppointmentForm from '../Appointments/form';
import { RequestTitle } from './header';
import { getAppointmentFromRequest, useRedirectToAfterSleep } from '../../util/functions';

const RequestAssign = ({ firebase }) => {
  const {
    loading, setMessage, alertComponent, type,
  } = useAlert(false, true);

  const { request } = useLoadRequest(firebase, setMessage);

  const [appointment, setAppointment] = React.useState(null);
  const [to, setTo] = React.useState(ROUTES.REQUESTS);
  React.useEffect(() => {
    if (request.item) {
      setAppointment(getAppointmentFromRequest(request.item));
      setTo(`${ROUTES.REQUESTS}/details/${request.item.uid}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request.item]);

  const redirect = useRedirectToAfterSleep(setMessage, 'la cita', to, 4, 'Se asignó correctamente ');

  const postSubmit = async (reference) => {
    try {
      await firebase.request(request.item.uid)
        .set({
          appointment: reference.id,
          status: 'scheduled',
        }, { merge: true });
      await redirect();
    } catch (e) {
      setMessage({
        type: 'error',
        message: 'Algo no salió bien actualizando el estado de la solicitud de cita.',
        title: 'Ups! Algo no salió bien',
      });
    }
  };

  return (
    <ModuleContainer
      title={!request.name ? 'Cargando detalles de solicitud de cita' : `Asignar solicitud de ${request.name}`}
      headerLeft={(
        <BackButton to={ROUTES.REQUESTS} />
      )}
    >
      <Box maxWidth="750px">
        <Skeleton isLoaded={!loading}>
          {type ? alertComponent : (
            <>
              <DetailsComponent request={request} firebase={firebase} />
              <Box my={4} gridColumn="1 / -1" bg="teal.500" p={4}>
                <RequestTitle color="white" mt={0} fontSize="sm" textTransform="uppercase">
                  Formulario de asignación de cita
                </RequestTitle>
              </Box>
              {!request.appointment && (
                <AppointmentForm
                  firebase={firebase}
                  appointment={appointment}
                  postSubmit={postSubmit}
                />
              )}
            </>
          )}
        </Skeleton>
      </Box>
    </ModuleContainer>
  );
};

RequestAssign.propTypes = {
  firebase: FirebasePropType.isRequired,
};

export default withFirebase(RequestAssign);
