import React from 'react';
import * as PropTypes from 'prop-types';
import { Flex, Heading, Image } from '@chakra-ui/core';
import { MAIN_TITLE } from '../../constants/titles';

const Logo = ({
  size, fontSize, vertical, ...props
}) => (
  <Flex
    align="center"
    direction={vertical ? 'column' : 'row'}
    {...props}
  >
    <Image
      size={`${size}px`}
      objectFit="cover"
      src="/favicon.png"
      alt="Citas HDSA"
      mr={vertical ? 0 : 1}
      mb={vertical ? 1 : 0}
    />
    <Heading as="p" size={fontSize} letterSpacing="-.05rem" textTransform="uppercase">
      {MAIN_TITLE}
    </Heading>
  </Flex>
);

Logo.defaultProps = {
  size: 35,
  fontSize: 'lg',
  vertical: false,
};

Logo.propTypes = {
  size: PropTypes.number,
  vertical: PropTypes.bool,
  fontSize: PropTypes.oneOf(['2xl', 'xl', 'lg', 'md', 'sm', 'xs']),
  ...Flex.propTypes,
};

export default Logo;
