import React from 'react';
import { compose } from 'recompose';
import * as PropTypes from 'prop-types';
import {
  Button, SimpleGrid, Skeleton, Stack,
} from '@chakra-ui/core';
import Box from '@chakra-ui/core/dist/Box';
import {
  RiGoogleLine,
  // RiFacebookLine,
  // RiTwitterLine,
  RiLockUnlockLine,
} from 'react-icons/ri';
import {
  AuthUserPropType, withCurrentUser, withEmailVerification,
} from '../../components/Session';
import { FirebasePropType, withFirebase } from '../../components/Firebase';
import PasswordChangeForm from '../../components/PasswordChange';
import ModuleContainer from '../../layout/ModuleContainer';
import SmallTitleLabel from '../../components/Text/SmallTitleLabel';
import SmallTitle from '../../components/Text/SmallTitle';
import { getAuthErrorMessage } from '../../util/functions';
import HAlert from '../../components/Notification/hAlert';

const SIGN_IN_METHODS = [
  {
    id: 'password',
    provider: null,
    icon: RiLockUnlockLine,
    color: 'gray',
  },
  {
    id: 'google',
    provider: 'googleProvider',
    color: 'red',
    icon: RiGoogleLine,
  },
  // {
  //   id: 'facebook',
  //   provider: 'facebookProvider',
  //   color: 'blue',
  //   icon: RiFacebookLine,
  // },
  // {
  //   id: 'twitter',
  //   provider: 'twitterProvider',
  //   color: 'cyan',
  //   icon: RiTwitterLine,
  // },
];

const SocialLoginToggle = ({
  onlyOneLeft, isEnabled, signInMethod, onLink, onUnlink, isLoading,
}) => {
  const [loading, setLoading] = React.useState(false);
  const text = `${isEnabled ? 'Desactivar' : 'Enlazar con'} ${signInMethod.id}`;
  const onClick = async () => {
    setLoading(true);
    if (isEnabled) {
      await onUnlink(signInMethod.id);
    } else {
      await onLink(signInMethod.id);
    }
    setLoading(false);
  };
  return (
    <Button
      leftIcon={signInMethod.icon}
      variantColor={signInMethod.color}
      onClick={onClick}
      isDisabled={(onlyOneLeft && isEnabled) || isLoading}
      isLoading={loading}
      mt={2}
      width="100%"
      maxWidth={250}
    >
      {text}
    </Button>
  );
};

SocialLoginToggle.propTypes = {
  onlyOneLeft: PropTypes.bool.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  signInMethod: PropTypes.shape({
    id: PropTypes.string.isRequired,
    provider: PropTypes.string,
    icon: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired,
  onLink: PropTypes.func.isRequired,
  onUnlink: PropTypes.func.isRequired,
};

const DefaultLoginToggle = ({
  onlyOneLeft, isEnabled, signInMethod, onUnlink, isLoading,
}) => {
  const [loading, setLoading] = React.useState(false);
  return isEnabled ? (
    <Button
      leftIcon={signInMethod.icon}
      variantColor={signInMethod.color}
      isDisabled={(onlyOneLeft && isEnabled) || isLoading}
      isLoading={loading}
      width="100%"
      maxWidth={250}
      mt={2}
      onClick={() => {
        setLoading(true);
        onUnlink(signInMethod.id)
          .finally(() => setLoading(false));
      }}
    >
      Desactivar contraseña
    </Button>
  ) : null;
};

DefaultLoginToggle.propTypes = {
  onlyOneLeft: PropTypes.bool.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  signInMethod: PropTypes.shape({
    id: PropTypes.string.isRequired,
    icon: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
    provider: PropTypes.string,
  }).isRequired,
  onUnlink: PropTypes.func.isRequired,
};

const LoginManagementBase = ({
  firebase, activeMethods, fetchSignInMethods, setMessage, isLoading,
}) => {
  const onSocialLoginLink = async (provider) => {
    try {
      setMessage({ loading: true });
      await firebase.auth.currentUser
        .linkWithPopup(firebase[`${provider}Provider`]);
      await fetchSignInMethods();
      setMessage({
        type: 'success',
        message: `Se enlazó correctamente con ${provider}`,
        loading: false,
      });
    } catch (err) {
      setMessage({
        type: 'error',
        message: getAuthErrorMessage(err),
        loading: false,
      });
    }
  };

  const onUnlink = async (providerId) => {
    try {
      setMessage({ loading: true });
      await firebase.auth.currentUser
        .unlink(`${providerId}`);
      await fetchSignInMethods();
      setMessage({
        type: 'success',
        message: `Se desactivó el inicio de sesión con ${providerId}`,
        loading: false,
      });
    } catch (err) {
      setMessage({
        type: 'error',
        message: getAuthErrorMessage(err),
        loading: false,
      });
    }
  };

  return (
    <>
      <SmallTitleLabel mb={3}>
        Métodos de inicio de sesión
      </SmallTitleLabel>
      <Stack spacing={8}>
        {SIGN_IN_METHODS.map((signInMethod) => {
          const onlyOneLeft = activeMethods.length === 1;
          const isEnabled = activeMethods.join().includes(
            signInMethod.id,
          );

          return signInMethod.id === 'password' ? (
            <DefaultLoginToggle
              key={signInMethod.id}
              onlyOneLeft={onlyOneLeft}
              isEnabled={isEnabled}
              signInMethod={signInMethod}
              onUnlink={onUnlink}
              isLoading={isLoading}
            />
          ) : (
            <SocialLoginToggle
              key={signInMethod.id}
              onlyOneLeft={onlyOneLeft}
              isEnabled={isEnabled}
              signInMethod={signInMethod}
              onLink={onSocialLoginLink}
              onUnlink={onUnlink}
              isLoading={isLoading}
            />
          );
        })}
      </Stack>
    </>
  );
};

LoginManagementBase.defaultProps = {
  isLoading: false,
};

LoginManagementBase.propTypes = {
  firebase: FirebasePropType.isRequired,
  activeMethods: PropTypes.arrayOf(PropTypes.string).isRequired,
  fetchSignInMethods: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

const AccountPage = ({ authUser, firebase }) => {
  const [activeMethods, setActiveMethods] = React.useState([]);
  const [updatePassword, setUpdatePassword] = React.useState(false);
  const [message, setMessage] = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);

  const fetchSignInMethods = async () => {
    try {
      const activeSignInMethods = await firebase.auth
        .fetchSignInMethodsForEmail(authUser.email);
      setUpdatePassword(activeSignInMethods.includes('password'));
      setActiveMethods(activeSignInMethods);
    } catch (err) {
      setMessage({
        type: 'error',
        message: getAuthErrorMessage(err),
      });
    } finally {
      setLoaded(true);
    }
  };

  React.useEffect(() => {
    fetchSignInMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = !!message && message.loading;

  return (
    <ModuleContainer title="Mi Cuenta">
      <SimpleGrid columns={{ base: 1, sm: 2 }} spacing={10}>
        <Box>
          <Skeleton isLoaded={loaded}>
            <SmallTitleLabel>
              Nombre
            </SmallTitleLabel>
            <SmallTitle mb={5}>
              {authUser.name}
            </SmallTitle>
            <SmallTitleLabel>
              Correo electrónico
            </SmallTitleLabel>
            <SmallTitle>
              {authUser.email}
            </SmallTitle>
            <SmallTitleLabel mt={10} mb={-4}>
              {updatePassword ? 'Cambiar contraseña' : 'Definir contraseña'}
            </SmallTitleLabel>
            <PasswordChangeForm
              authUser={authUser}
              fetchSignInMethods={fetchSignInMethods}
              firebase={firebase}
              updatePassword={updatePassword}
              isLoading={isLoading}
            />
          </Skeleton>
        </Box>
        <Box>
          <Skeleton isLoaded={loaded}>
            <LoginManagementBase
              setMessage={setMessage}
              firebase={firebase}
              fetchSignInMethods={fetchSignInMethods}
              activeMethods={activeMethods}
              isLoading={isLoading}
            />
            {!!message && !message.loading && (
              <HAlert
                mt={4}
                status={message.type}
                description={message.message}
                onClose={() => setMessage(null)}
              />
            )}
          </Skeleton>
        </Box>
      </SimpleGrid>
    </ModuleContainer>
  );
};

AccountPage.propTypes = {
  authUser: AuthUserPropType.isRequired,
  firebase: FirebasePropType.isRequired,
};

export default compose(
  withEmailVerification,
  withCurrentUser,
  withFirebase,
)(AccountPage);
