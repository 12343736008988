import * as PropTypes from 'prop-types';
import GenericPropType, {
  GenericDefaultProps, STATUS_OPTIONS, DOCUMENT_TYPES, SERVICE_TYPE, TimeStampPropType,
} from '../../util/genericPropType';

export const APPOINTMENT_TYPE = [
  {
    value: 'virtual',
    label: 'Virtual',
    color: 'cyan',
  },
  {
    value: 'face2face',
    label: 'Presencial',
    color: 'orange',
  },
];

export const DURATIONS = [
  '00:20',
  '00:25',
  '00:30',
  // '00:15',
  // '00:30',
  // '00:45',
  // '01:00',
  // '01:15',
  // '01:35',
  // '01:45',
  // '02:00',
];

const AppointmentPropTypes = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  status: PropTypes.oneOf(STATUS_OPTIONS.map((item) => item.value)),
  documentType: PropTypes.oneOf(DOCUMENT_TYPES.map((item) => item.value)),
  documentNumber: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  specialty: PropTypes.string,
  serviceType: PropTypes.oneOf(SERVICE_TYPE.map((item) => item.value)),
  eps: PropTypes.string,
  observations: PropTypes.string,
  datetime: TimeStampPropType,
  duration: PropTypes.oneOf(DURATIONS),
  doctor: PropTypes.string,
  link: PropTypes.string,
  type: PropTypes.oneOf(APPOINTMENT_TYPE.map((item) => item.value)),
  // days: PropTypes.shape(getDefaults(DAYS, true)),
  // dayPart: PropTypes.shape(getDefaults(DAY_PART, true)),
  ...GenericPropType,
});

export const AppointmentModel = [
  {
    type: 'section',
    label: 'Datos Personales',
  },
  {
    validation: {
      minLength: 4,
      required: true,
    },
    name: 'firstName',
    label: 'Nombres',
    type: 'text',
  },
  {
    validation: {
      minLength: 4,
      required: true,
    },
    name: 'lastName',
    label: 'Apellidos',
    type: 'text',
  },
  {
    validation: {
      required: true,
    },
    name: 'documentType',
    label: 'Tipo de documento',
    type: 'select',
    reference: {
      entity: DOCUMENT_TYPES,
    },
  },
  {
    validation: {
      minLength: 4,
      required: true,
    },
    name: 'documentNumber',
    label: 'Número de documento',
    type: 'text',
  },
  {
    validation: {
      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      required: true,
    },
    name: 'email',
    label: 'Correo electrónico',
    type: 'email',
  },
  {
    validation: {
      validate: (val) => val.length === 10 || val.length === 7,
      required: true,
    },
    name: 'phone',
    label: 'Número Telefónico',
    type: 'numeric',
  },
  {
    type: 'divider',
  },
  {
    type: 'section',
    label: 'Datos Solicitud',
  },
  {
    validation: {
      required: true,
    },
    name: 'specialty',
    label: 'Especialidad',
    type: 'reference',
    reference: {
      entity: 'specialties',
      where: [
        {
          field: 'active', operator: '==', value: true,
        },
      ],
    },
  },
  {
    validation: {
      required: true,
    },
    name: 'serviceType',
    label: 'Tipo de servicio',
    type: 'select',
    reference: {
      entity: SERVICE_TYPE,
    },
  },
  {
    validation: {
      required: true,
    },
    name: 'eps',
    label: 'EPS',
    type: 'reference',
    reference: {
      entity: 'epss',
      where: [
        {
          field: 'active', operator: '==', value: true,
        },
      ],
      // validate: (val, watch) => !(watch('serviceType') === 'eps' && val === ''),
    },
    isInvisible: (watch, entity) => watch('serviceType', entity.serviceType) !== 'eps',
    errorMessage: 'Débe indicar una EPS debido al tipo de servicio que indicó',
  },
  {
    type: 'empty',
  },
  {
    validation: {},
    name: 'observations',
    label: 'Observaciones adicionales',
    type: 'textarea',
  },
  {
    type: 'divider',
  },
  {
    type: 'section',
    label: 'Datos Cita',
  },
  {
    validation: {
      // validate: (val) => val.length === 10 || val.length === 7,
      required: true,
    },
    name: 'datetime',
    label: 'Fecha y Hora',
    type: 'datetime',
  },
  {
    validation: {
      required: true,
    },
    name: 'duration',
    label: 'Duración de la cita',
    type: 'select',
    reference: {
      entity: DURATIONS.map((item) => ({ label: item, value: item })),
    },
  },
  {
    validation: {
      required: true,
    },
    name: 'doctor',
    label: 'Médico',
    type: 'reference',
    reference: {
      entity: 'doctors',
      where: [
        {
          field: 'specialty', operator: '==', value: '',
        },
        {
          field: 'active', operator: '==', value: true,
        },
      ],
      watchFields: ['specialty'],
    },
  },
  {
    validation: {
      required: true,
    },
    name: 'type',
    label: 'Tipo de cita',
    type: 'select',
    reference: {
      entity: APPOINTMENT_TYPE,
    },
  },
  {
    validation: {},
    name: 'request',
    label: 'Solicitud',
    type: 'hidden',
  },
  {
    validation: {},
    name: 'notify',
    label: 'Notificar',
    type: 'boolean',
    value: true,
  },
  {
    validation: {},
    name: 'status',
    label: 'Status',
    type: 'hidden',
  },
];

export const AppointmentDefaultProps = {
  firstName: '',
  lastName: '',
  status: 'scheduled',
  notify: true,
  documentType: '',
  documentNumber: '',
  email: '',
  phone: '',
  specialty: '',
  serviceType: '',
  eps: null,
  observations: '',
  datetime: '',
  duration: '',
  doctor: null,
  link: null,
  type: '',
  request: null,
  ...GenericDefaultProps,
};

export default AppointmentPropTypes;
