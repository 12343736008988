import React from 'react';
import {
  Checkbox, FormControl, FormErrorMessage, FormHelperText, FormLabel,
} from '@chakra-ui/core';
import * as PropTypes from 'prop-types';

const VALIDATE_MESSAGES = {
  required: 'Este campo es requerido.',
  validate: 'El valor indicado no es válido',
};

const getValidation = (validation, watch, originalValues, name) => ({
  validate: (val) => {
    let requiredValidation = true;
    if (validation && validation.required) {
      requiredValidation = originalValues.some((item) => watch(`${name}.${item.value}`));
    }
    if (!requiredValidation) {
      return requiredValidation;
    }
    if (validation.validate) {
      return validation.validate(val, watch);
    }
    return requiredValidation;
  },
});

const getValidationMessage = (errors, originalValues, errorMessage) => {
  if (errors && originalValues.length === Object.keys(errors).length) {
    return (
      <FormErrorMessage>
        {errorMessage || VALIDATE_MESSAGES.required}
      </FormErrorMessage>
    );
  }
  return null;
};

const MultipleCheck = ({
  errors, label, name, register, validation, reference,
  errorMessage, value, isDisabled, helper, watch, ...props
}) => {
  const errMsg = getValidationMessage(errors[name], reference.entity, errorMessage);
  const newValidation = getValidation(validation, watch, reference.entity, name);
  return (
    <FormControl isInvalid={errors[name]} isDisabled={isDisabled} {...props}>
      <FormLabel htmlFor={name} d="block">
        {label}
      </FormLabel>
      {!!helper && (
        <FormHelperText id={`${name}-helper-text`} mt={-0.5} mb={2}>
          {helper}
        </FormHelperText>
      )}
      {reference.entity.map((item) => (
        <Checkbox
          variantColor="teal"
          mr={{ base: 0, sm: 3 }}
          mb={2}
          minWidth={{ base: '50%', sm: 'unset' }}
          isDisabled={isDisabled}
          key={`${name}.${item.value}`}
          defaultIsChecked={value[item.value]}
          name={`${name}.${item.value}`}
          ref={register(newValidation)}
        >
          {item.label}
        </Checkbox>
      ))}
      {errMsg}
    </FormControl>
  );
};

MultipleCheck.defaultProps = {
  register: () => {},
  validation: {},
  errors: {},
  errorMessage: null,
  value: null,
  helper: null,
};

MultipleCheck.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  helper: PropTypes.string,
  register: PropTypes.func,
  validation: PropTypes.shape({
    required: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    pattern: PropTypes.instanceOf(RegExp),
    validate: PropTypes.func,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object,
  errorMessage: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.object,
  reference: PropTypes.shape({
    entity: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  watch: PropTypes.func.isRequired,
  ...FormControl.propTypes,
};

export default MultipleCheck;
