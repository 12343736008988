import React from 'react';
import { Box } from '@chakra-ui/core';
import ModuleContainer from '../../layout/ModuleContainer';
import BackButton from '../../components/Buttons/back';
import * as ROUTES from '../../constants/routes';
import HForm from '../../components/Form/HForm';
import { EPSdefaultProps } from './EPSpropTypes';

const EPSNew = () => (
  <ModuleContainer
    title="Nueva EPS"
    headerLeft={(
      <BackButton to={ROUTES.EPS} />
      )}
  >
    <Box maxWidth="400px">
      <HForm
        basePath={ROUTES.EPS}
        collection="epss"
        label="la EPS"
        entity={EPSdefaultProps}
        model={[
          {
            validation: {
              minLength: 4,
              required: true,
            },
            name: 'name',
            label: 'Nombre',
            type: 'text',
          },
          {
            name: 'active',
            label: 'Activa',
            type: 'boolean',
          },
        ]}
      />
    </Box>
  </ModuleContainer>
);

export default EPSNew;
