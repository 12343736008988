import React from 'react';
import {
  Box, Skeleton,
} from '@chakra-ui/core';
import { useAlert } from '../../components/Notification/hAlert';
import { FirebasePropType, withFirebase } from '../../components/Firebase';
import { useRedirectToAfterSleep } from '../../util/functions';
import BackButton from '../../components/Buttons/back';
import * as ROUTES from '../../constants/routes';
import ModuleContainer from '../../layout/ModuleContainer';
import { useLoadAppointment, DetailsComponent } from '../../components/AppointmentsComponents';

const AppointmentDetails = ({ firebase }) => {
  const {
    loading, setMessage, alertComponent, type,
  } = useAlert(false, true);

  const { appointment } = useLoadAppointment(firebase, setMessage);

  const deleted = useRedirectToAfterSleep(
    setMessage, '', ROUTES.APPOINTMENTS, 4, 'Se borró correctamente la cita',
  );

  const cancelled = useRedirectToAfterSleep(
    setMessage, '', ROUTES.APPOINTMENTS, 4, 'Se canceló correctamente la cita',
  );

  return (
    <ModuleContainer
      title={!appointment.name ? 'Cargando detalles de cita' : `Detalles cita de ${appointment.name}`}
      headerLeft={(
        <BackButton to={ROUTES.APPOINTMENTS} />
      )}
    >
      <Box maxWidth="750px">
        <Skeleton isLoaded={!loading}>
          {type ? alertComponent : (
            <DetailsComponent
              showActions
              firebase={firebase}
              appointment={appointment}
              deleted={deleted}
              cancelled={cancelled}
            />
          )}
        </Skeleton>
      </Box>
    </ModuleContainer>
  );
};

AppointmentDetails.propTypes = {
  firebase: FirebasePropType.isRequired,
};

export default withFirebase(AppointmentDetails);
