import React from 'react';
import * as PropTypes from 'prop-types';
import { Button } from '@chakra-ui/core';
import { useForm } from 'react-hook-form';
import { RiLockPasswordLine } from 'react-icons/ri';
import { FirebasePropType, withFirebase } from '../Firebase';
import { getAuthErrorMessage } from '../../util/functions';
import InputBase from '../Form/Input';
import HAlert from '../Notification/hAlert';
import { AuthUserPropType } from '../Session';

const PasswordChangeForm = ({
  firebase, authUser, fetchSignInMethods, updatePassword, isLoading,
}) => {
  const {
    handleSubmit, errors, register, formState, watch,
  } = useForm();
  const [message, setMessage] = React.useState(null);
  const loginLink = async (password) => {
    const credential = firebase.emailAuthProvider.credential(
      authUser.email,
      password,
    );
    try {
      await firebase.auth.currentUser.linkWithCredential(credential);
      await fetchSignInMethods();
      setMessage({
        type: 'success',
        message: 'Se agregó correctamente una contraseña.',
      });
    } catch (err) {
      setMessage({
        type: 'error',
        message: getAuthErrorMessage(err),
      });
    }
  };

  const onSubmit = async ({ password }) => {
    if (updatePassword) {
      try {
        await firebase.doPasswordUpdate(password);
        setMessage({
          type: 'success',
          message: 'Se cambió correctamente la contraseña.',
        });
      } catch (e) {
        setMessage({
          type: 'error',
          message: getAuthErrorMessage(e),
        });
      }
      return;
    }
    await loginLink(password);
  };

  const loading = formState.isSubmitting || isLoading;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputBase
        mt={4}
        label="Contraseña"
        name="password"
        errors={errors}
        register={register}
        type="password"
        isDisabled={loading}
        validation={{
          required: true,
          min: 5,
        }}
      />
      <InputBase
        mt={4}
        label="Contraseña"
        name="passwordTwo"
        errors={errors}
        register={register}
        type="password"
        isDisabled={loading}
        validation={{
          validate: (val) => val === watch('password'),
        }}
        errorMessage="Las contraseñas no coinciden."
      />
      <Button
        mt={4}
        variantColor="teal"
        leftIcon={RiLockPasswordLine}
        isLoading={formState.isSubmitting}
        isDisabled={loading}
        type="submit"
      >
        {updatePassword ? 'Cambiar contraseña' : 'Definir contraseña'}
      </Button>
      {!!message && (
        <HAlert
          mt={4}
          status={message.type}
          description={message.message}
          onClose={formState.isSubmitting ? null : () => setMessage(null)}
        />
      )}
    </form>
  );
};

PasswordChangeForm.defaultProps = {
  isLoading: false,
};

PasswordChangeForm.propTypes = {
  firebase: FirebasePropType.isRequired,
  authUser: AuthUserPropType.isRequired,
  fetchSignInMethods: PropTypes.func.isRequired,
  updatePassword: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
};

export default withFirebase(PasswordChangeForm);
