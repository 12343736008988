import React from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Box, IconButton, Tooltip, useDisclosure,
} from '@chakra-ui/core';
import {
  RiEyeLine,
  RiEditLine,
  RiCalendarCheckLine,
  RiCloseLine,
  RiDeleteBinLine,
} from 'react-icons/ri';
import Confirm from '../../components/Confirm';
import { withCurrentUser } from '../../components/Session';
import { isAdmin } from '../../util/functions';
import * as ROUTES from '../../constants/routes';
import RequestPropTypes from './RequestPropType';
import { FirebasePropType } from '../../components/Firebase';

const RequestButton = ({
  desc, action, icon, variantColor, state, size, ...props
}) => {
  const attrs = {
    ...props,
  };
  if (typeof action === 'string') {
    attrs.as = Link;
    attrs.to = { pathname: action };
    if (state) {
      attrs.to.state = state;
    }
  } else {
    attrs.onClick = action;
  }
  return (
    <Tooltip
      hasArrow
      label={desc}
      aria-label={desc}
      placement="top"
    >
      <IconButton
        variantColor={variantColor}
        icon={icon}
        size={size}
        aria-label={desc}
        borderRadius={0}
        {...attrs}
      />
    </Tooltip>
  );
};

RequestButton.defaultProps = {
  variantColor: 'gray',
  'aria-label': null,
  state: null,
  size: 'xs',
};

RequestButton.propTypes = {
  ...IconButton.propTypes,
  desc: PropTypes.string.isRequired,
  action: PropTypes.oneOfType([
    PropTypes.string, PropTypes.func,
  ]).isRequired,
  variantColor: PropTypes.string,
  'aria-label': PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
};

const ViewButton = ({ action, desc, ...props }) => (
  <RequestButton
    {...props}
    desc={desc}
    action={action}
    icon={RiEyeLine}
    variantColor="cyan"
  />
);
ViewButton.propTypes = {
  ...RequestButton.propTypes,
  desc: PropTypes.string,
};
ViewButton.defaultProps = {
  desc: 'Ver Solicitud',
};

const EditButton = ({ action, desc, ...props }) => (
  <RequestButton
    {...props}
    desc={desc}
    action={action}
    icon={RiEditLine}
    variantColor="blue"
  />
);
EditButton.propTypes = {
  ...RequestButton.propTypes,
  desc: PropTypes.string,
};
EditButton.defaultProps = {
  desc: 'Editar Solicitud',
};

const AssignButton = ({ action, desc, ...props }) => (
  <RequestButton
    {...props}
    desc={desc}
    action={action}
    icon={RiCalendarCheckLine}
    variantColor="green"
  />
);
AssignButton.propTypes = {
  ...RequestButton.propTypes,
  desc: PropTypes.string,
};
AssignButton.defaultProps = {
  desc: 'Asignar Cita',
};

const CancelButton = ({
  action, desc, message, ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <RequestButton
        {...props}
        desc={desc}
        action={onOpen}
        icon={RiCloseLine}
        variantColor="orange"
      />
      <Confirm
        isOpen={isOpen}
        message={message}
        title="Confirmar Cancelación"
        onAccept={action}
        onClose={onClose}
      />
    </>
  );
};
CancelButton.propTypes = {
  ...RequestButton.propTypes,
  desc: PropTypes.string,
  message: PropTypes.string,
};
CancelButton.defaultProps = {
  desc: 'Cancelar Solicitud',
  message: '¿Está seguro de que desea cancelar la solicitud indicada?',
};

const DeleteButton = withCurrentUser((
  {
    action, desc, message, authUser, ...props
  },
) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return isAdmin(authUser) ? (
    <>
      <RequestButton
        {...props}
        desc={desc}
        action={onOpen}
        icon={RiDeleteBinLine}
        variantColor="red"
      />
      <Confirm
        isOpen={isOpen}
        message={message}
        title="Confirmar Borrado"
        onAccept={action}
        onClose={onClose}
      />
    </>
  ) : null;
});

DeleteButton.propTypes = {
  ...RequestButton.propTypes,
  desc: PropTypes.string,
  message: PropTypes.string,
};
DeleteButton.defaultProps = {
  desc: 'Borrar Solicitud',
  message: '¿Está seguro de que desea borrar la solicitud indicada?',
};

const ButtonBox = ({
  request, firebase, setMessage, size, hideView, ...props
}) => {
  const cancelAction = async (deleteIt = false) => {
    try {
      if (deleteIt) {
        await firebase.request(request.uid).delete();
      } else {
        await firebase.request(request.uid).set({
          status: 'cancelled',
        }, { merge: true });
      }
      if (typeof setMessage === 'function') {
        setMessage({
          type: 'success',
          message: `Se ${deleteIt ? 'borró' : 'canceló'} correctamente la solicitud de cita.`,
        });
      } else {
        if (deleteIt) {
          setMessage.deleted();
          return;
        }
        setMessage.cancelled();
      }
    } catch (e) {
      setMessage({
        type: 'error',
        message: `Ups! Algo no salió bien ${deleteIt ? 'borrando' : 'cancelando'} la solicitud de cita. Intentalo de nuevo.`,
      });
    }
  };
  return (
    <Box mt={1} {...props}>
      {!hideView && (
        <ViewButton
          size={size}
          action={`${ROUTES.REQUESTS}/details/${request.uid}`}
          state={{ item: request }}
        />
      )}
      {request.status === 'pending' && (
        <>
          <CancelButton size={size} action={cancelAction} />
          <AssignButton size={size} action={`${ROUTES.REQUESTS}/assign/${request.uid}`} />
        </>
      )}
      {(request.status === 'pending' || request.status === 'cancelled') && (
        <>
          <EditButton size={size} action={`${ROUTES.REQUESTS}/edit/${request.uid}`} />
          <DeleteButton size={size} action={() => cancelAction(true)} />
        </>
      )}
    </Box>
  );
};

ButtonBox.defaultProps = {
  hideView: false,
};

ButtonBox.propTypes = {
  request: RequestPropTypes.isRequired,
  firebase: FirebasePropType.isRequired,
  setMessage: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  hideView: PropTypes.bool,
  ...Box.propTypes,
};

export {
  CancelButton,
  AssignButton,
  ViewButton,
  EditButton,
  DeleteButton,
};

export default ButtonBox;
