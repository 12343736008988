import React from 'react';
import {
  Badge, Box, Divider, Link, SimpleGrid, Text,
} from '@chakra-ui/core';
import * as PropTypes from 'prop-types';
import { useLocation, useRouteMatch } from 'react-router';
import { RequestTitle } from '../../routes/Requests/header';
import MenuLabel from '../Navigation/MenuLabel';
import ButtonBox from '../../routes/Requests/buttons';
import { FirebasePropType } from '../Firebase';
import { getDateTimeFromFirebase } from '../../util/functions';
import { DAY_PART, DAYS } from '../../routes/Requests/RequestPropType';
import {
  DOCUMENT_TYPES, SERVICE_TYPE, STATUS_OPTIONS,
} from '../../util/genericPropType';
import ViewButton from '../Buttons/view';
import * as ROUTES from '../../constants/routes';

const Observaciones = ({ value }) => !!value && (
  <Box mb={4} gridColumn="1 / -1" bg="gray.800" p={4} borderRadius={4}>
    <RequestTitle color="gray.500" mt={0} fontSize="xs">
      Observaciones
    </RequestTitle>
    <Text color="white">
      {value}
    </Text>
  </Box>
);

Observaciones.defaultProps = {
  value: null,
};

Observaciones.propTypes = {
  value: PropTypes.string,
};

const Bloque = ({ label, value, type }) => {
  let Component = Text;
  let to;
  if (type !== 'text') {
    Component = Link;
    to = `${type === 'email' ? 'mailto' : 'tel'}:${value}`;
  }
  return (
    <Box mb={4}>
      <Component fontSize="md" fontWeight="bold" href={to} isExternal>
        {value}
      </Component>
      <RequestTitle color="gray.400" mt={0} fontSize="xs">
        {label}
      </RequestTitle>
    </Box>
  );
};

Bloque.defaultProps = {
  value: '',
  type: 'text',
};

Bloque.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.oneOf(['email', 'phone', 'text']),
};

const getSection = (text, withSeparator = false) => (
  <>
    {!!withSeparator && (
      <Divider
        mt={8}
        mb={0}
        gridColumn="1 / -1"
      />
    )}
    <MenuLabel
      text={text}
      gridColumn="1 / -1"
      mb={4}
      mx={0}
      mt={4}
      p={0}
      fontSize="sm"
    />
  </>
);

const DetailsComponent = ({
  firebase, request, cancelled, deleted, showActions,
}) => (
  <SimpleGrid
    columns={{
      base: 1, sm: 2, lg: 3,
    }}
  >
    {getSection('Datos del paciente')}
    <Bloque label="Nombre" value={request.name} />
    <Bloque label="Tipo documento" value={request.documentType} />
    <Bloque label="Número documento" value={request.documentNumber} />
    <Bloque label="Número telefónico" value={request.phone} type="phone" />
    <Bloque label="Correo electrónico" value={request.email} type="email" />
    {getSection('Datos solicitud')}
    <Bloque label="Especialidad" value={request.specialty} />
    <Bloque label="Tipo de servicio" value={request.serviceType} />
    {!!request.eps && (
      <Bloque label="EPS" value={request.eps} />
    )}
    <Bloque label="Fecha Solicitud" value={request.createdAt} />
    {!!request.lastEditAt && (
      <Bloque label="Última modificación" value={request.lastEditAt} />
    )}
    <Box mb={4}>
      <Badge variantColor={request.statusColor} fontSize="md">
        {request.status}
      </Badge>
      <RequestTitle color="gray.400" mt={0} fontSize="xs">
        Estado de la solicitud
      </RequestTitle>
    </Box>
    {!!request.appointment && (
      <Box mb={4}>
        <ViewButton to={`${ROUTES.APPOINTMENTS}/details/${request.appointment}`}>
          Ver Cita
        </ViewButton>
      </Box>
    )}
    <Observaciones value={request.observations} />
    {getSection('Datos Cita')}
    <Bloque label="Días sugeridos" value={request.days} />
    <Bloque label="Horarios sugeridos" value={request.dayPart} />
    {showActions && request.item && (
      <ButtonBox
        setMessage={{
          cancelled,
          deleted,
        }}
        firebase={firebase}
        request={request.item}
        hideView
        size="lg"
      />
    )}
  </SimpleGrid>
);

DetailsComponent.defaultProps = {
  showActions: false,
  cancelled: () => {},
  deleted: () => {},
};

DetailsComponent.propTypes = {
  firebase: FirebasePropType.isRequired,
  showActions: PropTypes.bool,
  cancelled: PropTypes.func,
  deleted: PropTypes.func,
  request: PropTypes.shape({
    name: PropTypes.string,
    documentType: PropTypes.string,
    documentNumber: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    specialty: PropTypes.string,
    eps: PropTypes.string,
    serviceType: PropTypes.string,
    createdAt: PropTypes.string,
    lastEditAt: PropTypes.string,
    statusColor: PropTypes.string,
    status: PropTypes.string,
    observations: PropTypes.string,
    days: PropTypes.string,
    dayPart: PropTypes.string,
    appointment: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    item: PropTypes.object,
  }).isRequired,
};

const fillServiceAndSpecialty = async (oldItem, firebase) => {
  const item = {
    ...oldItem,
  };
  if (item.serviceType === 'eps') {
    if (oldItem.eps) {
      item.epsId = `${oldItem.eps}`;
      const eps = await firebase.eps(item.eps).get();
      if (eps.exists) {
        item.eps = eps.data().name;
      } else {
        item.eps = 'EPS no asignada';
      }
    } else {
      item.eps = 'EPS no asignada';
    }
  }
  item.specialtyId = `${oldItem.specialty}`;
  const specialty = await firebase.specialty(item.specialty).get();
  if (specialty.exists) {
    item.specialty = specialty.data().name;
  } else {
    item.specialty = 'Especialidad no asignada';
  }
  return item;
};

const getRequest = async (firebase, uid, setMessage) => {
  const doc = await firebase.request(uid).get();
  if (doc.exists) {
    const item = {
      uid: doc.id,
      ...doc.data(),
    };
    return fillServiceAndSpecialty(item, firebase);
  }
  setMessage({
    loading: false,
    type: 'error',
    message: 'Ups! Lo sentimos, la solicitud de cita que busca, no existe. Verifíque el listado.',
  });
  return null;
};

const getObjects = () => {
  const STATUS_OBJECT = {};
  STATUS_OPTIONS.forEach((item) => {
    STATUS_OBJECT[item.value] = {
      status: item.label,
      statusColor: item.color,
    };
  });
  const DOCTYPE_OBJECT = {};
  DOCUMENT_TYPES.forEach((item) => {
    DOCTYPE_OBJECT[item.value] = item.label;
  });
  const SERVICETYPE_OBJECT = {};
  SERVICE_TYPE.forEach((item) => {
    SERVICETYPE_OBJECT[item.value] = item.label;
  });
  return {
    STATUS_OBJECT, DOCTYPE_OBJECT, SERVICETYPE_OBJECT,
  };
};

const getDaysOrTimeString = (ARRAY, value) => {
  const res = [];
  ARRAY.forEach((item) => {
    if (value[item.value]) {
      res.push(item.label);
    }
  });
  return res.join(' - ');
};

const setRequestData = (request, setRequest) => {
  const {
    DOCTYPE_OBJECT, SERVICETYPE_OBJECT, STATUS_OBJECT,
  } = getObjects();
  const newRequest = {
    ...request,
    name: `${request.firstName} ${request.lastName}`,
    createdAt: getDateTimeFromFirebase(request.createdAt),
    documentType: DOCTYPE_OBJECT[request.documentType],
    serviceType: SERVICETYPE_OBJECT[request.serviceType],
    lastEditAt: getDateTimeFromFirebase(request.lastEditAt),
    days: getDaysOrTimeString(DAYS, request.days),
    dayPart: getDaysOrTimeString(DAY_PART, request.dayPart),
    ...STATUS_OBJECT[request.status],
    item: request,
  };
  setRequest(newRequest);
};

const useLoadRequest = (firebase, setMessage) => {
  const match = useRouteMatch();
  const location = useLocation();
  const [request, setRequest] = React.useState({});
  React.useEffect(() => {
    if (location.state && location.state.item) {
      setRequestData(location.state.item, setRequest);
      setMessage({
        loading: false,
        message: null,
        type: null,
      });
    } else if (match && match.params.id) {
      getRequest(firebase, match.params.id, setMessage)
        .then((item) => {
          if (item) {
            setRequestData(item, setRequest);
            setMessage({
              loading: false,
              type: null,
              message: null,
            });
          }
        })
        .catch(() => {
          setMessage({
            loading: false,
            message: 'Ups! Algo no salió bien cargando la solicitud de cita que busca. Inténtelo de nuevo.',
            type: 'error',
          });
        });
    } else {
      setMessage({
        loading: false,
        message: 'Ups! No hemos encontrado la solicitud de cita que busca. Verifique en el listado.',
        type: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, match]);
  return {
    setRequest,
    request,
  };
};

export {
  useLoadRequest,
  DetailsComponent,
  getSection,
  Bloque,
  Observaciones,
  getObjects,
  getDaysOrTimeString,
  fillServiceAndSpecialty,
};
