import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Box, FormControl, FormLabel, Input,
} from '@chakra-ui/core';

const TextFilter = ({
  label, onChange, field, defaultValue, placeholder, ...props
}) => {
  const [value, setValue] = React.useState(defaultValue);
  const action = () => onChange(value, field);
  const change = (evt) => setValue(evt.target.value);
  const keyDown = (evt) => (evt.which === 13 || evt.keyCode === 13 ? action() : null);
  return (
    <Box
      maxWidth="300px"
      my={4}
      d="inline-block"
      mr={4}
      verticalAlign="top"
      {...props}
    >
      <FormControl>
        <FormLabel htmlFor={field}>
          {label}
        </FormLabel>
        <Input
          width="100%"
          placeholder={placeholder}
          onChange={change}
          onKeyDown={keyDown}
          onBlur={action}
          defaultValue={value}
        />
      </FormControl>
    </Box>
  );
};

TextFilter.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number, PropTypes.bool,
  ]).isRequired,
  ...Box.propTypes,
};

TextFilter.defaultProps = {
  onChange: () => {},
  placeholder: '',
};

export default TextFilter;
