import React from 'react';
import { Link } from '@chakra-ui/core';
import { Link as RLink } from 'react-router-dom';
import * as PropTypes from 'prop-types';

const MenuItem = ({ route, text, active }) => (
  <Link
    d="block"
    py={0.4}
    my={2}
    px={5}
    color="gray.700"
    bg={active ? 'teal.50' : 'transparent'}
    borderRadius={3}
    as={RLink}
    to={route}
    _hover={{
      pl: 6,
      // color: 'black',
    }}
  >
    {text}
  </Link>
);

MenuItem.defaultProps = {
  active: false,
};

MenuItem.propTypes = {
  route: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default MenuItem;
