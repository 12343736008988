import React from 'react';
import * as PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { MAIN_TITLE } from '../../constants/titles';

const Title = ({ title, children }) => {
  const resultTitle = !title ? MAIN_TITLE : `${title} | ${MAIN_TITLE}`;
  return (
    <DocumentTitle title={resultTitle}>
      <>
        {children}
      </>
    </DocumentTitle>
  );
};

Title.defaultProps = {
  title: null,
};

Title.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Title;
