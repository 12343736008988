import React from 'react';
import * as PropTypes from 'prop-types';
import { RiEditLine } from 'react-icons/ri';
import { Button } from '@chakra-ui/core';
import { Link } from 'react-router-dom';

const EditButton = ({ to, text, ...props }) => (
  <Button
    variantColor="blue"
    size="xs"
    as={Link}
    to={to}
    leftIcon={RiEditLine}
    {...props}
  >
    {text}
  </Button>
);

EditButton.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string,
  ...Button.propTypes,
  children: PropTypes.node,
};

EditButton.defaultProps = {
  children: null,
  text: 'Editar',
};

export default EditButton;
