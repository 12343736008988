import React from 'react';
import * as PropTypes from 'prop-types';
import { Box, SimpleGrid, Text } from '@chakra-ui/core';

const AppointmentTitle = ({ children, ...props }) => (
  <Text
    mt={1}
    fontWeight="bold"
    fontSize="xs"
    color="gray.500"
    textTransform="uppercase"
    {...props}
  >
    {children}
  </Text>
);

AppointmentTitle.propTypes = {
  children: PropTypes.node.isRequired,
  ...Text.propTypes,
};

const AppointmentHeader = () => (
  <Box p={2} d={{ base: 'none', lg: 'block' }}>
    <SimpleGrid columns={7} spacing={3}>
      <AppointmentTitle>Fecha</AppointmentTitle>
      <AppointmentTitle>Duración</AppointmentTitle>
      <AppointmentTitle>Especialidad</AppointmentTitle>
      <AppointmentTitle>Médico</AppointmentTitle>
      <AppointmentTitle>EPS</AppointmentTitle>
      <AppointmentTitle>Paciente</AppointmentTitle>
    </SimpleGrid>
  </Box>
);

export {
  AppointmentTitle,
};

export default AppointmentHeader;
