import React from 'react';
import * as PropTypes from 'prop-types';
import { Box, SimpleGrid, Text } from '@chakra-ui/core';

const RequestTitle = ({ children, ...props }) => (
  <Text
    mt={1}
    fontWeight="bold"
    fontSize="xs"
    color="gray.500"
    textTransform="uppercase"
    {...props}
  >
    {children}
  </Text>
);

RequestTitle.propTypes = {
  children: PropTypes.node.isRequired,
  ...Text.propTypes,
};

const RequestHeader = () => (
  <Box p={2} d={{ base: 'none', lg: 'block' }}>
    <SimpleGrid columns={5} spacing={4}>
      <RequestTitle>Fecha</RequestTitle>
      <RequestTitle>Especialidad</RequestTitle>
      <RequestTitle>EPS</RequestTitle>
      <RequestTitle>Paciente</RequestTitle>
    </SimpleGrid>
  </Box>
);

export {
  RequestTitle,
};

export default RequestHeader;
