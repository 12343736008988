import { isAdmin, isEditor } from '../util/functions';

export const HOME = '/app';
export const LOGIN = '/auth';
export const PASSWORD_FORGET = `${LOGIN}/pw-forget`;
export const SIGN_UP = `${LOGIN}/signup`;
export const ACCOUNT = `${HOME}/account`;
export const USERS = `${HOME}/users`;
export const EPS = `${HOME}/eps`;
export const DOCTORS = `${HOME}/doctors`;
export const SPECIALTIES = `${HOME}/specialties`;
export const APPOINTMENTS = `${HOME}/appointments`;
export const REQUESTS = `${HOME}/requests`;
export const NOTIFICATIONS = `${HOME}/notifications`;
export const SOLICITUD = '/solicitud';

const menu = {
  links: {
    dashboard: {
      label: 'Inicio',
      path: HOME,
    },
    dates: {
      label: 'Programación',
      links: {
        requests: {
          label: 'Solicitudes',
          path: REQUESTS,
        },
        appointments: {
          label: 'Citas Médicas',
          path: APPOINTMENTS,
        },
      },
    },
    masters: {
      label: 'Maestros',
      condition: isEditor,
      links: {
        eps: {
          label: 'EPS',
          path: EPS,
        },
        doctors: {
          label: 'Médicos',
          path: DOCTORS,
        },
        specialties: {
          label: 'Especialidades',
          path: SPECIALTIES,
        },
      },
    },
    settings: {
      label: 'Configuración',
      links: {
        account: {
          label: 'Mi cuenta',
          path: ACCOUNT,
        },
        users: {
          label: 'Usuarios',
          path: USERS,
          condition: isAdmin,
        },
        notifications: {
          label: 'Notificaciones',
          path: NOTIFICATIONS,
          condition: isAdmin,
        },
      },
    },
  },
};

export default menu;
