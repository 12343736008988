import React from 'react';
import * as PropTypes from 'prop-types';
import { Box } from '@chakra-ui/core';
import Title, { TitleComponent } from '../components/Title';

const ModuleContainer = ({
  title, children, headerRight, headerLeft,
}) => (
  <Title title={title}>
    <TitleComponent
      title={title}
      leftContent={headerLeft}
      rightContent={headerRight}
    />
    <Box mb={4}>
      {children}
    </Box>
  </Title>
);

ModuleContainer.defaultProps = {
  headerLeft: null,
  headerRight: null,
};

ModuleContainer.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  headerLeft: PropTypes.node,
  headerRight: PropTypes.node,
};

export default ModuleContainer;
