import React from 'react';
import { Heading, SimpleGrid, Skeleton } from '@chakra-ui/core';
import { FirebasePropType, withFirebase } from '../../components/Firebase';
import * as ROUTES from '../../constants/routes';
import { useAlert } from '../../components/Notification/hAlert';
import AddButton from '../../components/Buttons/add';
import UserItem from './item';
import ModuleContainer from '../../layout/ModuleContainer';

const UsersList = ({ firebase }) => {
  const [data, setData] = React.useState([]);
  const { alertComponent, setMessage, loading } = useAlert(true, true);

  React.useEffect(() => {
    const unsubscribe = firebase.users().onSnapshot((snapshot) => {
      const list = [];
      if (!snapshot.empty) {
        snapshot.forEach((doc) => {
          list.push({
            uid: doc.id,
            ...doc.data(),
          });
        });
      }
      setData(list);
      setMessage({
        loading: false,
      });
    }, () => {
      setMessage({
        loading: false,
        type: 'error',
        message: 'Ups! Algo pasó y no pudimos cargar los usuarios. Recarga la página para intentarlo nuevamente.',
      });
    });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModuleContainer
      title="Usuarios"
      headerRight={(
        <AddButton to={`${ROUTES.USERS}/new`} />
      )}
    >
      <Skeleton height={loading ? '500px' : 'auto'} isLoaded={!loading}>
        {alertComponent}
        {data.length === 0 ? (
          <Heading as="h4" size="lg" textAlign="center" color="gray.400" mt={10}>
            {'No encontramos ningún usuario en la base datos... '}
            <span role="img" aria-label="carita pensando">🧐</span>
          </Heading>
        ) : (
          <SimpleGrid
            columns={{
              base: 1, md: 2, xl: 3,
            }}
            spacing={5}
          >
            {data.map((item) => <UserItem key={item.uid} item={item} setMessage={setMessage} />)}
          </SimpleGrid>
        )}
      </Skeleton>
    </ModuleContainer>
  );
};

UsersList.propTypes = {
  firebase: FirebasePropType.isRequired,
};

export default withFirebase(UsersList);
