import React from 'react';
import { compose } from 'recompose';
import { Box, Skeleton } from '@chakra-ui/core';
import axios from 'axios';
import { useRouteMatch } from 'react-router-dom';
import ModuleContainer from '../../layout/ModuleContainer';
import BackButton from '../../components/Buttons/back';
import * as ROUTES from '../../constants/routes';
import HForm from '../../components/Form/HForm';
import { getRolesEntity } from './UserPropTypes';
import { useAlert } from '../../components/Notification/hAlert';
import { USUARIOS_URL } from '../../constants/apis';
import { withCurrentUser, AuthUserPropType } from '../../components/Session';
import { encrypt } from '../../util/functions';
import { FirebasePropType, withFirebase } from '../../components/Firebase';

const UserEdit = ({ authUser, firebase }) => {
  const {
    setMessage, alertComponent, type, loading,
  } = useAlert(false, true);
  const [entity, setEntity] = React.useState(null);
  const match = useRouteMatch();
  React.useEffect(() => {
    if (match) {
      if (match.params.id) {
        firebase.user(match.params.id)
          .get()
          .then((doc) => {
            if (doc.exists) {
              setEntity({
                uid: doc.id,
                ...doc.data(),
              });
              setMessage({
                loading: false,
                message: null,
                type: null,
              });
            } else {
              setMessage({
                loading: false,
                type: 'error',
                message: 'Ups, no encontramos el usuario que buscas en la base de datos.',
              });
            }
          }).catch(() => {
            setMessage({
              loading: false,
              type: 'error',
              message: 'Ups, algo no salió bien intentando cargar el usuario. Intentalo de nuevo.',
            });
          });
      } else {
        setMessage({
          loading: false,
          type: 'warning',
          message: 'Ups, nos perdimos encontrando la página que estás buscando.',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  const onSubmit = async (meta, values) => {
    setMessage({
      type: null,
      message: null,
      title: null,
    });
    try {
      const password = (typeof values.password === 'string' && values.password.length >= 8)
        ? encrypt(values.password) : null;
      const userId = encrypt(authUser.uid);
      const uid = encrypt(entity.uid);
      const data = {
        ...values,
        password,
        userId,
        uid,
        action: 'edit',
      };
      const result = await axios({
        method: 'PUT',
        url: USUARIOS_URL,
        data,
      });
      const {
        type: nType, message: title, details,
      } = result.data;
      const passwordMessage = password !== null ? `<br/>Contraseña: <strong>${values.password}</strong>` : '';
      const message = nType === 'error'
        ? details
        : `Usuario: <strong>${values.email}</strong>.${passwordMessage}`;
      setMessage({
        type: nType,
        title,
        message: message || '',
      });
    } catch (e) {
      setMessage({
        type: 'error',
        title: e.message,
        message: e.details,
      });
    }
    return true;
  };
  return (
    <ModuleContainer
      title={!entity ? 'Cargando usuario...' : `Editar usuario: ${entity.name}`}
      headerLeft={(
        <BackButton to={ROUTES.USERS} />
      )}
    >
      <Box maxWidth="400px">
        {alertComponent}
        {type !== 'success' && (
          <Skeleton height={loading ? '500px' : 'auto'} isLoaded={!loading}>
            {!entity ? null : (
              <HForm
                collection="users"
                basePath={ROUTES.USERS}
                label="el usuario"
                entity={entity}
                preSubmit={onSubmit}
                model={[
                  {
                    validation: {
                      minLength: 4,
                      required: true,
                    },
                    name: 'name',
                    label: 'Nombre',
                    type: 'text',
                  },
                  {
                    validation: {
                      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      required: true,
                    },
                    name: 'email',
                    label: 'Correo electrónico',
                    type: 'email',
                  },
                  {
                    validation: {
                      minLength: 8,
                    },
                    name: 'password',
                    label: 'Contraseña',
                    type: 'password',
                  },
                  {
                    name: 'roles',
                    label: 'Roles',
                    type: 'multiboolean',
                    reference: {
                      entity: getRolesEntity(),
                    },
                  },
                  {
                    name: 'active',
                    label: 'Activo',
                    type: 'boolean',
                  },
                ]}
              />
            )}
          </Skeleton>
        )}
      </Box>
    </ModuleContainer>
  );
};

UserEdit.propTypes = {
  authUser: AuthUserPropType.isRequired,
  firebase: FirebasePropType.isRequired,
};

export default compose(
  withCurrentUser,
  withFirebase,
)(UserEdit);
