import React from 'react';
import * as PropTypes from 'prop-types';
import { Box, Button } from '@chakra-ui/core';
import { useForm } from 'react-hook-form';
import { FirebasePropType, withFirebase } from '../../components/Firebase';
import * as ROUTES from '../../constants/routes';
import HLink from '../../components/Link/hLink';
import ModuleAuthContainer from '../../layout/ModuleAuthContainer';
import { getAuthErrorMessage } from '../../util/functions';
import InputBase from '../../components/Form/Input';
import HAlert from '../../components/Notification/hAlert';

const PasswordForgetFormBase = ({ firebase }) => {
  const {
    handleSubmit, errors, register, formState,
  } = useForm();
  const [message, setMessage] = React.useState(null);
  const onSubmit = async ({ email }) => {
    try {
      await firebase.doPasswordReset(email);
      setMessage({
        type: 'success',
        message: 'Se ha enviado un correo electrónico con las instrucciones para recuperar su contraseña.',
      });
    } catch (e) {
      setMessage({
        type: 'error',
        message: getAuthErrorMessage(e),
      });
    }
  };

  const disabled = formState.isSubmitting
    || (message && message.type === 'success');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputBase
        mt={4}
        label="Correo electrónico"
        name="email"
        errors={errors}
        register={register}
        type="email"
        isDisabled={disabled}
        validation={{
          required: true,
          pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        }}
      />
      <Button
        mt={4}
        variantColor="teal"
        isLoading={formState.isSubmitting}
        isDisabled={disabled}
        loadingText="Enviando..."
        type="submit"
      >
        Enviar
      </Button>
      {!!message && (
        <HAlert
          mt={4}
          status={message.type}
          description={message.message}
          onClose={disabled ? null : () => setMessage(null)}
        />
      )}
    </form>
  );
};

PasswordForgetFormBase.propTypes = {
  firebase: FirebasePropType.isRequired,
};

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

const PasswordForgetLink = ({ toLogin }) => (
  <Box mt={4}>
    <HLink to={toLogin ? ROUTES.LOGIN : ROUTES.PASSWORD_FORGET} router>
      {toLogin ? 'Volver a iniciar sesión' : 'Olvidé mi contraseña'}
    </HLink>
  </Box>
);

PasswordForgetLink.propTypes = {
  toLogin: PropTypes.bool,
};

PasswordForgetLink.defaultProps = {
  toLogin: false,
};

export { PasswordForgetForm, PasswordForgetLink };

const TITLE = 'Recuperar contraseña';

const PasswordForgetPage = () => (
  <ModuleAuthContainer title={TITLE}>
    <PasswordForgetForm />
    <PasswordForgetLink toLogin />
  </ModuleAuthContainer>
);

export default PasswordForgetPage;
