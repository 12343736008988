import React from 'react';
import { RiMenuLine, RiLogoutBoxRLine } from 'react-icons/ri';
import { IconButton } from '@chakra-ui/core';

const MenuIcon = (props) => (
  <IconButton
    variantColor="teal"
    fontSize="20px"
    icon={RiMenuLine}
    {...props}
  />
);

MenuIcon.propTypes = {
  ...IconButton.propTypes,
};

const LogoutIcon = (props) => (
  <IconButton
    variantColor="teal"
    fontSize="20px"
    icon={RiLogoutBoxRLine}
    {...props}
  />
);

LogoutIcon.propTypes = {
  ...IconButton.propTypes,
};

export {
  LogoutIcon,
  MenuIcon,
};
