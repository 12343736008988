import React from 'react';
import { Box } from '@chakra-ui/core';
import ModuleContainer from '../../layout/ModuleContainer';
import BackButton from '../../components/Buttons/back';
import * as ROUTES from '../../constants/routes';
import HForm from '../../components/Form/HForm';
import {
  RequestDefaultProps, RequestModel,
} from './RequestPropType';

const RequestsNew = () => (
  <ModuleContainer
    title="Nueva solicitud de cita"
    headerLeft={(
      <BackButton to={ROUTES.REQUESTS} />
    )}
  >
    <Box maxWidth="950px">
      <HForm
        collection="requests"
        columns={2}
        basePath={ROUTES.REQUESTS}
        label="la solicitud"
        entity={RequestDefaultProps}
        model={RequestModel}
      />
    </Box>
  </ModuleContainer>
);

export default RequestsNew;
