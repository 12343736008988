import React from 'react';
import { Box } from '@chakra-ui/core';
import axios from 'axios';
import ModuleContainer from '../../layout/ModuleContainer';
import BackButton from '../../components/Buttons/back';
import * as ROUTES from '../../constants/routes';
import HForm from '../../components/Form/HForm';
import { getRolesEntity, UserDefaultProps } from './UserPropTypes';
import { useAlert } from '../../components/Notification/hAlert';
import { USUARIOS_URL } from '../../constants/apis';
import { withCurrentUser, AuthUserPropType } from '../../components/Session';
import { encrypt } from '../../util/functions';

const UserNew = ({ authUser }) => {
  const {
    setMessage, alertComponent, type,
  } = useAlert(false, false);
  const onSubmit = async (meta, values) => {
    setMessage({
      type: null,
      message: null,
      title: null,
    });
    try {
      const password = encrypt(values.password);
      const userId = encrypt(authUser.uid);
      const data = {
        ...values,
        password,
        userId,
      };
      const result = await axios({
        method: 'POST',
        url: USUARIOS_URL,
        data,
      });
      const {
        type: nType, message: title, details,
      } = result.data;
      const message = nType === 'error'
        ? details
        : `Usuario: <strong>${values.email}</strong>.<br/>Contraseña: <strong>${values.password}</strong>`;
      setMessage({
        type: nType,
        title,
        message,
      });
    } catch (e) {
      setMessage({
        type: 'error',
        message: e.message,
      });
    }
    return true;
  };
  return (
    <ModuleContainer
      title="Nuevo Usuario"
      headerLeft={(
        <BackButton to={ROUTES.USERS} />
      )}
    >
      <Box maxWidth="400px">
        {alertComponent}
        {type !== 'success' && (
          <HForm
            collection="users"
            basePath={ROUTES.USERS}
            label="el usuario"
            entity={UserDefaultProps}
            preSubmit={onSubmit}
            model={[
              {
                validation: {
                  minLength: 4,
                  required: true,
                },
                name: 'name',
                label: 'Nombre',
                type: 'text',
              },
              {
                validation: {
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  required: true,
                },
                name: 'email',
                label: 'Correo electrónico',
                type: 'email',
              },
              {
                validation: {
                  minLength: 8,
                  required: true,
                },
                name: 'password',
                label: 'Contraseña',
                type: 'password',
              },
              {
                name: 'roles',
                label: 'Roles',
                type: 'multiboolean',
                reference: {
                  entity: getRolesEntity(),
                },
              },
              {
                name: 'active',
                label: 'Activo',
                type: 'boolean',
              },
            ]}
          />
        )}
      </Box>
    </ModuleContainer>
  );
};

UserNew.propTypes = {
  authUser: AuthUserPropType.isRequired,
};

export default withCurrentUser(UserNew);
