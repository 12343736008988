import React from 'react';
import { Heading, SimpleGrid, Skeleton } from '@chakra-ui/core';
import AddButton from '../../components/Buttons/add';
import ModuleContainer from '../../layout/ModuleContainer';
import * as ROUTES from '../../constants/routes';
import { FirebasePropType, withFirebase } from '../../components/Firebase';
import { useAlert } from '../../components/Notification/hAlert';
import EPSItem from './item';
import { useActiveFilter } from '../../components/Filters';

const EpsList = ({ firebase }) => {
  const [data, setData] = React.useState([]);
  const { alertComponent, setMessage, loading } = useAlert(false, true);
  const { component, filter } = useActiveFilter();
  React.useEffect(() => {
    let reference = firebase.epss();
    if (typeof filter === 'boolean') {
      reference = reference.where('active', '==', filter);
    }
    const unsubscribe = reference.onSnapshot((snapshot) => {
      const list = [];
      if (!snapshot.empty) {
        snapshot.forEach((doc) => {
          list.push({
            uid: doc.id,
            ...doc.data(),
          });
        });
      }
      setData(list);
      setMessage({
        loading: false,
        type: null,
        message: null,
      });
    }, () => {
      setMessage({
        loading: false,
        type: 'error',
        message: 'Ups! Algo pasó y no pudimos cargar las EPS. Recarga la página para intentarlo nuevamente.',
      });
    });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);
  return (
    <ModuleContainer
      title="EPSs"
      headerRight={(
        <AddButton to={`${ROUTES.EPS}/new`} />
      )}
    >
      <Skeleton height={loading ? '500px' : 'auto'} isLoaded={!loading}>
        {alertComponent}
        {data.length === 0 ? (
          <Heading as="h4" size="lg" textAlign="center" color="gray.400" mt={10}>
            {'No encontramos ningúna EPS cargada en la base datos... '}
            <span role="img" aria-label="carita pensando">🧐</span>
          </Heading>
        ) : (
          <>
            {component}
            <SimpleGrid
              columns={{
                base: 1, sm: 2, lg: 3, xl: 4,
              }}
              spacing={5}
            >
              {data.map((item) => <EPSItem key={item.uid} eps={item} setMessage={setMessage} />)}
            </SimpleGrid>
          </>
        )}
      </Skeleton>
    </ModuleContainer>
  );
};

EpsList.propTypes = {
  firebase: FirebasePropType.isRequired,
};

export default withFirebase(EpsList);
