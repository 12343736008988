import React from 'react';
import * as PropTypes from 'prop-types';
import {
  AlertDialog, AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent, AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay, Button,
  SlideIn,
} from '@chakra-ui/core';

const Confirm = ({
  isOpen, onClose, onAccept, title, message,
}) => {
  const cancelRef = React.useRef();
  const accept = () => {
    onAccept();
    onClose();
  };
  return (
    <SlideIn in={isOpen}>
      {(styles) => (
        <AlertDialog
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isOpen
        >
          <AlertDialogOverlay opacity={styles.opacity} />
          <AlertDialogContent {...styles}>
            <AlertDialogHeader>
              {title}
            </AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              {message}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button variantColor="gray" ref={cancelRef} onClick={onClose}>
                No
              </Button>
              <Button variantColor="green" ml={3} onClick={accept}>
                Si
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </SlideIn>
  );
};

Confirm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
};

Confirm.defaultProps = {
  title: 'Confirmar Acción',
};

export default Confirm;
