import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RequestsList from './list';
import * as ROUTES from '../../constants/routes';
import RequestsNew from './new';
import RequestEdit from './edit';
import RequestDetails from './details';
import RequestAssign from './assign';
import { withAuthorization } from '../../components/Session';
import { isEditor } from '../../util/functions';

const Requests = () => (
  <Switch>
    <Route component={RequestsNew} path={`${ROUTES.REQUESTS}/new`} />
    <Route component={RequestEdit} path={`${ROUTES.REQUESTS}/edit/:id`} />
    <Route component={RequestDetails} path={`${ROUTES.REQUESTS}/details/:id`} />
    <Route component={RequestAssign} path={`${ROUTES.REQUESTS}/assign/:id`} />
    <Route component={RequestsList} path={ROUTES.REQUESTS} />
  </Switch>
);

export default withAuthorization(isEditor)(Requests);
