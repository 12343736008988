import React from 'react';
import { withRouter } from 'react-router-dom';
import RRDPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';
// import { SignUpLink } from '../SignUp';
import { RiLoginBoxLine } from 'react-icons/ri';
import { useForm } from 'react-hook-form';
import { Button } from '@chakra-ui/core';
import { PasswordForgetLink } from '../PasswordForget';
import { FirebasePropType, withFirebase } from '../../components/Firebase';
import ModuleAuthContainer from '../../layout/ModuleAuthContainer';
import InputBase from '../../components/Form/Input';
import HAlert from '../../components/Notification/hAlert';
import {
  getAuthErrorMessage, loginRedirect,
  showLoader,
} from '../../util/functions';
import { SignInGoogle } from './buttons';

const SignInFormBase = ({ firebase, history, location }) => {
  const {
    handleSubmit, errors, register, formState,
  } = useForm();
  const [socialLogin, setSocialLogin] = React.useState(false);
  const [error, setError] = React.useState(null);
  const onSubmit = async ({ email, password }) => {
    try {
      await firebase.doSignInWithEmailAndPassword(email, password);
      setError(null);
      showLoader();
      loginRedirect(history, location);
    } catch (e) {
      setError(getAuthErrorMessage(e));
    }
  };

  const isDisabled = formState.isSubmitting || socialLogin;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputBase
        mt={4}
        label="Correo electrónico"
        name="email"
        errors={errors}
        register={register}
        type="email"
        isDisabled={isDisabled}
        validation={{
          required: true,
          pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        }}
      />
      <InputBase
        mt={4}
        label="Contraseña"
        name="password"
        errors={errors}
        register={register}
        type="password"
        isDisabled={isDisabled}
        validation={{
          required: true,
          minLength: 5,
        }}
      />
      <Button
        leftIcon={RiLoginBoxLine}
        mt={4}
        variantColor="teal"
        isLoading={isDisabled}
        type="submit"
      >
        Ingresar
      </Button>
      <SignInGoogle loading={isDisabled} setLoading={setSocialLogin} setError={setError} />
      {!!error && (
        <HAlert
          mt={4}
          description={error}
          onClose={() => setError(null)}
        />
      )}
    </form>
  );
};

SignInFormBase.propTypes = {
  firebase: FirebasePropType.isRequired,
  history: RRDPropTypes.history.isRequired,
  location: RRDPropTypes.location.isRequired,
};

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

const TITLE = 'Iniciar Sesión';

const SignInPage = () => (
  <ModuleAuthContainer title={TITLE}>
    <SignInForm />
    {/* <SignInGoogle /> */}
    {/* <SignInFacebook /> */}
    {/* <SignInTwitter /> */}
    <PasswordForgetLink />
    {/* <SignUpLink /> */}
  </ModuleAuthContainer>
);

export {
  SignInForm,
};

export default SignInPage;
