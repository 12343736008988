import React from 'react';
import * as PropTypes from 'prop-types';
import { Heading } from '@chakra-ui/core';

const SmallTitle = ({ children, ...props }) => (
  <Heading
    as="h4"
    size="lg"
    fontWeight="400"
    mb={10}
    {...props}
  >
    {children}
  </Heading>
);

SmallTitle.propTypes = {
  children: PropTypes.node.isRequired,
  ...Heading.propTypes,
};

export default SmallTitle;
