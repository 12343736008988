import * as PropTypes from 'prop-types';

const AuthUserPropType = PropTypes.shape({
  uid: PropTypes.string.isRequired,
  email: PropTypes.string,
  emailVerified: PropTypes.bool,
  providerData: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  roles: PropTypes.object,
});

export default AuthUserPropType;
