import React from 'react';
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import PageSolicitud from './page';

const Solicitud = () => (
  // <GoogleReCaptchaProvider
  //   useRecaptchaNet
  //   reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
  // >
  <PageSolicitud />
  // </GoogleReCaptchaProvider>
);

export default Solicitud;
