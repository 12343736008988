import React from 'react';
import * as PropTypes from 'prop-types';
import { FirebasePropType, withFirebase } from '../Firebase';
import HSelect from './HSelect';

const ReferenceSelect = ({
  errors, name, errorMessage, label, validation, register, watch,
  placeholder, value, reference, firebase, isDisabled, ...props
}) => {
  const [options, setOptions] = React.useState([]);
  const [internalMessage, setInternalMessage] = React.useState({
    loading: false,
    message: null,
  });
  const [watchedFields, setWatchedFields] = React.useState(null);
  if (reference.watchFields) {
    const temp = watch(reference.watchFields);
    if (JSON.stringify(temp) !== JSON.stringify(watchedFields)) {
      setWatchedFields(temp);
    }
  }
  React.useEffect(() => {
    let unsubscribe;
    if (reference && reference.entity) {
      setInternalMessage({
        loading: true,
        message: null,
      });
      let instanceReference = firebase[reference.entity]();
      if (reference.where) {
        reference.where.forEach((item) => {
          let val = item.value;
          if (watchedFields && watchedFields[item.field]) {
            val = watchedFields[item.field];
          }
          instanceReference = instanceReference.where(
            item.field,
            item.operator,
            val,
          );
        });
      }
      unsubscribe = instanceReference.onSnapshot((snap) => {
        if (snap.empty) {
          setOptions([]);
          setInternalMessage({
            loading: false,
            message: 'No se encontraron registros en la base de datos.',
          });
        } else {
          const items = [];
          snap.forEach((doc) => {
            items.push({
              value: doc.id,
              label: doc.data().name,
            });
          });
          setOptions(items);
          setInternalMessage({
            loading: false,
            message: null,
          });
        }
      }, () => {
        setInternalMessage({
          loading: false,
          message: 'Ups! Algo no salió bien consultando los registros, intenta recargar la página.',
        });
      });
    }
    return () => !!unsubscribe && unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedFields]);
  let newValidation;
  if (reference.validate && watch) {
    newValidation = {
      ...validation,
      validate: (val) => reference.validate(val, watch),
    };
  } else {
    newValidation = {
      ...validation,
    };
  }
  return (
    <HSelect
      name={name}
      reference={{
        entity: options,
      }}
      label={label}
      isDisabled={isDisabled}
      value={value}
      errorMessage={errorMessage}
      validation={newValidation}
      register={register}
      errors={errors}
      internalMessage={internalMessage}
      placeholder={placeholder}
      {...props}
    />
  );
};

ReferenceSelect.defaultProps = {
  reference: null,
  internalMessage: null,
  watch: null,
};

ReferenceSelect.propTypes = {
  ...HSelect.propTypes,
  reference: PropTypes.shape({
    entity: PropTypes.string.isRequired,
    validate: PropTypes.func,
  }),
  firebase: FirebasePropType.isRequired,
  watch: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  internalMessage: PropTypes.any,
};

export default withFirebase(ReferenceSelect);
